import React from "react";

const InputTypeText = ({
  fieldItem,
  isVisible = true,
  preview = true,
  handleInputChange = () => {},
  formElementId = null,
  validationType = "None",
}) => {
  const isValid = (value) => {
    switch (validationType) {
      case "Alphabetic":
        return /^[a-zA-Z]+$/.test(value);
      case "AlphaNumeric":
        return /^[a-zA-Z0-9]+$/.test(value);
      case "Numeric":
        return /^[0-9]+$/.test(value);
      case "Phone":
        return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(value);
      case "Email":
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      default:
        return true;
    }
  };

  const isMaxLengthValid = (value) => {
    let maxLength = 1024; // Default maximum length is 1024
  
    // Adjust maximum length based on validation type if needed
    switch (validationType) {
      case "Phone":
        maxLength = 16; // Maximum length for phone numbers is 16 (including country code and separators)
        break;
      case "Email":
        maxLength = 320; // Maximum length for email addresses is 320 characters (per RFC specifications)
        break;
      // Add more cases here if needed for other validation types
      default:
        break;
    }
  
    // Check if value is not null or undefined
    if (value !== null && value !== undefined) {
      if (maxLength && value.length > maxLength) {
        return false;
      }
    }
    return true;
  };

  return (
    <>
      {isVisible && fieldItem?.isVisible && (
        <>
          {fieldItem?.label && (
            <label>
              {fieldItem?.label}
              <span className="RequiredMark">
                {fieldItem?.required && " *"}
              </span>
            </label>
          )}
          <input
            type={fieldItem?.type}
            name={formElementId ?? fieldItem?.name}
            placeholder={fieldItem?.placeholder}
            required={fieldItem?.required}
            onChange={(e) => {
              if (!preview && isValid(e.target.value) && isMaxLengthValid(e.target.value)) {
                handleInputChange(e);
              }
            }}
            readOnly={preview}
          />
          {!isValid(fieldItem?.value) && (
            <div className="ValidationError">
              Invalid input for {validationType} validation.
            </div>
          )}
          {!isMaxLengthValid(fieldItem?.value) && (
            <div className="ValidationError">
              Input value exceeds maximum length.
            </div>
          )}
        </>
      )}
    </>
  );
};

export default InputTypeText;