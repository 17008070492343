import React from 'react';
import smallLogo from '../../specto_logos/Single_logo.png';

const SmallLogo = () => {
  const styles = {
    logoContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    logo: {
      width: '7rem',
      height: 'auto',
      marginBottom: '1rem'
    }
  };

  return (
    <div style={styles.logoContainer}>
      <img src={smallLogo} alt="Logo" style={styles.logo} />
    </div>
  );
};

export default SmallLogo;
