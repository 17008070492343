import React, { useState, useEffect } from "react";
import GeneralSwitch from "./GeneralSwitch";
import Switch from "react-switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";

const ChoiceInputSection = ({ item, fieldDef, fieldIndex, handleChange }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const initialOptions = item.fields?.[fieldIndex].options || [];
    setOptions(initialOptions);
  }, [item.fields, fieldIndex]);

  const addOption = () => {
    const newOption = `Option ${options.length + 1}`;
    const updatedOptions = [...options, newOption];
    setOptions(updatedOptions);
    updateOptionsInParent(updatedOptions);
  };

  const removeOption = (index) => {
    const updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    setOptions(updatedOptions);
    updateOptionsInParent(updatedOptions);
  };

  const handleOptionChange = (index, newValue) => {
    const updatedOptions = [...newValue];
    updatedOptions[index] = newValue;
    setOptions(updatedOptions);
    updateOptionsInParent(updatedOptions);
  };

  const updateOptionsInParent = (updatedOptions) => {
    handleChange({ target: { value: updatedOptions } }, fieldIndex, "options");
  };

  return (
    <>
      {"multiple" in fieldDef?.properties && (
        <GeneralSwitch
          label="Multiple Choice:"
          itemFields={item.fields}
          fieldIndex={fieldIndex}
          handleChange={handleChange}
          property="multiple"
        />
      )}
      <label className="mt-3">Options:</label>
      <div>
        {options.map((option, index) => (
          <div key={index}>
            <input
              type="text"
              value={option}
              className="OptionInput"
              placeholder="Option Title"
              onChange={(e) => handleOptionChange(index, e.target.value)}
            />
            <button
              className="remove-option"
              onClick={() => removeOption(index)}
              style={{ backgroundColor: "rgba(255, 0, 0, 0.7)" }}
            >
              <FontAwesomeIcon icon={faTrashCan} />
            </button>
          </div>
        ))}
      </div>
      <button onClick={addOption}>Add Option</button>
    </>
  );
};

export default ChoiceInputSection;
