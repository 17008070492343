import React, { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import QRCode from 'qrcode.react';
import HexagonSpinner from '../../General/Animations/Hexspinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHospital, faPlus } from '@fortawesome/free-solid-svg-icons';
import styles from './Clinics.css'
import useUID from '../../General/useUID'
import {
    collection,
    getDoc,
    deleteDoc,
    doc, 
    query,
    where,
    onSnapshot,
    getDocs,
    addDoc
  } from "firebase/firestore";
  import { auth, db } from "../../../firebase";
  import ClinicEditModal from './ClinicEditModal';
 import Modal from 'react-modal';
import AddClinic from './AddClinic';


  function Clinics() {
  const [isLoading, setIsLoading] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [clinics, setClinics] = useState([]);
  const functions = getFunctions();
  const [uid, subUserUID] = useUID();
  const [selectedClinic, setSelectedClinic] = useState(null);
  const [link, setLink] = useState(null);


  const getClinics = () => {
    const clinicsSnapshot = collection(db, "clinics");
    const q = query(clinicsSnapshot, where("ownerId", "==", uid));
  
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const clinicsList = [];
      snapshot.forEach((doc) => {
        const clinicData = doc.data();
        // Check if `created` exists and is not null before converting to Date
        const createdDate = clinicData.created ? clinicData.created.toDate() : null;
        clinicsList.push({
          id: doc.id, // Adding id from the document
          name: clinicData.clinicName,
          link: clinicData.link, // Use the subdomain link stored in Firestore
          created: createdDate,
        });
      });
      setClinics(clinicsList);
    }, (error) => {
      console.error('Error fetching clinics:', error);
    });
  
    // Clean up the listener when component is unmounted
    return () => unsubscribe();
  };
  
  
  useEffect(() => {
    return getClinics(); // getClinics now returns the unsubscribe function
  }, [uid]);
  
  
  const togglePopup = () => {
    setPopupOpen(!popupOpen);
  };

  
  // Function to download the QR code
function downloadQRCode(id) {
    const canvas = document.getElementById(id);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "clinic_qrcode.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
  
  

  


  const deleteClinic = async (id) => {
    try {
      const clinicRef = doc(db, 'clinics', id);
      await deleteDoc(clinicRef);
      // Remove clinic from local state as well
      setClinics(clinics.filter(clinic => clinic.id !== id));
    } catch (error) {
      console.error('Error deleting clinic:', error);
    }
  };

const handleEditClick = (clinic) => {
  setSelectedClinic(clinic);
};

  return (
    <div className='addClinic'>
      <div>
          <button
            id="AddClinicButton"
            onClick={togglePopup}
            className='addClinicButton'
          >
            <FontAwesomeIcon icon={faHospital} style={{ marginRight: '10px' }} />
            <div className='patientsActionButtonText'>
            Clinics
            </div>
          </button>
      </div>

      {popupOpen && 
        <div className="popupContainer">
          <div className="clinicWindow">
            <div className="cancelButton">
              <button onClick={togglePopup} className="filesCloseButton">X</button>
            </div>
            <div className='newClinic'> 
            <div className="centerHeader">
                <h3>Clinics</h3>
                   </div>

                <AddClinic />

              {isLoading && 
                <HexagonSpinner />
              }
              {link && 
                <div>
                  <p>Link: <a href={link}>{link}</a></p>
                  <QRCode 
                    id="clinicQRCode" // add an ID here
                    value={link} 
                    onClick={() => downloadQRCode("clinicQRCode")}
                  />
                </div>
              }
            </div>

            <div className='clinicsList'>
              <table>
              <thead>
              <tr>
                <th>Clinic Name</th>
                <th>Clinic Link</th>
                <th>QR Code</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {clinics.map(clinic => (
                <tr key={clinic.id}>
                  <td style={{textAlign: 'center'}}>{clinic.name}</td>
                  <td style={{textAlign: 'center'}}>
                    {clinic.link ? (
                      <button onClick={() => navigator.clipboard.writeText(clinic.link)}>
                        Copy Link
                      </button>
                    ) : (
                      <span>Private Clinic</span>
                    )}
                  </td>
                  <td style={{textAlign: 'center'}}>
                    {clinic.link && (
                      <QRCode 
                        id={`clinicQRCode-${clinic.id}`} 
                        value={clinic.link} 
                        size={64} 
                        onClick={() => downloadQRCode(`clinicQRCode-${clinic.id}`)} 
                      />
                    )}
                  </td>
                  <td style={{textAlign: 'center'}}>
                    <button onClick={() => deleteClinic(clinic.id)}>Delete</button>
                    <button onClick={() => handleEditClick(clinic)}>Edit</button>
                  </td>
                </tr>
              ))}
            </tbody>

              </table>

          
              <ClinicEditModal 
                  selectedClinic={selectedClinic}
                  setSelectedClinic={setSelectedClinic}
                />

            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default Clinics;
