import React, { useState } from "react";
import { auth, db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import "./AUTH.css"; // Import styles without assigning to a variable
import { signInWithEmailAndPassword, setPersistence, browserSessionPersistence } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import styles from "./AUTH.css";
import Smalllogo from './smalllogo';
import { Link } from 'react-router-dom';
import Footer from "../General/Home/Footer";
import logo from '../../specto_logos/Specto_logo_specto.png';
import { Helmet } from 'react-helmet';
import { collection, getDocs, query, where } from "firebase/firestore";
import { getFunctions, httpsCallable } from 'firebase/functions';
import HexagonSpinner from "../General/Animations/Hexspinner";
import { onAuthStateChanged } from "firebase/auth";


const functions = getFunctions();
const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  // React.useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, user => {
  //     if (user) {
  //       navigate("/home");
  //     }
  //   });
    
  //   // Cleanup listener on unmount
  //   return () => unsubscribe();
  // }, [navigate]);

  const signIn = (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading
  
    setPersistence(auth, browserSessionPersistence)
      .then(() => {
        return signInWithEmailAndPassword(auth, email, password);
      })
      .then(async (userCredential) => {
        const userUID = userCredential.user.uid;
        let isSubuser = false; // Initialize the flag here
        
        // Use a separate function to determine if the user is a subuser
        const getEffectiveUID = async (email) => {
          const usersCollection = collection(db, "users");
          const allUsersSnapshot = await getDocs(usersCollection);
          
          for (let userDoc of allUsersSnapshot.docs) {
            const subusersCollection = collection(userDoc.ref, "subusers");
            const q = query(subusersCollection, where("Email", "==", email));
            const subuserSnapshot = await getDocs(q);
            
            if (!subuserSnapshot.empty) {
              isSubuser = true; // Set the flag here
              return userDoc.id; // Return parentUID if found
            }
          }
      
          return userUID; // If not a subuser, return the user's own UID
        };
        
        // Call this function and proceed based on the result
        getEffectiveUID(email).then((effectiveUID) => {
          // Now, use 'effectiveUID' for subsequent operations.
          const effectiveUserRef = doc(db, "users", effectiveUID);
          sessionStorage.setItem('effectiveUID', effectiveUID); // Set the effective UID in session storage
          
          getDoc(effectiveUserRef)
            .then((doc) => {
              if (doc.exists()) {
                const userData = doc.data();
    
                // If the user is a subuser, bypass payment and go directly to home
                if (isSubuser) {
                  navigate("/home");
                  setIsLoading(false); // End loading
                  logIpAddress(); // Log the IP after navigating
                  return; // Exit the function
                }
    
                const hasNPI = Boolean(userData.NPI);
                const hasSubscription = Boolean(userData.subscriptionId);
                const hasAccountTier = Boolean(userData.AccountTier);
    
                if (!hasNPI) {
                  navigate("/signupprovider");
                } else {
                  if (!hasSubscription && !hasAccountTier) {
                    navigate("/payment");
                  } else {
                    navigate("/home");
                  }
                }
              } else {
                navigate("/payment");
              }
              setIsLoading(false); // End loading
              logIpAddress(); // Log the IP after navigating
            })
            .catch((error) => {
              console.log(error);
              auth.signOut();
              setError("Unable to fetch user data");
              setIsLoading(false); // End loading
            });
        
        });
      })
      .catch((error) => {
        // Handle authentication errors here
        console.log(error);
        setError("Wrong password or username."); // Set an error message
        setIsLoading(false); // Stop the loading spinner
      });
  };
  

  const logIpAddress = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      const ipAddress = data.ip;

      // Call the cloud function to log the event
      const addLogFunction = httpsCallable(functions, 'addLog');
      await addLogFunction({
        uid: auth.currentUser.uid,
        message: `User logged in from ${ipAddress} IP address.`
      });
    } catch (ipError) {
      console.error("Error fetching or logging IP address: ", ipError);
    }
  };


  return (

    <>


      <div className="background">
        <a href="/" className="logoWrapper">
          <img src={logo} alt="Logo" className="logo" />
        </a>

        <div className="AUTH-container">
          <div>
            <Smalllogo />
          </div>
          <form onSubmit={signIn}>
            <h1 className="heading">Log In to Specto Health</h1>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={styles["input-field"]}
            />
            <input
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={styles["input-field"]}
            />
            {error && <p className={styles["error-message"]}>{error}</p>}
            {isLoading ? (
              <HexagonSpinner /> // Render this when it's loading
            ) : (
              <button type="submit" className={styles["login-btn"] + " signIn-button"}>
                Sign In
              </button>
            )}

            <Link to="/forgot-password" className="forgot-password-link">
              Forgot Password?
            </Link>
            <Link to="/Signup" className="signup-link">
              Create a Specto Account
            </Link>
          </form>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default SignIn;


<Helmet>
  <title>Sign In | Specto Health</title>
  <meta name="description" content="Sign in to your Specto Health account to access our suite of medical tools. Don't have an account? Register today!" />
  <meta name="keywords" content="Sign in, Log in, Medical Tools, Medical Billing Software, Telehealth tools, Healthcare Billing, Clinic Management, Patient Management, AI in Healthcare, Specto Health" />
  <meta property="og:title" content="Sign In | Specto Health" />
  <meta property="og:description" content="Sign in to your Specto Health account to access our suite of medical tools. Don't have an account? Register today!" />
  <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/spectohealth.appspot.com/o/Specto_logo_wide.png?alt=media&token=72c8f353-f0f9-463c-966e-4f7b644bea5d" />
  <meta property="og:url" content="https://spectohealth.com/signin" />
</Helmet>