import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import { doc, updateDoc } from "firebase/firestore";
import { db, auth } from "../../firebase";
import { Link } from 'react-router-dom';
import InputMask from "react-input-mask";
import styles from "./AUTH.css";

const SignupProvider = () => {
  const [npi, setNpi] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [Phone, setPhone] = useState("");
  const [ein, setEin] = useState("");
  const [referalCode, setreferalCode] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // new state variable
  const [einError, setEinError] = useState("");
  
  const navigate = useNavigate();

  const functions = getFunctions();

  const handleEinChange = (e) => {
    const inputEin = e.target.value;
    setEin(inputEin);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();

    // Validate EIN to be exactly 9 digits
    if (ein.length !== 9 || !ein.match(/^\d{9}$/)) {
      setEinError("EIN must be exactly 9 digits");
      return;
    }

    try {
      setLoading(true); // set loading to true before calling the function

      // Call the Firebase Cloud Function to verify the NPI
      const verifyNpi = httpsCallable(functions, "verifyNpi");
      const result = await verifyNpi({ npi: npi });

      setLoading(false); // set loading back to false after the function call

      if (result.data.valid) {
        const npiData = result.data.npiData;
        if (npiData.results[0].basic.organization_name === organizationName.toUpperCase()) {
          const user = auth.currentUser;
        await updateDoc(doc(db, "users", user.uid), {
          NPI: npi,
          OrganizationName: organizationName,
          FirstName: firstName,
          LastName: lastName,
          Phone: removeNonNumericCharacters(Phone),
          referalCode : referalCode,
          EIN: ein
        });

        if (npiData.results[0].basic.organization_name === organizationName.toUpperCase()) {
          navigate("/payment");
        } else {
          if (
            npiData.results[0].basic.first_name !== firstName.toUpperCase() ||
            npiData.results[0].basic.last_name !== lastName.toUpperCase()
          ) {
            setError(
              "Organization Name and Provider Name could not be verified by NPI. Case Sensitive"
            );
          } else {
            navigate("/payment");
          }
        }

        } else {
          // If the organization name does not match, check for firstName and lastName
          if (
            npiData.results[0].basic.first_name === firstName.toUpperCase() &&
            npiData.results[0].basic.last_name === lastName.toUpperCase()
          ) {
            const user = auth.currentUser;
            await updateDoc(doc(db, "users", user.uid), {
              NPI: npi,
              OrganizationName: organizationName,
              FirstName: firstName,
              LastName: lastName,
              Phone: removeNonNumericCharacters(Phone),
              referalCode : referalCode,
              EIN: ein
            });
            // If firstName and lastName match, navigate to stripe
            navigate("/payment");
          } else {
            // If firstName and lastName do not match, show an error message to the user
            setError(
              "Organization Name and Provider Name could not be verified by NPI. Case Sensitive"
            );
          }
        }
      } else {
        // If the NPI is invalid, show an error message to the user
        setError("NPI could not be verified");
      }
    } catch (error) {
      console.log(error);
      setError("Error verifying NPI");
    }
  };

  const removeNonNumericCharacters = (input) => {
    return input.replace(/\D/g,"");
  }

  return (
    <div className = "background">
      <div className="AUTH-container">
        <form onSubmit={handleSignUp}>
          <h1 className="heading">
            Provider Verification{" "}
                </h1>
                <h2 className="h2">We just need to confirm that you're a healthcare professional</h2>
                <input
                type="text"
                placeholder="NPI"
                value={npi}
                onChange={(e) => setNpi(e.target.value)}
                className={styles["input-field"]}
                required
                />
                <input
                type="text"
                placeholder="Organization Name"
                value={organizationName}
                onChange={(e) => setOrganizationName(e.target.value.toUpperCase())}
                className={styles["input-field"]}
                required
                />
                <input
                type="text"
                placeholder="Provider's First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value.toUpperCase())}
                className={styles["input-field"]}
                required
                />
                <input
                type="text"
                placeholder="Provider's Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value.toUpperCase())}
                className={styles["input-field"]}
                required
                />
                <InputMask
                  mask="(999) 999-9999"
                  value={Phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Phone"
                  required
                >
                  {(inputProps) => <input {...inputProps} type="text" name="phone" className={styles["input-field"]}
                required />}
                  
                </InputMask>
                <input
                  type="text"
                  placeholder="EIN (Tax ID)"
                  value={ein}
                  onChange={handleEinChange}
                  className={styles["input-field"]}
                  required
                />
               {einError && <p className="error-message">{einError}</p>}
                
                <input
                type="text"
                placeholder="Referal Code (optional)"
                value={referalCode}
                onChange={(e) => setreferalCode(e.target.value)}
                className={styles["input-field"]}
                />
                {error && <p className="error-message">{error}</p>}
                <div style={{ display: "inline-flex", alignItems: "center" }}>
                  <button type="submit" className="signup-btn">
                    {loading ? "Looking up NPI" : "Continue"}
                  </button>
                  {loading && (
                    <span className="spinner-container">
                      <span className="loading-spinner" />
                    </span>
                  )}
                </div>
           </form>
        </div>
    </div>
);
};

export default SignupProvider;
