import React from "react";
import { Droppable } from "react-beautiful-dnd";
import PaletteCard from "../FormElements/PaletteCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

const Palette = ({ onElementAdd, fieldDefinitions, saveFormData }) => {
  const fields = Object.entries(fieldDefinitions);
  return (
    <div className="PaletteContainer">
      <div className="PaletteDroppable">
        <Droppable droppableId="palette-droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {fields.map(
                ([key, typeData]) => (
                  <PaletteCard
                    key={typeData?.type}
                    typeData={typeData}
                    onAdd={onElementAdd}
                  />
                )
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
      {/* Save button */}
      <button className="primary w-100 SaveButton" onClick={() => saveFormData()}> Save</button>
      {/* <FontAwesomeIcon icon={faSave} /> */}
    </div>
  );
};

export default Palette;
