import React, { useState, useEffect } from 'react';
import styles from './PatientView.module.css';
import 'firebase/functions';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import useUID from '../General/useUID'

function Zoom({ email, phone, onClose }) {
  const [patientEmail, setPatientEmail] = useState(email);
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [duration, setDuration] = useState('');
  const [providerEmail, setProviderEmail] = useState('');
  const [meetingDetails, setMeetingDetails] = useState(null); // New state variable for meeting details
  const [uid, subUserUID] = useUID();

  useEffect(() => {

    const fetchEmail = async () => {
      if (uid) {
        const docRef = doc(db, 'users', uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setProviderEmail(docSnap.data().Email);
        } else {
        }
      }
    }

    fetchEmail();
  }, []);

  const functions = getFunctions();
  const scheduleZoomCall = httpsCallable(functions, 'scheduleZoomCall');

  const handleScheduleCall = async () => {
    try {
      const dateTime = new Date(date + 'T' + time);
      const response = await scheduleZoomCall({
        patientEmail: patientEmail,
        providerEmail: providerEmail,
        dateTime: dateTime.toISOString(),
        duration: duration // Include duration
      });
      if (response.data.success) {
        setMeetingDetails(response.data.meeting); // Store meeting details on success

        // //Logs
        // const addLogFunction = httpsCallable(functions, 'addLog');
        // await addLogFunction({ uid: auth.currentUser.uid, message: 'Zoom Call Scheduled.' });

      } else {
        console.error('Error scheduling Zoom call:', JSON.stringify(response.data.error));
        alert('Error scheduling Zoom call: ' + JSON.stringify(response.data.error)); // Displays error message
      }
    } catch (error) {
      console.error('Error scheduling Zoom call:', JSON.stringify(error));
      alert('Error scheduling Zoom call: ' + JSON.stringify(error)); // Displays error message
    }
  };

  return (
    <div
     className={styles.contentContainer}
     >
      <button onClick={onClose} className='filesCloseButton'>
        X
      </button>
      <div 
      className={styles.zoomcontent}
      >
        <div className='input-group-row'>
          <div className="input-field">
            <label htmlFor="provideremail">Provider Email:</label>
            <input
              type="email"
              value={providerEmail}
              onChange={(e) => setProviderEmail(e.target.value)} // Make the field editable
            // className={styles.zoomInput}
            />
          </div>
          <div className="input-field">
            <label htmlFor="patientemail">Patient Email:</label>
            <input
              type="email"
              value={patientEmail}
              onChange={(e) => setPatientEmail(e.target.value)}
              // className={styles.zoomInput}
              placeholder={patientEmail}
            />
          </div>
        </div>
        <div className='input-group-row'>
          <div className="input-field">
            <label htmlFor="date">Date:</label>
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              placeholder="Select a date"
            // className={styles.zoomInput}
            />
          </div>
          <div className="input-field">
            <label htmlFor="date">Time:</label>
            <input
              type="time"
              value={time}
              onChange={(e) => setTime(e.target.value)}
              placeholder="Select a time"
            // className={styles.zoomInput}
            />
          </div>
        </div>
        <div className='input-group-row'>
          <div className="input-field">
            <label htmlFor="date">Duration:</label>
            <select
              // className={styles.zoomInput}
              onChange={(e) => setDuration(e.target.value)}
            >
              <option value="" disabled selected>
                Select duration
              </option>
              <option value="15">15 minutes</option>
              <option value="30">30 minutes</option>
              <option value="45">45 minutes</option>
              <option value="60">60 minutes</option>
            </select>
          </div>
        </div>
        <div className='content-center mt-3'>
          <button onClick={handleScheduleCall} className="schedule-call-button">
            Schedule Call
          </button>
        </div>
        {meetingDetails && ( // Display meeting details if available
          <div>
            <h2>Zoom Call Scheduled.</h2>
            <p>Meeting ID: {meetingDetails.id}</p>
            <p>Start Time: {new Date(meetingDetails.start_time).toLocaleString()}</p>
            <p><a href={meetingDetails.join_url} target="_blank" rel="noopener noreferrer">Join Meeting</a></p>
            <button onClick={() => navigator.clipboard.writeText(meetingDetails.join_url)}>
              Copy Meeting Link
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Zoom;
