import React, { useState } from 'react';
import ProviderSelection from '../Providers/ProviderSelection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserMd } from '@fortawesome/free-solid-svg-icons';

const ProvidersMenu = ({ onClose }) => {
    const [showProviderSelection, setShowProviderSelection] = useState(false);

    const handleProviderSelectionClick = () => {
        setShowProviderSelection(true);
    };

    const handleUnifiedProviderSelection = () => {
        console.log('selected');
    }

    // Determine the button class
    const buttonClass = `MainActionButton ${showProviderSelection ? 'selected' : ''}`;

    return (
        <>
            {showProviderSelection && 
                <button className="filesCloseButton" onClick={() => {
                    setShowProviderSelection(false);
                    onClose();
                }}>
                    X
                </button>
            }

            {!showProviderSelection && 
                <button
                    id="ClaimStatusButton"
                    onClick={handleProviderSelectionClick}
                    className={buttonClass}
                >
                    <FontAwesomeIcon icon={faUserMd} style={{ marginRight: '10px' }} />
                    My Providers
                </button>
            }

            {showProviderSelection && 
                <div className='Claims-inner-container'>
                    <div className='claimsMenus'>
                        <h3>Providers</h3>
                        <ProviderSelection
                            type="unified"
                            onProviderSelect={handleUnifiedProviderSelection}
                        />
                    </div>
                </div>
            }
        </>
    );
};

export default ProvidersMenu;
