import { auth, db } from "../../firebase";
import { useState, useEffect, useMemo, useRef } from "react";
import styles from "./PatientFiles.modules.css";
import AddPatientPopup from "./AddPatient/AddPatientPopup";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-modal';
import { doc, getDoc, getDocs, updateDoc, deleteField, collection, query, where, onSnapshot, writeBatch } from 'firebase/firestore';
import { faTrash, faSortUp, faSortDown, faCheckSquare, faArrowUp, faBan, faPhone, faClipboardList, faUserGraduate, faUserMd, faCheckDouble, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import BatchClaims from './Billing/PatientsBatchClaims';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { getFunctions, httpsCallable } from 'firebase/functions';
import useUID from '../General/useUID'
import usePatientSearch from './SearchFunction';
import { useNavigate } from "react-router-dom";
import HexagonSpinner from "../General/Animations/Hexspinner";

const functions = getFunctions();

function PatientList({ onSelectPatient, selectedFolder, selectedPatient, patientIdfromURL, scrollingTableRef, dataUpdated }) {
  const [patients, setPatients] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [showCheckBoxes, setShowCheckBoxes] = useState(false);
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isSortFilterModalOpen, setIsSortFilterModalOpen] = useState(false);
  const [showBatchClaimsModal, setShowBatchClaimsModal] = useState(false);
  const [unbilledPatients, setUnbilledPatients] = useState(new Set());
  const [allPatients, setAllPatients] = useState([]); // Holds all patient data
  const [patientFlags, setPatientFlags] = useState({});
  const [uid, subUserUID, error] = useUID();
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [showFlagDeleteConfirmModal, setShowFlagDeleteConfirmModal] = useState(false);
  const [flagToDelete, setFlagToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [addPatientUpdated, setaddPatientUpdated] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {  
    setIsLoading(true);
    const fetchData = async () => {
      let patientsRef = collection(db, "patients", uid, "patientData");
  
      try {
        const querySnapshot = await getDocs(patientsRef);
        let flags = {};
        let patientsList = await Promise.all(querySnapshot.docs.map(async (docSnapshot) => {
          const patientData = docSnapshot.data();
          const patientId = docSnapshot.id;
  
          // Fetching flags
          const patientRef = doc(db, "patients", uid, "patientData", patientId);
          const patientDoc = await getDoc(patientRef);
          if (patientDoc.exists() && patientDoc.data().flag) {
            flags[patientId] = patientDoc.data().flag;
          }
  
          return { id: patientId, data: patientData };
        }));
  
        setPatientFlags(flags); // Update the patient flags state  
  
        setAllPatients(patientsList); // Set allPatients state here
  
        // Selecting patient based on URL or the first patient
        const selectedPatientFromURL = patientIdfromURL && patientsList.find(patient => patient.id === patientIdfromURL);
        if (selectedPatientFromURL) {
          onSelectPatient(selectedPatientFromURL);
          setIsLoading(false);

        } else {
          // Select the first non-deleted patient and update URL if patientIdfromURL is not valid
          const firstNonDeletedPatient = patientsList.find(patient => !patient.data.deleted);
          if (firstNonDeletedPatient) {
            onSelectPatient(firstNonDeletedPatient);
            navigate(`/patients/${firstNonDeletedPatient.id}`);
            setIsLoading(false);
          }
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching patients:", error);
        setIsLoading(false);
      }
    };
  
    if (uid) {
      fetchData();
    }
  }, [uid, dataUpdated]);

  const handleDataUpdated = () => {
    setaddPatientUpdated(true); // This will trigger the useEffect
  };
  
  
  // Filter data based on selected folder
  useEffect(() => {
    let filteredPatientsList = allPatients;
  
    if (selectedFolder === "All Patients") {
      filteredPatientsList = allPatients.filter(patient => !patient.data.deleted);
    } else if (selectedFolder !== "All Patients" && selectedFolder.id) {
      filteredPatientsList = allPatients.filter(patient => patient.data.patient.clinicToken === selectedFolder.id && !patient.data.deleted);
    } else if (selectedFolder === "Deleted Patients") {
      filteredPatientsList = allPatients.filter(patient => patient.data.deleted);
    }
  
    setPatients(filteredPatientsList);
  }, [allPatients, selectedFolder]);
  
  
  

  useEffect(() => {
    if (!scrollingTableRef.current) return;
    const checkScrollTop = () => {
      const tableScrollPosition = scrollingTableRef.current.scrollTop;
      if (!showScrollToTop && tableScrollPosition > 400) {
        setShowScrollToTop(true);
      } else if (showScrollToTop && tableScrollPosition <= 400) {
        setShowScrollToTop(false);
      }
    };

    scrollingTableRef.current.addEventListener('scroll', checkScrollTop);

    return () => {
      if (scrollingTableRef.current) {
        scrollingTableRef.current.removeEventListener('scroll', checkScrollTop);
      }
    };
  }, [showScrollToTop]);

  const convertDOB = (dob) => {
    if (typeof dob !== 'string') {
      return dob;  // Handle non-string or undefined DOBs appropriately here
    }

    // If DOB format is MM/DD/YYYY
    if (dob.includes("/")) {
      const [month, day, year] = dob.split("/");
      return `${year}${month}${day}`;
    }
    // If DOB format is YYYYMMDD
    else if (dob.length === 8) {
      return dob;
    }
    // Else handle other possible formats or invalid entries
    else {
      return dob;  // Handle this case appropriately
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSelectPatient = (patient) => {
    onSelectPatient(patient);

    // Update the URL with the selected patient's ID
    if (patient && patient.id) {
      navigate(`/patients/${patient.id}`);
    }
  };

  const toggleCheckBoxes = () => {
    setShowCheckBoxes(prev => !prev);
    setSelectedPatients([]);
  };

  const togglePatientSelection = (id) => {
    setSelectedPatients((prevState) =>
      prevState.includes(id)
        ? prevState.filter((patientId) => patientId !== id)
        : [...prevState, id]
    );
  };

  const handleDeleteSelectedPatients = async () => {

    // Start a new batch
    const batch = writeBatch(db);

    // Add each patient's soft delete operation to the batch
    for (let patientId of selectedPatients) {
      const patientDocRef = doc(db, "patients", uid, "patientData", patientId);
      batch.update(patientDocRef, { deleted: true });
    }

    // Commit the batch
    await batch.commit();

    // Filter out deleted patients from the local state
    setPatients(prevPatients => prevPatients.filter(patient => !selectedPatients.includes(patient.id)));

    // Clear the selected patients after deletion
    setSelectedPatients([]);
  };

  const requestDeleteSelectedPatients = () => {
    if (selectedPatients.length > 0) {
      setShowConfirmModal(true);
    }
  };

  const confirmDelete = () => {
    handleDeleteSelectedPatients();
    setShowConfirmModal(false);
  };

  const cancelDelete = () => {
    setShowConfirmModal(false);
  };

  function highlightMatch(text, searchTerm) {
    const safeText = text ? text.toString() : '';
    const safeSearchTerm = searchTerm ? searchTerm.toString() : '';

    const index = safeText.toLowerCase().indexOf(safeSearchTerm.toLowerCase());
    if (index === -1 || !safeSearchTerm) return safeText;

    const beforeMatch = safeText.substring(0, index);
    const match = safeText.substring(index, index + safeSearchTerm.length);
    const afterMatch = safeText.substring(index + safeSearchTerm.length);

    return (
      <span dangerouslySetInnerHTML={{ __html: `${beforeMatch}<b>${match}</b>${afterMatch}` }} />
    );
  }



  const filteredPatients = usePatientSearch(patients, searchTerm);

  const sortedPatients = useMemo(() => {
    if (!sortBy) {
      return filteredPatients;
    }

    return [...filteredPatients].sort((a, b) => {
      let aValue, bValue;

      // Sort by Date of Birth
      if (sortBy === "dob") {
        aValue = convertDOB(a.data.patient[sortBy]);
        bValue = convertDOB(b.data.patient[sortBy]);
      } else if (sortBy === "flags") {
        // Sort by flags - Assuming a default value for patients with no flags
        aValue = patientFlags[a.id] ? patientFlags[a.id] : "zzz";
        bValue = patientFlags[b.id] ? patientFlags[b.id] : "zzz";
      } else {
        // Sort by other criteria (firstName, lastName, etc.)
        aValue = a.data.patient[sortBy] ? a.data.patient[sortBy].toLowerCase() : "";
        bValue = b.data.patient[sortBy] ? b.data.patient[sortBy].toLowerCase() : "";
      }

      // Ascending or Descending based on sortOrder state
      if (aValue < bValue) {
        return sortOrder === 'asc' ? -1 : 1;
      } else if (aValue > bValue) {
        return sortOrder === 'asc' ? 1 : -1;
      } else {
        return 0;
      }
    });
  }, [filteredPatients, sortBy, sortOrder, patientFlags]); // Include patientFlags in the dependency array



  function capitalizeName(name) {
    if (!name) {
      return "";
    }
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  }

  const handleSendClaims = () => {
    if (selectedPatients.length > 0) {
      setShowBatchClaimsModal(true);
    }
  };

  const selectAllPatients = () => {
    const allPatientIds = patients.map(patient => patient.id);
    setSelectedPatients(allPatientIds);
  };

  const downloadPatientsData = async () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(
      selectedPatients.map(id => patients.find(patient => patient.id === id)).map(patient => {
        const patientData = patient.data.patient;
        return {
          ...patientData,
          address: patientData.address ? `${patientData.address.address1}, ${patientData.address.city}, ${patientData.address.state}, ${patientData.address.zip}` : ''
        };
      })
    );

    XLSX.utils.book_append_sheet(wb, ws, "PatientsData");

    const binaryString = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    const data = new Blob([s2ab(binaryString)], { type: "" });
    const currentDate = getCurrentDateFormatted();
    saveAs(data, `patients_data_${currentDate}.xlsx`);

    // Fetch IP Address and log
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      const ipAddress = data.ip;

      // Call the cloud function to log the event with IP
      const addLogFunction = httpsCallable(functions, 'addLog');
      await addLogFunction({
        uid: auth.currentUser.uid,
        message: `Patients data downloaded. User IP address: ${ipAddress}.`
      });

    } catch (ipError) {
      console.error("Error fetching or logging IP address: ", ipError);
    }
  }

  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }

  function getCurrentDateFormatted() {
    const date = new Date();
    return `${date.getFullYear()}${String(date.getMonth() + 1).padStart(2, '0')}${String(date.getDate()).padStart(2, '0')}`;
  }

  const scrollToTop = () => {
    if (scrollingTableRef.current) {
      scrollingTableRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };


  const getFlagIcon = (flag) => {
    switch (flag) {
      case 'Eligible':
        return { icon: faCheckDouble, tooltip: 'Eligible', className: styles.Eligible };
      case 'Ineligible':
        return { icon: faCircleExclamation, tooltip: 'Ineligible', className: styles.Ineligible };
      case 'Denied Service':
        return { icon: faBan, tooltip: 'Denied Service', className: styles.deniedService };
      case 'Call':
        return { icon: faPhone, tooltip: 'Call', className: styles.call };
      case 'Needs Assessment':
        return { icon: faClipboardList, tooltip: 'Needs Assessment', className: styles.needsAssessment };
      case 'Enrolled':
        return { icon: faUserGraduate, tooltip: 'Enrolled', className: styles.enrolled }; // Add case for Enrolled
      case 'Outpatient':
        return { icon: faUserMd, tooltip: 'Outpatient', className: styles.outpatient }; // Add case for Outpatient
      default:
        return { icon: null, tooltip: '', className: '' };
    }
  };

  // Modified function to request flag deletion
  const requestRemovePatientFlag = (patientId, flag) => {
    setFlagToDelete({ patientId, flag });
    setShowFlagDeleteConfirmModal(true);
  };


  const confirmFlagDelete = async () => {
    if (flagToDelete) {
      const patientRef = doc(db, "patients", uid, "patientData", flagToDelete.patientId);
      await updateDoc(patientRef, {
        flag: deleteField(),  // Directly deleting the 'flag' field
      });
  
      setPatientFlags((prevFlags) => {
        const newFlags = { ...prevFlags };
        delete newFlags[flagToDelete.patientId];  // Removing the flag from the local state
        return newFlags;
      });
  
      // Reset the state after deletion
      setFlagToDelete(null);
      setShowFlagDeleteConfirmModal(false);
    }
  };

  return (
    <>
      <div className="claimsContainer">
        {
          showBatchClaimsModal &&
          <BatchClaims
            selectedPatientsData={selectedPatients.map(id => patients.find(patient => patient.id === id))}
            onClose={() => setShowBatchClaimsModal(false)}
          />
        }

      </div>
      <div className={'patientFiles'}>
        <div className={styles.controls}>
        <AddPatientPopup 
            selectedFolder={selectedFolder} 
            onDataUpdated={handleDataUpdated} 
          />
          <input
            className="search-input-patients"
            type="text"
            placeholder="Search all patients"
            value={searchTerm}
            onChange={handleSearch}
          />
          <div className="patientControls">
            {showCheckBoxes && (
              <button className="primary" onClick={selectAllPatients}>
                <FontAwesomeIcon title={'Select All'} icon={faCheckSquare} />
              </button>
            )}
            <button className="primary" onClick={toggleCheckBoxes}>
              {showCheckBoxes ? "Cancel" : "Select Multiple"}
            </button>
            <button className="primary" onClick={() => setIsSortFilterModalOpen(true)}>Sort</button>
          </div>
          <div className='multiControls'>
            {selectedPatients.length > 0 && (
              <>
                {!subUserUID && (
                  <>
                    <button className="primary" onClick={handleSendClaims}>Send Claims</button>
                  </>
                )}
                <button className="delete" onClick={requestDeleteSelectedPatients}>
                  <FontAwesomeIcon title={'Delete selected patients'} icon={faTrash} />
                </button>
                <button className="primary" onClick={downloadPatientsData}>
                  <FontAwesomeIcon title={'Download selected'} icon={faDownload} />
                </button>
              </>
            )}
          </div>
        </div>
        
        <div className="scrollingTable" ref={scrollingTableRef}>
          {isLoading ? (
            <div className='loadingContainer'>
              <HexagonSpinner />
              <h4>Loading every patient you have.</h4>
            </div>
          ) : (
            <table className={styles.scrollingTable}>
            <thead></thead>
            <tbody>
              {sortedPatients.map((patient) => (
                <tr
                  key={patient.id}
                  onClick={() => handleSelectPatient(patient)}
                  className={selectedPatient && patient.id === selectedPatient.id ? "selected-patient" : ""}
                >
                  {showCheckBoxes && (
                    <td>
                      <label className="checkbox-container">
                        <input
                          type="checkbox"
                          checked={selectedPatients.includes(patient.id)}
                          onChange={() => togglePatientSelection(patient.id)}
                        />
                        <span className="checkbox">
                          <span className="checkmark"></span>
                        </span>
                      </label>
                    </td>
                  )}
                  <td className="relativePositioned">
                    <span title={`DOB: ${patient.data.patient?.dob ?? 'N/A'}, Gender: ${patient.data.patient?.gender ?? 'N/A'}`}>
                      {highlightMatch(capitalizeName(patient.data.patient?.firstName ?? ''), searchTerm)}
                      {" "}
                      {highlightMatch(capitalizeName(patient.data.patient?.lastName ?? ''), searchTerm)}
                    </span>
                    {patientFlags[patient.id] && (
                      <span className={styles.patientFlagIndicator} title={getFlagIcon(patientFlags[patient.id]).tooltip}>
                        <FontAwesomeIcon
                          icon={getFlagIcon(patientFlags[patient.id]).icon}
                          style={{ marginLeft: '2rem' }}
                          onClick={() => requestRemovePatientFlag(patient.id)}
                        />

                      </span>
                    )}
                    {unbilledPatients.has(patient.id) && <span title={'Encounter ready to bill'} className="unbilledIndicator">$</span>}
                  </td>
                  <td>
                    {highlightMatch(patient.matchedText, searchTerm)} {/* Display the matched text with highlighting */}
                  </td>
                </tr>
              ))}
            </tbody>
            </table>
          )}
        </div>
      </div>
      <Modal
        isOpen={showConfirmModal}
        onRequestClose={cancelDelete}
        className="confirmModal"
      >
        <h2>Delete Selected Patients?</h2>
        <p>Are you sure you want to delete the selected patients?</p>
        <div className={styles.confirmButtons}>
          <button className="secondaryButton" onClick={cancelDelete}>
            Cancel
          </button>
          <button className="dangerButton" onClick={confirmDelete}>
            Delete
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={showFlagDeleteConfirmModal}
        onRequestClose={() => setShowFlagDeleteConfirmModal(false)}
        className="confirmModal"
      >
        <h2>Delete the flag {flagToDelete?.flag}?</h2>
        <div className={styles.confirmButtons}>
          <button className="secondaryButton" onClick={() => setShowFlagDeleteConfirmModal(false)}>
            Cancel
          </button>
          <button className="dangerButton" onClick={confirmFlagDelete}>
            Delete Flag
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={isSortFilterModalOpen}
        onRequestClose={() => setIsSortFilterModalOpen(false)}
        className="confirmModal"
      >
        <h2>Sort</h2>
        <div>
          <label htmlFor="sortBy">Sort By:</label>
          <select
            id="sortBy"
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
          >
            <option value={null}>None</option>
            <option value="firstName">First Name</option>
            <option value="lastName">Last Name</option>
            <option value="dob">Date of Birth</option>
            <option value="flags">Flags</option>
          </select>

        </div>
        <div>
          <label>Sort Order:</label>
          <div>
            <button
              className={sortOrder === 'asc' ? 'selectedSortButton' : ''}
              onClick={() => setSortOrder('asc')}
            >
              <FontAwesomeIcon icon={faSortUp} />
              <div className='patientsActionButtonText'>Ascending</div>
            </button>

            <button
              className={sortOrder === 'desc' ? 'selectedSortButton' : ''}
              onClick={() => setSortOrder('desc')}
            >
              <FontAwesomeIcon icon={faSortDown} />
              <div className='patientsActionButtonText'>Descending</div>
            </button>
          </div>
        </div>
        <button onClick={() => setIsSortFilterModalOpen(false)}>Close</button>
      </Modal>
      <button className={`scrollToTopButton ${showScrollToTop ? 'visible' : ''}`} onClick={scrollToTop}>
        <FontAwesomeIcon icon={faArrowUp} />
      </button>
    </>
  );
}

export default PatientList;
