import { useRef, useState, useEffect } from 'react';
import styles from './styles//Home.module.css';
import ReactMarkdown from 'react-markdown';
import LoadingDots from './UI components/loadingdots';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from './UI components/accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import botImage from './UI components/bot-image.png';
import UserIcon from './UI components/usericon.jsx';
import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';
import { storage, auth, db } from '../../firebase';
import { getFunctions, httpsCallable } from "firebase/functions";
import TypingText from './UI components/TypingText';


// Initialize Firebase Functions
const functions = getFunctions();
const callChat = httpsCallable(functions, 'chat');

export default function Chat({ special }) {
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);

  const [messageState, setMessageState] = useState({
    messages: [
      {
        message: 'Hello, I am an AI built for healthcare and trained on healthcare specific information. I was trained on information that represents the consensus in our industry. In a couple of months I will add the ability for you to train me with documents.',
        type: 'apiMessage',
      },
    ],
    history: [],
  });

  const { messages, history } = messageState;

  const messageListRef = useRef(null);
  const textAreaRef = useRef(null);

  //handle form submission
async function handleSubmit(e) {
    e.preventDefault();
  
    setError(null);
  
    if (!query) {
      alert('Please input a question');
      return;
    }
  
    const question = query.trim();
  
    setMessageState((state) => ({
      ...state,
      messages: [
        ...state.messages,
        {
          type: 'userMessage',
          message: question,
        },
      ],
    }));
  
    setLoading(true);
    setQuery('');
    // Check if a user is signed in, if not set uid to 'preloaded'
    const uid = 'preloaded';

    try {
      // Call the Firebase function and pass data to it
      const result = await callChat({
        question: question,
        history: history,
        uid: uid
      });
        
      // result.data should contain the response from your function
  
      if (result.data.error) {
        setError(result.data.error);
      } else {
        setMessageState((state) => ({
          ...state,
          messages: [
            ...state.messages,
            {
              type: 'apiMessage',
              message: result.data.text,
              sourceDocs: result.data.sourceDocuments,
            },
          ],
          history: [...state.history, [question, result.data.text]],
        }));
      }
  
      setLoading(false);
  
      //scroll to bottom
      messageListRef.current?.scrollTo(0, messageListRef.current.scrollHeight);
    } catch (error) {
      setLoading(false);
      setError('An error occurred while fetching the data. Please try again.');
      console.log('error', error);
    }
  }
  

  //prevent empty submissions
  const handleEnter = (e) => {
    if (e.key === 'Enter' && query) {
      handleSubmit(e);
    } else if (e.key == 'Enter') {
      e.preventDefault();
    }
  };

  
  const checkScrollPosition = () => {
    // check if scroll is at the bottom
    if (messageListRef.current) {
      const bottom =
        messageListRef.current.scrollHeight -
        messageListRef.current.scrollTop ===
        messageListRef.current.offsetHeight;
  
      setIsScrolledToBottom(bottom);
    }
  };
  

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.addEventListener("scroll", checkScrollPosition);
    }
    return () => {
      if (messageListRef.current) {
        messageListRef.current.removeEventListener(
          "scroll",
          checkScrollPosition
        );
      }
    };
  }, []);

    // Function to scroll to the bottom of message list
    const scrollToBottom = () => {
      if (messageListRef.current) {
        messageListRef.current.scrollTo(0, messageListRef.current.scrollHeight);
      }
    };

  return (

            <main className={styles.main}>
              <div className={`${styles.cloud} ${special ? styles.cloudSpecial : ''}`}>
                <div ref={messageListRef} className={styles.messagelist}>
                {messages.map((message, index) => {
                  let icon;
                  let className;
                  if (message.type === 'apiMessage') {
                    icon = (
                      <img
                      src={botImage}
                      alt="AI"
                      className={styles.boticon}
                    />
                    );
                  
                    className = `${styles.apimessage}`;
                  } else {
                    icon = <UserIcon />;
                    // The latest message sent by the user will be animated while waiting for a response
                      className =
                        loading && index === messages.length - 1
                          ? styles.usermessagewaiting
                          : styles.usermessage;
                    }
                    return (
                      <>
                        <div key={`chatMessage-${index}`} className={className}>
                          {icon}
                          <div className={styles.markdownanswer}>
                          {message.type === 'apiMessage' 
                                ? <TypingText text={message.message} /> 
                                : <ReactMarkdown linkTarget="_blank">{message.message}</ReactMarkdown>
                            }
                          </div>
                        </div>
                        {message.sourceDocs && (
                        <div
                          className="sources"
                          key={`sourceDocsAccordion-${index}`}
                        >
                          <Accordion
                            type="single"
                            collapsible
                            className="accordion"
                            >
                            {message.sourceDocs.map((doc, index) => (
                              <div key={`messageSourceDocs-${index}`}>
                                <AccordionItem value={`item-${index}`}>
                                  <AccordionTrigger>
                                    <p>Source {index + 1}</p>
                                  </AccordionTrigger>
                                  <AccordionContent>
                                    <ReactMarkdown linkTarget="_blank">
                                      {doc.pageContent}
                                    </ReactMarkdown>
                                    {/* <p className="mt-2">
                                      <b>Source:</b> {doc.metadata.source}
                                    </p> */}
                                  </AccordionContent>
                                </AccordionItem>
                              </div>
                            ))}
                          </Accordion>
                        </div>
                      )}
                      </>
                    );
                  })}
                </div>
              </div>
                    {/* Scroll to Bottom Button */}
                    {!isScrolledToBottom && (
                      <button
                        className="scroll-to-bottom-btn"
                        onClick={scrollToBottom}
                      >
                        <FontAwesomeIcon icon={faArrowCircleDown} />
                      </button>
                    )}
              <div className={styles.center}>
                <div className={styles.cloudform}>
                  <form onSubmit={handleSubmit}>
                    <textarea
                      disabled={loading}
                      onKeyDown={handleEnter}
                      ref={textAreaRef}
                      autoFocus={false}
                      rows={1}
                      maxLength={512}
                      id="userInput"
                      name="userInput"
                      placeholder={
                        loading
                          ? 'Waiting for response...'
                          : 'Send a message.'
                      }
                      value={query}
                      onChange={(e) => setQuery(e.target.value)}
                      className={styles.textarea}
                    />
                    <button
                      disabled={loading}
                      className={styles.generatebutton}
                      alt="submit spectoAI chat message"
                    >
                      {loading ? (
                        <div className={styles.loadingwheel}>
                          <LoadingDots color="#000" />
                        </div>
                      ) : (
                        // Send icon SVG in input field
                        <svg
                            viewBox="0 0 20 20"
                            className={styles.svgicon}
                            xmlns="http://www.w3.org/2000/svg"
                            >
                          <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                            </svg>

                      )}
                    </button>
                  </form>
                </div>
              </div>
              <div className={`disclaimer ${special ? 'disclaimerSpecial' : ''}`}>
                <p style={{ textAlign: 'center' }}>spectoAI might produce inaccurate information. This is not a medical device.</p>
              </div>
              {error && (
                <div className="border border-red-400 rounded-md p-4">
                  <p className="text-red-500">{error}</p>
                </div>
              )}
            </main>

  );
}
