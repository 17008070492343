import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from "../../firebase";
import { doc, getDoc } from 'firebase/firestore';
import './SingleArticle.css';
import Footer from '../Website/Footer';
import SearchBar from '../Articles/SearchBar';
import Header from '../Website/Header';
import { Helmet } from 'react-helmet';


const SingleArticle = () => {
    const [article, setArticle] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        const fetchArticle = async () => {
            const docRef = doc(db, "articles", id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setArticle(docSnap.data());
            } else {
            }
        };
        fetchArticle();
    }, [id]);

    if (!article) {
        return <div>Loading...</div>;
    }

    const keywords = article.keywords ? article.keywords.join(', ') : '';

    return (

        <div>
            <Header />

            <Helmet>
                <meta name="keywords" property="og:keywords" content={keywords} />
                <meta name="author" property="og:type" content="specto Health"></meta>
                <meta name="title" property="og:title" content="specto Health"></meta>
                <meta name="description" property="og:description" content={article.title}/>
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/spectohealth.appspot.com/o/specto_logo_wide.png?alt=media&token=72c8f353-f0f9-463c-966e-4f7b644bea5d" />  
                
                <title>{article.title}</title>
            </Helmet>

            <div className="single-article-container">
                
                <SearchBar />

                <div className="article-content" dangerouslySetInnerHTML={{ __html: article.article }}></div>
            </div>

            <Footer/ >
        </div>
    );
};

export default SingleArticle;
