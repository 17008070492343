import jsPDF from 'jspdf';
import { SHA256 } from 'crypto-js';
import logo from '../../../specto_logos/Single_logo.png';
import { getDoc, doc } from 'firebase/firestore';
import { auth, db } from '../../../firebase';

class PdfGenerator {

  generatePdf(response, user, patientData, signatureData) {

    try {
      const pdf = new jsPDF('p', 'pt', 'a4');
      pdf.setFontSize(12);

      const margins = 20;
      const pageWidth = pdf.internal.pageSize.getWidth();
      const usableWidth = pageWidth - 2 * margins;
      const pageHeight = pdf.internal.pageSize.getHeight();

      const splitResponse = pdf.splitTextToSize(response, usableWidth);
      pdf.text(splitResponse, margins, margins);

      const noteHash = SHA256(response).toString();
      const noteIdText = `Document ID: ${noteHash}`;
      pdf.text(noteIdText, margins, pageHeight - margins - 20);

      const logoWidth = 30;
      const logoHeight = 30;
      const logoX = pageWidth - margins - logoWidth;
      const logoY = pageHeight - margins - logoHeight;
      const imgData = logo;
      pdf.addImage(imgData, 'PNG', logoX, logoY, logoWidth, logoHeight);

      const textBlockHeight = splitResponse.length * 12;

      if (signatureData) {
        const signatureImage = new Image();
        signatureImage.src = signatureData.imageData;
        const signatureWidth = 100 * 1.618;
        const signatureHeight = 100;
        const signatureX = margins;
        const signatureY = margins + textBlockHeight + 80;
        pdf.addImage(signatureImage, 'PNG', signatureX, signatureY, signatureWidth, signatureHeight);
      }

      return pdf;
    } catch (error) {
      console.error('Error creating PDF:', error);
      throw error;
    }
  }

  async downloadPdf(response, patientData, signatureData, uid, subUserUID) {

    const userDocRef = doc(db, 'users', uid);
    const userDoc = await getDoc(userDocRef);
    this.user = userDoc.exists() ? userDoc.data() : {};

    if (!this.user) {
      console.error('Users data is missing or user is not signed in.');
      return;
    }

    this.response = response;
    this.patientData = patientData;
    this.signatureData = signatureData;

    if (!this.response) {
      console.error('Response data is missing or incomplete.');
      return;
    }

    if (!this.patientData || !this.patientData.patient) {
      console.error('Patient data is missing or incomplete.');
      return;
    }

    if (!this.signatureData) {
      console.error('Signature data is missing or incomplete.');
      return;
    }


    try {
      const pdf = this.generatePdf(response, this.user, patientData, signatureData);

      const a = document.createElement('a');
      a.style.display = 'none';
      document.body.appendChild(a);

      const url = window.URL.createObjectURL(new Blob([pdf.output('blob')], { type: 'application/pdf' }));
      a.href = url;

      const doctorName = `${this.user.Name}`;
      const patientName = `${patientData.patient.firstName}_${patientData.patient.lastName}`;
      const currentDate = new Date();
      const formattedDate = `${currentDate.getMonth() + 1}.${currentDate.getDate()}.${currentDate.getFullYear().toString().slice(2)}`;
      a.download = `DoctorsNote_${doctorName}_for_${patientName}__${formattedDate}.pdf`;

      a.click();

      window.URL.revokeObjectURL(url);

      document.body.removeChild(a);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  }
}

export default PdfGenerator;