import React, { useState, useEffect } from "react";
import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import BlankEncounter from "./BlankEncounter";
import HexagonSpinner from "../../General/Animations/Hexspinner";
import { db } from "../../../firebase";
import {
  collection,
  getDocs,
  doc,
  query,
  where,
  getDoc,
  onSnapshot,
  deleteDoc,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import useUID from "../../General/useUID";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faStethoscope,
  faHospital,
  faAmbulance,
  faUserMd,
  faHome,
  faClinicMedical,
  faEye,
  faFileMedical,
  faHandshake,
  faBed,
  faHeartbeat,
  faHandHoldingMedical,
  faHandHoldingHeart,
  faLaptopMedical,
  faBuilding,
  faUserNurse,
  faUserTie,
  faUserInjured,
  faMedkit,
  faShieldAlt,
  faSmile,
  faTimesCircle,
  faFileInvoiceDollar,
  faFileInvoice,
} from "@fortawesome/free-solid-svg-icons";
import { useProviderContext } from "../../../context/ProviderContext";

const faIcons = {
  "Outpatient Visit": faClinicMedical,
  "Inpatient Admission": faHospital,
  "Emergency Room Visit": faAmbulance,
  "Telemedicine Visit": faLaptopMedical,
  "Home Health Visit": faHome,
  "Ambulatory Care": faUserMd,
  "Day Surgery": faClinicMedical,
  Observation: faEye,
  "Ancillary Services": faFileMedical,
  "Follow-up Visit": faHandshake,
  "Preventative Visit": faShieldAlt,
  "Rehabilitation Visit": faHandHoldingMedical,
  "Referral Visit": faBuilding,
  "Urgent Care Visit": faAmbulance,
  "Post-operative Visit": faHeartbeat,
  "Nursing Home Visit": faBed,
  "Routine Check-up": faSmile,
  "Maternity & Antenatal Visits": faUserTie,
};

function Encounters({ patientId, onClose, duration }) {
  const [showBlankEncounter, setShowBlankEncounter] = useState(!!duration);
  const [gettingEncounters, setGettingEncounters] = useState(true);
  const [encounters, setEncounters] = useState([]);
  const [editingEncounter, setEditingEncounter] = useState(null);
  const [providers, setProviders] = useState([]);
  const [uid] = useUID();

  useEffect(() => {
    if (!uid) return;
    if (!patientId) {
      return; // Exit the useEffect if patientId isn't available
    }
    const encountersRef = collection(
      doc(db, "patients", uid, "patientData", patientId),
      "encounters"
    );

    // Create a query for the encounters
    const encountersQuery = query(encountersRef);

    // Set up the real-time listener
    const unsubscribe = onSnapshot(encountersQuery, async (snapshot) => {
      const loadedEncounters = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      // .filter((encounter) => !encounter.isDeleted);

      const providerDataPromises = loadedEncounters.map(async (encounter) => {
        if (!encounter.providerId) {
          // Check if providerId exists
          return {
            organizationName: null,
            firstName: null,
            lastName: null,
          };
        }
        const providerDocRef = doc(
          db,
          "users",
          uid,
          "providers",
          encounter.providerId
        );
        const providerSnapshot = await getDoc(providerDocRef);
        if (providerSnapshot.exists()) {
          const data = providerSnapshot.data();
          return {
            organizationName: data.organizationName,
            firstName: data.firstName,
            lastName: data.lastName,
          };
        }
        return {
          organizationName: null,
          firstName: null,
          lastName: null,
        };
      });

      const providersData = await Promise.all(providerDataPromises);

      const encountersWithProviders = loadedEncounters.map(
        (encounter, index) => ({
          ...encounter,
          ...providersData[index],
        })
      );

      setEncounters(encountersWithProviders);
      setGettingEncounters(false);
    });

    // Clean up the listener on component unmount
    return () => unsubscribe();
  }, [uid, patientId, db]);

  useEffect(() => {
    const fetchProviders = async () => {
      const querySnapshot = await getDocs(
        collection(db, `users/${uid}/providers`)
      );
      const providersData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProviders(providersData);
    };

    if (uid) {
      fetchProviders();
    }
  }, [uid]);

  const handleEncounterFormClose = () => {
    setShowBlankEncounter(false);
    setEditingEncounter(null);
  };

  const handleAddNewEncounter = () => {
    setShowBlankEncounter(true);
  };

  const handleEditEncounter = (encounter) => {
    const selectedProvider = providers.find(
      (provider) => provider.id === encounter.providerId
    );

    setShowBlankEncounter(true);
    setEditingEncounter({
      id: encounter.id,
      ...encounter,
      selectedProvider,
    });
  };

  const handleBillingStatusChange = async (encounterId, newBillingStatus) => {
    try {
      const encounterDocRef = doc(
        db,
        "patients",
        uid,
        "patientData",
        patientId,
        "encounters",
        encounterId
      );

      await updateDoc(encounterDocRef, { BillingStatus: newBillingStatus });
    } catch (error) {
      console.error("Error updating billing status:", error);
    }
  };

  const handleDeleteEncounter = async (encounterId) => {
    try {
      const encounterDocRef = doc(
        db,
        "patients",
        uid,
        "patientData",
        patientId,
        "encounters",
        encounterId
      );

      await deleteDoc(encounterDocRef);
    } catch (error) {
      console.error("Error deleting encounter:", error);
    }
  };

  return (
    <div className="popupContainer">
      <div className="popupContent">
        <button onClick={onClose} className="filesCloseButton">
          X
        </button>

        <div className="container content-center">
          {showBlankEncounter ? (
            <BlankEncounter
              patientId={patientId}
              onClose={handleEncounterFormClose}
              duration={duration}
              encounterData={editingEncounter}
              providers={providers}
            />
          ) : (
            <>
              <div className="my-3">
                <h3>Encounters</h3>
              </div>

              {gettingEncounters ? (
                <div className="grey-card w-100 content-center p-5 mb-4">
                  <HexagonSpinner />
                </div>
              ) : (
                encounters.map((encounter) => (
                  <div
                    className="white-card w-100 mb-4"
                    // className={(encounter.id === selectedencounterId ? "blue-card card-selected" : "white-card") + " card w-100 h-250px content-center py-4"}
                    // onClick={() => handleSelect(encounter.id)}
                    key={encounter.id}
                  >
                    <div className="flex-container p-4">
                      <div>
                        <h3 className="mt-3">
                          {" "}
                          {faIcons[encounter.encounterType] && (
                            <FontAwesomeIcon
                              style={{ height: "32px" }}
                              icon={faIcons[encounter.encounterType]}
                              className="encounterIcon mr-3"
                              title={encounter.encounterType} // Tooltip text
                            />
                          )}{" "}
                           {encounter.encounterType}
                        </h3>

                        <h4 className="mt-3">{encounter.dateOfService}</h4>
                        <h4>
                          {encounter.BillingStatus ? "Billed" : "Not Billed"}
                        </h4>
                      </div>

                      <div className="ml-auto">
                        <button
                          className="secondary mb-3 mr-0 mt-0 m-0"
                          style={{ color: "black" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteEncounter(encounter.id);
                          }}
                        >
                          <FontAwesomeIcon
                            title="Delete Encounter"
                            className=""
                            icon={faTrash}
                          />
                        </button>

                        <button
                          className={`secondary mt-3 mr-0 mb-0 ${
                            encounter.BillingStatus ? "billed" : "not-billed"
                          }`}
                          style={{ color: "black" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            const newBillingStatus = !encounter.BillingStatus;
                            handleBillingStatusChange(
                              encounter.id,
                              newBillingStatus
                            );
                          }}
                        >
                          <FontAwesomeIcon
                            icon={
                              encounter.BillingStatus
                                ? faFileInvoiceDollar
                                : faFileInvoice
                            }
                            title={
                              encounter.BillingStatus ? "Billed" : "Not Billed"
                            }
                          />
                        </button>

                        <button
                          className="secondary mt-3 mr-0 mb-0"
                          style={{ color: "black" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditEncounter(encounter); // Pass the entire encounter object
                          }}
                        >
                          <FontAwesomeIcon
                            title="Edit"
                            className=""
                            icon={faEdit}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              )}

              <div
                className="white-card w-100 content-center py-4"
                onClick={handleAddNewEncounter}
              >
                <FontAwesomeIcon icon={faPlus} style={{ height: "32px" }} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Encounters;
