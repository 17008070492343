import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../../firebase'; // Adjust this path to your Firebase config file
import styles from './PatientView.module.css';

const BPReadingCards = ({ uid, patientId }) => {
  const [readings, setReadings] = useState([]);

  useEffect(() => {
    const fetchReadings = async () => {
      const readingsRef = collection(db, `patients/${uid}/patientData/${patientId}/TenoviDevice`);
      const q = query(readingsRef, orderBy("timestamp", "asc"));

      try {
        const querySnapshot = await getDocs(q);
        const fetchedReadings = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          formattedTimestamp: new Date(doc.data().timestamp).toLocaleString()
        }));

        setReadings(fetchedReadings);
      } catch (error) {
        console.error("Error fetching readings: ", error);
      }
    };

    fetchReadings();
  }, [uid, patientId]);

  const renderReadingValue = (reading) => {
    if (reading.metric === 'pulse') {
      return `Pulse: ${reading.value_1}`;
    } else if (reading.metric === 'blood_pressure') {
      return `Blood Pressure: ${reading.value_1}/${reading.value_2}`;
    } else {
      return `Reading: ${reading.value_1}`; // Default or unknown metric case
    }
  };

  return (
    <div className='card'>
      {readings.length > 0 ? (
        readings.map((reading) => (
          <div key={reading.id} className={styles.patientViewCard}>
            <p>Timestamp: {reading.formattedTimestamp}</p>
            <p>{renderReadingValue(reading)}</p>
          </div>
        ))
      ) : (
        <p></p>
      )}
    </div>
  );
};

export default BPReadingCards;
