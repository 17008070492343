import React, { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import HexagonSpinner from '../General/Animations/Hexspinner';
import useUID from '../General/useUID';
import ClaimStatusCategoryFlag from '../ClaimStatus/ClaimStatusCategoryFlag';
import styles from './Claims.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const formatDOB = (dob) => {
  if (!dob || dob.length !== 8) return dob;
  return `${dob.substring(4,6)}/${dob.substring(6,8)}/${dob.substring(0,4)}`;
};

const ClaimsTable = ({ onClose }) => {
  const [loading, setLoading] = useState(true);
  const [claimsData, setClaimsData] = useState([]);
  const [selectedClaimId, setSelectedClaimId] = useState(null);
  const [uid, subUserUID] = useUID();
  const [searchInput, setSearchInput] = useState('');
  const [filterPopupVisible, setFilterPopupVisible] = useState(false);
  const [selectedPayers, setSelectedPayers] = useState(new Set());
  const [selectedProviders, setSelectedProviders] = useState(new Set());
  const [showFilters, setShowFilters] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!uid) return;
    const fetchClaims = async () => {
      const functions = getFunctions();
      const fetchClaimsData = httpsCallable(functions, 'fetchClaimsData');

      try {
        const response = await fetchClaimsData({ uid });
        console.log(response)
        setClaimsData(response.data);
      } catch (error) {
        console.error("Error fetching claims data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchClaims();
  }, [uid]);

  const toggleSelectedClaim = (claimId) => {
    setSelectedClaimId(selectedClaimId === claimId ? null : claimId);
  };

  const findServiceDetails = (procedureId, serviceDetails) => {
    return serviceDetails.find(detail => detail.procedureId === procedureId);
  };

  // Utility function to sort statuses by updatedAt in descending order
    const sortStatusesByDate = (statuses) => {
        return statuses.sort((a, b) => 
        new Date(b.mainStatus.updatedAt) - new Date(a.mainStatus.updatedAt)
        );
    };

    // Utility function to format timestamp
    const formatTimestamp = (timestamp) => {
        if (!timestamp || !timestamp._seconds) return '';
    
        return new Date(timestamp._seconds * 1000).toLocaleDateString();
    };

     // Function to handle search input changes
    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value.toLowerCase());
    };

    // Function to get unique payers and providers from claims data
  const getUniqueOptions = (data, key) => {
    return [...new Set(data.map(claim => claim[key]))];
  };

  const uniquePayers = getUniqueOptions(claimsData, 'receiver');
  const uniqueProviders = getUniqueOptions(claimsData, 'provider');

  // Function to handle payer/provider checkbox changes
  const handleFilterChange = (value, type) => {
    const updateSet = new Set(type === 'payer' ? selectedPayers : selectedProviders);
    if (updateSet.has(value)) {
      updateSet.delete(value);
    } else {
      updateSet.add(value);
    }
    type === 'payer' ? setSelectedPayers(updateSet) : setSelectedProviders(updateSet);
  };

    // Filtered data based on search input and selected filters
    const filteredClaimsData = claimsData.filter(claim => {
      const searchMatch = searchInput.toLowerCase();
      const dobFormatted = formatDOB(claim.dateOfBirth);
      const submittedDateFormatted = formatTimestamp(claim.timestamp);
      const serviceDateFormatted = formatDOB(claim.serviceLines[0]?.serviceDate || '');
    
      return (
        claim.firstName.toLowerCase().includes(searchMatch) ||
        claim.lastName.toLowerCase().includes(searchMatch) ||
        claim.receiver.toLowerCase().includes(searchMatch) ||
        claim.provider.toLowerCase().includes(searchMatch) ||
        claim.memberId.toLowerCase().includes(searchMatch) ||
        dobFormatted.includes(searchMatch) ||
        submittedDateFormatted.includes(searchMatch) ||
        serviceDateFormatted.includes(searchMatch) ||
        claim.serviceLines.some(line => line.professionalService?.procedureCode.toLowerCase().includes(searchMatch)) ||
        claim.diagnosisCodes.some(code => code.toLowerCase().includes(searchMatch))
      );
    });

  if (loading) {
    return (
      <div>
        <HexagonSpinner />
        <h4>Loading every detail of every last claim.</h4>
      </div>
    );
  }
  const toggleFilterModal = () => setShowFilters(!showFilters);

  const handleMoreInfoClick = (claimId) => {
    navigate(`/claims/${claimId}`);
  };
  
  return (
    <div className="Claims-inner-container" style={{ display: 'flex', alignItems: 'flex-start' }}>
    <button className="filesCloseButton" onClick={onClose}>
            X
      </button> 
      {/* Filter Modal */}
      {showFilters && (
        <div className='overlay'>
          <div className='modalBox'>
            <h4>Filter by Payer</h4>
            {uniquePayers.map(payer => (
              <div key={payer}>
                <input
                  type="checkbox"
                  className='custom-checkbox'
                  checked={selectedPayers.has(payer)}
                  onChange={() => handleFilterChange(payer, 'payer')}
                />
                {payer}
              </div>
            ))}
            <h4>Filter by Provider</h4>
            {uniqueProviders.map(provider => (
              <div key={provider}>
                <input
                  type="checkbox"
                  className='custom-checkbox'                  
                  checked={selectedProviders.has(provider)}
                  onChange={() => handleFilterChange(provider, 'provider')}
                />
                {provider}
              </div>
            ))}
            <button onClick={toggleFilterModal}>Close</button>
          </div>
        </div>
      )}
     
      <div className='claimsTableContainer' >  
      <h3>Claims Table</h3>
      <button onClick={toggleFilterModal}>Filter</button>
      <input
        type="text"
        placeholder="Search claims..."
        value={searchInput}
        onChange={handleSearchInputChange}
        className={styles.searchInput} // Add CSS as needed
      />
      <table className={styles.claimsDataTable}>
        <thead>
          <tr>
            <th className={styles.claimsDataHeader}>Patient</th>
            <th className={styles.claimsDataHeader}>Insurance</th>
            <th className={styles.claimsDataHeader}>Status</th>
            <th className={styles.claimsDataHeader}>Charge Amount</th>
            <th className={styles.claimsDataHeader}>Billing Codes</th>
            <th className={styles.claimsDataHeader}>Date of Service</th>
            <th className={styles.claimsDataHeader}>Provider</th>
            <th className={styles.claimsDataHeader}>Submitted</th>

          </tr>
        </thead>
        <tbody>
        {filteredClaimsData.map((claim, index) => {
            const sortedStatuses = sortStatusesByDate(claim.statuses);
            const latestStatus = sortedStatuses[0]?.mainStatus.statusCategoryCode || 'N/A';
  
            return (
              <React.Fragment key={index}>
                <tr onClick={() => toggleSelectedClaim(claim.claimId)}>
                <td>
                {claim.firstName} {claim.middleName ? `${claim.middleName} ` : ''}{claim.lastName}
                <br />
                {claim.gender} | DOB: {formatDOB(claim.dateOfBirth)}
                </td>
                  <td>{claim.receiver} 
                  <br />
                     {claim.memberId}
                    </td>
                  <td><ClaimStatusCategoryFlag statusCategoryCode={latestStatus} /></td>
                  <td>{'$'}{claim.amount}</td>
                  <td>{claim.serviceLines.map(line => line.professionalService?.procedureCode).join(', ')}</td>
                  <td>{formatDOB(claim.serviceLines[0]?.serviceDate || '')}</td>
                  <td>{claim.provider}</td>
                  <td>{formatTimestamp(claim.timestamp)}</td>
                  <td> 
                    <button
                    onClick={() => handleMoreInfoClick(claim.claimId)}
                     className="moreInfoButton" // Add suitable CSS class
                      >
                      <FontAwesomeIcon icon={faInfoCircle } style={{ marginRight: '5px' }}/>
                        More Information
                   </button></td>
                </tr>
                {selectedClaimId === claim.claimId && (
                  <tr>
                    <td colSpan="6">
                      <table>
                        <thead>
                          <tr>
                            <th>Procedure Code</th>
                            <th>Diagnosis Codes</th>
                            <th>Amount Charged</th>
                            <th>Amount Paid</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {claim.serviceLines.map((line, idx) => {
                            const detail = findServiceDetails(line.professionalService.procedureCode, claim.statuses.flatMap(status => status.serviceDetails || []));
                            return (
                              <tr key={idx}>
                                <td>{line.professionalService.procedureCode}</td>
                                <td>{claim.diagnosisCodes.join(', ')}</td>
                                <td>${line.professionalService.lineItemChargeAmount}</td>
                                <td>${detail?.amountPaidService || 'N/A'}</td>
                                <td><ClaimStatusCategoryFlag statusCategoryCode={detail?.statusCategoryCode} />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      </div>
    </div>
  );
};

export default ClaimsTable;
