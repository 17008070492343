import Topbar from "../General/Topbar";
import BatchEligibility from "./BatchEligibility";
import "./Eligibility.css";
import FileBrowser from "./FileBrowser";

function Eligibility() {
  return (
    <div>
     <Topbar/>
     <FileBrowser />
      <BatchEligibility />
    </div>
  );
}

export default Eligibility;