import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, getDocs, collection, query, where, limit, orderBy, onSnapshot } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCircleCheck } from '@fortawesome/free-solid-svg-icons';
import useUID from '../General/useUID';
import { storage, auth, db } from '../../firebase';

function ClaimsViewClaimStatus({ claimId, patientId }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [requestData, setRequestData] = useState(null);
  const [hasRemainingRequests, setHasRemainingRequests] = useState(true);
  const [uid, subUserUID, isLoading] = useUID();
  const [claimResults, setClaimResults] = useState(null);

  function formatDate(dateStr) {
    // Check if the dateStr is exactly 8 characters (YYYYMMDD)
    if (dateStr && dateStr.length === 8) {
      const year = dateStr.substring(0, 4);
      const month = dateStr.substring(4, 6);
      const day = dateStr.substring(6, 8);

      // Reformat to MM/DD/YYYY
      return `${month}/${day}/${year}`;
    }
    return '';
  }

  const handleRunClaimStatus = async () => {
    setLoading(true);
    setError(null);

    // Get the Firestore instance
    const db = getFirestore();

    // Reference to the specific claim's document
    const claimDocRef = doc(db, `patients/${uid}/patientData/${patientId}/claims/${claimId}`);

    try {
      // Fetch the document for the specific claim
      const claimDocSnap = await getDoc(claimDocRef);

      if (claimDocSnap.exists()) {
        const claimData = claimDocSnap.data().requestData;
        setRequestData(claimData);

        const { subscriber, claimInformation, providers, tradingPartnerServiceId, tradingPartnerName } = claimData;
        const { firstName, lastName, gender, memberId } = subscriber;
        const claimChargeAmount = claimInformation.claimChargeAmount;
        const providerOrganizationName = claimInformation.serviceFacilityLocation.organizationName
        const convertedtradingPartnerServiceId = await getConvertedTradingID(tradingPartnerName);

        let providerNPI, billingOrganizationName, billingEIN;

        // Extract the provider information
        providers.forEach(provider => {
          if (provider.providerType === "BillingProvider") {
            billingOrganizationName = provider.organizationName;
            billingEIN = provider.employerId;
          }

          if (provider.providerType === "RenderingProvider" || provider.providerType === "ReferringProvider") {
            providerNPI = provider.npi;
          }
        });

        // Format dates
        const formattedDob = formatDate(subscriber.dateOfBirth);
        const formattedDos = formatDate(claimInformation.serviceLines[0].serviceDate);

        // Set up input data for the Firebase function
        const inputData = {
          memberId,
          firstName,
          lastName,
          gender,
          dob: formattedDob,
          dos: formattedDos,
          providerOrganizationName,
          providerNPI,
          billingOrganizationName,
          billingEIN,
          tradingPartnerServiceId: convertedtradingPartnerServiceId,
          uid,
          patientId,
          associatedClaimID: claimId,
          claimChargeAmount
        };

        // // Call the Firebase function to get the claim status
        const functions = getFunctions();
        const getClaimStatusFunction = httpsCallable(functions, 'getClaimStatus');
        const response = await getClaimStatusFunction(inputData);

        setClaimResults(response.data);

      } else {
        setError('Claim data not found');
        setRequestData(null);
      }
    } catch (err) {
      // setError(err.message || 'An error occurred while fetching claim data.');
    } finally {
      setLoading(false);
    }
  };

  async function getConvertedTradingID(tradingPartnerName) {
    try {
      // Create a query against the Payers collection using the tradingPartnerName
      const payersRef = collection(db, 'Payers');
      const q = query(payersRef, where('payerName', '==', tradingPartnerName));

      const snapshot = await getDocs(q);

      if (!snapshot.empty) {
        const realtimePayerID = snapshot.docs[0].data().RealtimePayerID;

        if (realtimePayerID) {
          return realtimePayerID;
        } else {
          throw new Error('This payer does not support single click claim status at this time.');
        }
      } else {
        console.log('No matching payer found for:', tradingPartnerName);
        return null;
      }
    } catch (error) {
      console.error('Error fetching Realtime Payer ID:', error);
      throw error;
    }
  }

  useEffect(() => {
    const fetchAccountTierAndCheckRemainingRequests = async () => {
      const uid = auth.currentUser.uid;
      const userDocRef = doc(db, "users", uid);
      const userDocSnap = await getDoc(userDocRef);

      const AccountTier = userDocSnap.data().AccountTier || '';

      const requestsRef = collection(db, "users", uid, "API");
      const q = query(requestsRef, orderBy("timestamp", "desc"));

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let total = 0;
        querySnapshot.forEach((doc) => {
          const requestData = doc.data();

          if (requestData.APIRequests) {
            const apiRequestsCount = parseInt(requestData.APIRequests, 10);
            total += apiRequestsCount;
          }
        });

        if (AccountTier === 'Freebie' && total >= 25) {
          setHasRemainingRequests(false);
        } else {
          setHasRemainingRequests(true);
        }
      });

      return () => unsubscribe();
    };

    if (auth.currentUser) {
      fetchAccountTierAndCheckRemainingRequests();
    }
  }, [auth.currentUser]);

  return (
    <>
      <button
        onClick={handleRunClaimStatus}
        className="btn btn-outline-secondary"
        disabled={loading}
        title="Check Claim Status on this claim"
      >
        {loading ? 'Checking...' : <FontAwesomeIcon icon={faFileCircleCheck} style={{ marginRight: '5px' }} />}
        Check Status
      </button>

      {error === 'claimNotFound' && (
        <div className="errorContainer">
          <h2>Claim not found.</h2>
        </div>
      )}

      <div className='patientDataPopupContainer' style={{ display: claimResults ? 'block' : 'none' }}>

        {claimResults && (
          <div className='results'>
            <div className='resultsContainer'>
              <button
                type="button"
                className='filesCloseButton'
                onClick={() => setClaimResults(null)}
              >
                X
              </button>
              {claimResults.patientData && claimResults.allClaims.length > 0 && (
                <div>
                  <h3>Claims:</h3>
                  <table>
                    <thead>
                      <tr>
                        <th>Status Category Code Value</th>
                        <th>Status Code Value</th>
                        <th>Submitted Amount</th>
                        <th>Amount Paid</th>
                        <th>Paid Date</th>
                        <th>Check Number</th>
                        <th>Tracking Number</th>
                        <th>Claim Service Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {claimResults.allClaims.map((claim, index) => (
                        <tr key={index}>
                          <td>{claim.mainStatus.statusCategoryCodeValue || 'N/A'}</td>
                          <td>{claim.mainStatus.statusCodeValue || 'N/A'}</td>
                          <td>{'$'}{claim.mainStatus.submittedAmount || '0.00'}</td>
                          <td>{'$'}{claim.mainStatus.amountPaid || '0.00'}</td>
                          <td>{claim.mainStatus.paidDate ? formatDate(claim.mainStatus.paidDate) : 'N/A'}</td>
                          <td>{claim.mainStatus.checkNumber || 'N/A'}</td>
                          <td>{claim.mainStatus.trackingNumber || 'N/A'}</td>
                          <td>
                            {claim.mainStatus.claimServiceDate
                              ? (claim.mainStatus.claimServiceDate.includes('-')
                                ? claim.mainStatus.claimServiceDate.split('-').map(dateStr => formatDate(dateStr)).join(' - ')
                                : formatDate(claim.mainStatus.claimServiceDate))
                              : 'N/A'}
                          </td>
                        </tr>
                      ))}

                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ClaimsViewClaimStatus;
