const mapPatientDataForDownload = (patient) => {

    // Utility function to convert date format from 'YYYYMMDD' to 'MM/DD/YYYY'
    function formatDate(dateString) {
        if (dateString && dateString.length === 8) {
        const formattedDate = `${dateString.substring(4, 6)}/${dateString.substring(6, 8)}/${dateString.substring(0, 4)}`;
        return formattedDate;
        }
        return dateString;
    }

    const capitalizeName = (name) => {
        if (!name) return '';
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    };

    console.log(patient)

       // Initialize flattenedData with basic patient info
    const flattenedData = {
        firstName: `${capitalizeName(patient.patient.firstName)}`,
        middleName: `${capitalizeName(patient.patient.middleName)} `,
        lastName: `${capitalizeName(patient.patient.lastName)}`,
        fullName: `${capitalizeName(patient.patient.firstName)} ${capitalizeName(patient.patient.middleName)} ${capitalizeName(patient.patient.lastName)}`,
        memberId: patient.payers?.memberId || '',
        insurance: patient.payers?.name || '',
        dob: patient.patient.dob || '',
        phone: patient.patient.phone || '',
        email: patient.patient.email || '',
        address1: `${patient.patient.address.address1 || ''}`,
        address2: `${patient.patient.address.address2 || ''}`,
        city: `${patient.patient.address.city || ''}`,
        state: `${patient.patient.address.state || ''}`,
        zip: `${patient.patient.address.zip}`,        
        flag: patient.flag || '',
        // Add other basic patient info if needed
    };
  
     
    const formatClaims = (claims) => {
        return claims.map(claim => {
            return {
                controlNumber: claim.requestData?.controlNumber || 'N/A',
                organizationName: claim.requestData?.receiver?.organizationName || 'N/A',
                serviceProvider: claim.requestData?.claimInformation?.serviceFacilityLocation?.organizationName || 'N/A',
                diagnosisCodes: claim.requestData?.claimInformation?.healthCareCodeInformation?.map(info => info.diagnosisCode) || ['N/A'],
                procedureCodes: claim.requestData?.claimInformation?.serviceLines?.map(line => line.professionalService?.procedureCode) || ['N/A'],
                lineItemChargeAmount: claim.requestData?.claimInformation?.serviceLines?.map(line => line.professionalService?.lineItemChargeAmount) || ['N/A'],
                serviceDates: claim.requestData?.claimInformation?.serviceLines?.map(line => formatDate(line.serviceDate)) || ['N/A']
            };
        });
    };
    
    flattenedData.claims = patient.claims ? formatClaims(patient.claims) : [];
    

    const formatClaimStatuses = (claimStatuses) => {
        return claimStatuses.map(status => {
            return {
                amountPaid: status.mainStatus?.amountPaid || 'N/A',
                statusCodeValue: status.mainStatus?.statusCodeValue || 'N/A',
                submittedAmount: status.mainStatus?.submittedAmount || 'N/A',
                trackingNumber: status.mainStatus?.trackingNumber || 'N/A',
                entity: status.mainStatus?.entity || 'N/A',
                statusCategoryCodeValue: status.mainStatus?.statusCategoryCodeValue || 'N/A',
                claimServiceDate: formatDate(status.mainStatus?.claimServiceDate) || 'N/A',
                controlNumber: status.mainStatus?.controlNumber || 'N/A',
                checkIssueDate: status.mainStatus?.checkIssueDate || 'N/A',
                checkNumber: status.mainStatus?.checkNumber || 'N/A',
                effectiveDate: status.mainStatus?.effectiveDate || 'N/A',
                paidDate: status.mainStatus?.paidDate || 'N/A',
                tradingPartnerClaimNumber: status.mainStatus?.tradingPartnerClaimNumber || 'N/A',
                // Additional fields for service details can be added here
                serviceDetails: status.serviceDetails ? status.serviceDetails.map(serviceDetail => {
                    return {
                        amountPaidService: serviceDetail.amountPaidService || 'N/A',
                        effectiveDateService: serviceDetail.effectiveDateService || 'N/A',
                        procedureId: serviceDetail.procedureId || 'N/A',
                        serviceIdQualifier: serviceDetail.serviceIdQualifier || 'N/A',
                        statusCategoryCodeValueService: serviceDetail.statusCategoryCodeValue || 'N/A',
                        statusCodeValueService: serviceDetail.statusCodeValue || 'N/A',
                        submittedAmountService: serviceDetail.submittedAmountService || 'N/A',
                        submittedUnits: serviceDetail.submittedUnits || 'N/A',
                        // Add more service detail fields here as needed
                    };
                }) : []
            };
        });
    };
    
    flattenedData.claimStatus = patient.claimStatus ? formatClaimStatuses(patient.claimStatus) : [];    
    
    const formatEligibility = (eligibilityData) => {
        return eligibilityData.map(eligibility => {
            return {
                insuranceStatus: eligibility.insuranceStatus || 'N/A',
                insuranceName: eligibility.insuranceName || 'N/A',
                coverageLevel: eligibility.coverageLevel || 'N/A',
                insuranceType: eligibility.insuranceType || 'N/A',
                payerID: eligibility.payerID || 'N/A',
                planDateInformation: eligibility.planDateInformation || 'N/A',
                planDetails: eligibility.planDetails || 'N/A',
                providerName: eligibility.providerName || 'N/A',
                serviceTypeCodes: eligibility.serviceTypeCodes || 'N/A',
                subentityIdentifier: eligibility.subentityIdentifier || 'N/A',
                subentityType: eligibility.subentityType || 'N/A',
                subfirstName: eligibility.subfirstName || 'N/A',
                sublastName: eligibility.sublastName || 'N/A',
                // Additional fields can be added here as needed
            };
        });
    };
    
    flattenedData.eligibility = patient.eligibility ? formatEligibility(patient.eligibility) : [];
    

    const formatEncounter = (encounterData) => {
        return encounterData.map(encounter => {
            return {
                diagnosisCode: encounter.diagnosisCode || 'N/A',
                encounterType: encounter.encounterType || 'N/A',
                reasonForVisit: encounter.reasonForVisit || 'N/A',
                service: encounter.service || 'N/A',
                dateOfService: encounter.dateOfService ? formatDate(encounter.dateOfService) : 'N/A',
                notes: encounter.notes || 'N/A',
                organizationName: encounter.organizationName || 'N/A',
                providerfirstName: encounter.providerfirstName || 'N/A',
                providerlastName: encounter.providerlastName || 'N/A',
            };
        });
    };
    
    flattenedData.encounters = patient.encounters ? formatEncounter(patient.encounters) : [];
    

    const formatNotes = (notesData) => {
        return notesData.map(note => ({ text: note.text || 'N/A' }));
    };
    
    flattenedData.notes = patient.notes ? formatNotes(patient.notes) : [];
    
    const formatMedicalHistory = (medicalHistory) => {
        return medicalHistory.map(record => {
            switch (record.type) {
                case 'ImmunizationsAllergies':
                    return {
                        type: 'ImmunizationsAllergies',
                        immunizations: record.immunizations || 'N/A',
                        allergies: record.allergies || 'N/A'
                    };
    
                case 'vitals':
                    const vitals = Object.keys(record)
                        .filter(key => ['bloodPressure', 'bmi', 'heartRate', 'oxygenSaturation', 'respiratoryRate', 'temperature', 'weight'].includes(key))
                        .reduce((obj, key) => ({ ...obj, [key]: record[key] || 'N/A' }), {});
                    return { type: 'vitals', ...vitals };
    
                case 'LabAndTest':
                    const labTests = ['notes', 'testDate', 'testName', 'testResults']
                        .reduce((obj, field) => ({ ...obj, [field]: record[field] || 'N/A' }), {});
                    return { type: 'LabAndTest', ...labTests };
    
                case 'Medications':
                    const medications = ['dosage', 'endDate', 'frequency', 'medicationName', 'notes', 'startDate']
                        .reduce((obj, field) => ({ ...obj, [field]: record[field] || 'N/A' }), {});
                    return { type: 'Medications', ...medications };
                    case 'FamilyHistory':
                        const familyHistoryFormatted = {};
                        Object.keys(record).forEach(key => {
                            if (key !== 'type' && key !== 'timestamp') {
                                familyHistoryFormatted[key] = record[key].join(', ');
                            }
                        });
                        return {
                            type: 'FamilyHistory',
                            ...familyHistoryFormatted
                        };
                    default:
                        return { type: 'Unknown', details: 'Unknown Medical History Record' };
                }
            });
        };
    
    flattenedData.medicalHistory = patient.medicalHistory ? formatMedicalHistory(patient.medicalHistory) : [];
    


        

// Add any additional transformations or data aggregations here
return flattenedData;

};

export default mapPatientDataForDownload;