import React, { useState } from 'react';
import styles from './Claims.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup, faFileCircleCheck, faMagnifyingGlassDollar, faTable, faCalendar, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import BatchClaims from './BatchClaims';
import ClaimsTable from './ClaimsTable';
import ClaimsCalendar from './ClaimsCalendar';
import { useNavigate } from 'react-router-dom';
import PayerMatchingModal from '../PatientFiles/AddPatient/PayerMatching';
import SingleClaim from './SingleClaim';
import ProvidersMenu from './ProvidersMenu';
import CodesMenu from './CodesMenu';
import PaymentPosting from './PaymentPosting';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

function ToolsBar() {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isTableOpen, setIsTableOpen] = useState(false);
  const [patientData, setPatientData] = useState(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isPayerMatchingOpen, setIsPayerMatchingOpen] = useState(false);
  const [showProvidersMenu, setShowProvidersMenu] = useState(false);
  const [showCodesMenu, setShowCodesMenu] = useState(false);
  const [isToolsDropdownOpen, setIsToolsDropdownOpen] = useState(false);
  const [isConfigDropdownOpen, setIsConfigDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const [isSingleClaimOpen, setIsSingleClaimOpen] = useState(false);

  // Define the toggle functions
  const toggleToolsDropdown = () => setIsToolsDropdownOpen(!isToolsDropdownOpen);
  const toggleConfigDropdown = () => setIsConfigDropdownOpen(!isConfigDropdownOpen);

  // Update toggle functions to close dropdowns
  const toggleSearchPopup = () => {
    setIsSearchOpen(!isSearchOpen);
    setIsToolsDropdownOpen(false);
  };
  const togglePayerMatching = () => {
    setIsPayerMatchingOpen(!isPayerMatchingOpen);
    setIsConfigDropdownOpen(false);
  };
  const toggleTablePopup = () => {
    setIsTableOpen(!isTableOpen);
    setIsToolsDropdownOpen(false);
  };
  const toggleCalendarPopup = () => {
    setIsCalendarOpen(!isCalendarOpen);
    setIsToolsDropdownOpen(false);
  };

  const toggleSingleClaim = () => {
    setIsSingleClaimOpen(!isSingleClaimOpen);
  };

  const closePatientDataPopup = () => {
    setIsSearchOpen(false);
    // Any additional logic you need when closing BatchClaims
  };

  // Function to capture the screen and generate PDF
  const downloadPdf = () => {
    const input = document.getElementById('dashboardContent');

    if (!input) {
      console.error('Element to capture not found');
      return;
    }

    html2canvas(input, {
      scale: 2, // Increase scale for higher resolution
      useCORS: true // If you have images that are cross-origin
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "px",
          format: [canvas.width, canvas.height]
        });
        pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);

        // Get current date and format it
        const today = new Date();
        const formattedDate = today.toLocaleDateString('en-US', {
          month: 'short', day: '2-digit', year: 'numeric'
        }).replace(/ /g, '/'); // Replace spaces with slashes

        // Save the PDF with the formatted date in the filename
        pdf.save(`claimsReport_${formattedDate}.pdf`);
      })
      .catch(err => console.error('Error: ', err));
  };


  const buttonClass = `MainActionButton ${isSearchOpen ? 'selected' : ''}`;
  const buttonClassTable = `MainActionButton ${isTableOpen ? 'selected' : ''}`;
  const buttonClassCalendar = `MainActionButton ${isCalendarOpen ? 'selected' : ''}`;
  const buttonClassMatching = `MainActionButton ${isPayerMatchingOpen ? 'selected' : ''}`;

  const dropdownItemClass = 'dropdown-item';

  return (
    <div className='toolsBar'>
      {/* <SearchBarClaims /> */}

      <div className="dropdownContainer">
        <button className="dropbtn" onClick={toggleToolsDropdown}>Tools</button>
        {isToolsDropdownOpen && (
          <div className="dropdownClaims">
            <button onClick={toggleSearchPopup} className={buttonClass}>
              <FontAwesomeIcon icon={faLayerGroup} style={{ marginRight: '10px' }} />
              Batch Claims
            </button>
            <button onClick={toggleSingleClaim} className={buttonClass}>
              <FontAwesomeIcon icon={faLayerGroup} style={{ marginRight: '10px' }} />
              Single Claims
            </button>
            <button onClick={toggleTablePopup} className={buttonClassTable}>
              <FontAwesomeIcon icon={faTable} style={{ marginRight: '10px' }} />
              Claims Table
            </button>
            <button onClick={toggleCalendarPopup} className={buttonClassCalendar}>
              <FontAwesomeIcon icon={faCalendar} style={{ marginRight: '10px' }} />
              Claims Calendar
            </button>
            <button onClick={() => navigate('/claimstatus')} className="MainActionButton">
              <FontAwesomeIcon icon={faFileCircleCheck} style={{ marginRight: '10px' }} />
              Claim Status
            </button>
            <button onClick={() => navigate('/eligibility')} className="MainActionButton">
              <FontAwesomeIcon icon={faMagnifyingGlassDollar} style={{ marginRight: '10px' }} />
              Eligibility
            </button>
          </div>
        )}
      </div>

      <div className="dropdownContainer">
        <button className="dropbtn" onClick={toggleConfigDropdown}>Configuration</button>
        {isConfigDropdownOpen && (
          <div className="dropdownClaims">
            <button onClick={togglePayerMatching} className={buttonClassMatching}>
              <FontAwesomeIcon icon={faLayerGroup} style={{ marginRight: '10px' }} />
              Payer Matching for Batch Uploads
            </button>
            <ProvidersMenu
              showProviderSelection={showProvidersMenu}
              onClose={() => setShowProvidersMenu(false)}
            />

            <CodesMenu
              showBillingCodes={showCodesMenu}
              onClose={() => setShowCodesMenu(false)}
            />

          </div>
        )}
      </div>

      <PaymentPosting />

      {isTableOpen && <div className='claims-inner-container'><ClaimsTable onClose={() => setIsTableOpen(false)} /></div>}
      {isCalendarOpen && <ClaimsCalendar onClose={() => setIsCalendarOpen(false)} />}
      {isPayerMatchingOpen && <PayerMatchingModal onClose={() => setIsPayerMatchingOpen(false)} />}
      {isSearchOpen && <BatchClaims selectedPatientsData={patientData} onClose={closePatientDataPopup} />}
      {isSingleClaimOpen &&
        <><button onClick={toggleSingleClaim} className="filesCloseButton">X</button>
          <SingleClaim onClose={toggleSingleClaim} /></>}

      <button onClick={downloadPdf} className="pdf-download-button">
        <FontAwesomeIcon icon={faFilePdf} />
      </button>

    </div>
  );
}

export default ToolsBar;
