import React, { useState, useEffect } from "react";
import Topbar from "../General/Topbar";
import PatientList from "./DevicePatientList";
import Dashboard from "./Dashboard";
import useUID from '../General/useUID';
import { collection, getDocs, query, where, orderBy, limit } from 'firebase/firestore';
import { db } from '../../firebase';
import { subDays, formatISO } from 'date-fns';

function Monitoring() {
  const [patients, setPatients] = useState([]);
  const [uid] = useUID();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPatients = async () => {
      if (!uid) return;
  
      setLoading(true);
      try {
        const patientRef = collection(db, 'patients', uid, 'patientData');
        const patientSnapshot = await getDocs(patientRef);
        const twoDaysAgo = subDays(new Date(), 1);
  
        const patientsList = await Promise.all(patientSnapshot.docs.map(async patientDoc => {
          const terraRef = collection(patientDoc.ref, 'Terra');
          const terraSnapshot = await getDocs(terraRef);
  
          let lastActiveDate = null;
  
          if (!terraSnapshot.empty) {
            const patientData = patientDoc.data().patient;
            const payersData = patientDoc.data().payers;
            const isDeleted = patientDoc.data().deleted;
            let isActive;

            for (const terraDoc of terraSnapshot.docs) {
              const deviceDataRef = collection(terraDoc.ref, 'DeviceData');
              const q = query(deviceDataRef, orderBy("timestamp", "desc"), limit(1));
              const querySnapshot = await getDocs(q);
  
              if (!querySnapshot.empty) {
                const mostRecentDeviceData = querySnapshot.docs[0].data();
                const mostRecentTimestamp = mostRecentDeviceData.timestamp.toDate();
                if (mostRecentTimestamp > twoDaysAgo) {
                  isActive = true; // Consider active if any device data is from less than a day ago
                }
                lastActiveDate = formatISO(mostRecentTimestamp);
                if (isActive) break; // Break the loop if the patient is active
              }
            }
  
            return {
              id: patientDoc.id,
              firstName: patientData.firstName,
              middleName: patientData.middleName,
              lastName: patientData.lastName,
              dob: patientData.dob,
              gender: patientData.gender,
              insurance: payersData?.name,
              memberId: payersData?.memberId,
              isActive,
              statusDetails: isActive ? 'Active' : `Inactive since ${lastActiveDate}`,
              isDeleted
            };
          }
          return null;
        }));
  
        const filteredPatientsList = patientsList.filter(patient => patient !== null);
        setPatients(filteredPatientsList);
      } catch (error) {
        console.error("Error fetching patient data:", error);
      }
      setLoading(false);
    };
  
    fetchPatients();
  }, [uid]);

  return (
    <div className="aibackground">
      <Topbar />
      <Dashboard />
      <PatientList patientsData={patients} isLoading={isLoading} />
    </div>
  );
}

export default Monitoring;
