import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, deleteDoc, doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../../firebase';
import useUID from '../../General/useUID';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNotesMedical, faSyringe, faFlask, faPills, faHeartbeat, faTrash} from '@fortawesome/free-solid-svg-icons';
import styles from "../PatientView.module.css";

// Add a dictionary to map the medical history type to an icon
const typeToIcon = {
  'FamilyHistory': faNotesMedical,
  'ImmunizationsAllergies': faSyringe,
  'LabAndTest': faFlask,
  'Medications': faPills,
  'vitals': faHeartbeat,
};

function PatientMedicalHistoryView({ patientId }) {
  const [medicalHistoryRecords, setMedicalHistoryRecords] = useState([]);
  const [expandedRecordId, setExpandedRecordId] = useState(null); // New state to track expanded card
  const [uid] = useUID();

  useEffect(() => {
    if (!uid) return;
    const q = query(collection(db, "patients", uid, "patientData", patientId, "medicalHistory"));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const records = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate().toLocaleString() // Convert timestamp
      }));
      setMedicalHistoryRecords(records);
    }, (error) => {
      console.error("Error fetching medical history: ", error);
    });

    // Cleanup the listener when the component unmounts
    return () => unsubscribe();
  }, [patientId, uid]);
  

  // Helper function to convert Firestore field names
const formatFieldName = (fieldName) => {
  const formattedName = {
    'FamilyHistory': 'Family History',
    'ImmunizationsAllergies': 'Immunizations & Allergies',
    'LabAndTest': 'Lab and Test Results',
    'Medications': 'Medications',
    'vitals': 'Vitals',
  };

  return formattedName[fieldName] || fieldName;
};


// Add the delete handler
  const handleDelete = async (recordId) => {
    try {
      // Confirm before deleting
        await deleteDoc(doc(db, "patients", uid, "patientData", patientId, "medicalHistory", recordId));
        setMedicalHistoryRecords(prevRecords => prevRecords.filter(record => record.id !== recordId));
        console.log('Record deleted:', recordId);
    } catch (error) {
      console.error('Error deleting medical history record: ', error);
    }
  };


  const toggleExpand = (recordId) => {
    setExpandedRecordId(expandedRecordId === recordId ? null : recordId);
  };

  const familyMembers = [
    'Father',
    'Mother',
    'Siblings',
    'Children',
    'Grandparents',
    'UnclesAunts',
    'Cousins',
    'Other'
  ];

    // Card component
const MedicalHistoryCard = ({ record }) => (
  <div className={`Card ${expandedRecordId === record.id ? 'expanded' : ''}`} onClick={() => toggleExpand(record.id)}>
    <div className={styles.medicalHistoryHeader}>
      <FontAwesomeIcon icon={typeToIcon[record.type] || faNotesMedical} size="lg" />
      <p>{formatFieldName(record.type)}</p>
      <p>{record.timestamp}</p>
      <button
        className="delete"
        style={{ color: 'white' }}
        onClick={(e) => {
          e.stopPropagation();
          handleDelete(record.id);
        }}
      >
        <FontAwesomeIcon icon={faTrash} />
      </button>
    </div>
    {expandedRecordId === record.id && (
      <div className={styles.medicalHistoryDetails}>
        {record.type === 'vitals' && (
        <div className={styles.medicalHistoryDetails}>
          {record.bmi && <p><strong>BMI:</strong> {record.bmi}</p>}
          {record.bloodPressure && <p><strong>Blood Pressure:</strong> {record.bloodPressure}</p>}
          {record.heartRate && <p><strong>Heart Rate:</strong> {record.heartRate}</p>}
          {record.oxygenSaturation && <p><strong>Oxygen Saturation:</strong> {record.oxygenSaturation}</p>}
          {record.respiratoryRate && <p><strong>Respiratory Rate:</strong> {record.respiratoryRate}</p>}
          {record.temperature && <p><strong>Temperature:</strong> {record.temperature}</p>}
          {record.weight && <p><strong>Weight:</strong> {record.weight}</p>}
        </div>
      )}
        {record.type === 'LabAndTest' && (
          <>
             {record.testName && <p><strong>Test Name:</strong> {record.testName}</p>}
              {record.testDate && <p><strong>Test Date:</strong> {record.testDate}</p>}
              {record.testResults && <p><strong>Test Results:</strong> {record.testResults}</p>}
              {record.notes && <p><strong>Notes:</strong> {record.notes}</p>}
          </>
        )}
        {record.type === 'Medications' && (
          <>
          {record.dosage && <p><strong>Dosage:</strong> {record.dosage}</p>}
          {record.frequency && <p><strong>Frequency:</strong> {record.frequency}</p>}
          {record.medicationName && <p><strong>Medication Name:</strong> {record.medicationName}</p>}
          {record.startDate && <p><strong>Start Date:</strong> {record.startDate}</p>}
          {record.endDate && <p><strong>End Date:</strong> {record.endDate}</p>}
          {record.notes && <p><strong>Notes:</strong> {record.notes}</p>}
          </>
        )}
      {record.type === 'ImmunizationsAllergies' && (
                <>
                   {record.immunizations && <p><strong>Immunizations:</strong> {record.immunizations}</p>}
                    {record.allergies && <p><strong>Allergies:</strong> {record.allergies}</p>}
                    {record.notes && <p><strong>Notes:</strong> {record.notes}</p>}
                </>
              )}
        
        {record.type === 'FamilyHistory' && (
          familyMembers.map(member => 
            record[member.toLowerCase()] && (
              <p key={member}>
                <strong>{member}:</strong> {record[member.toLowerCase()].join(', ')}
              </p>
            )
          )
        )}
        
     </div>
    )}
  </div>
);
  
    return (
      <div>
        {medicalHistoryRecords.length > 0 ? (
          medicalHistoryRecords.map(record => (
            <MedicalHistoryCard key={record.id} record={record} />
          ))
        ) : (
          <p>No medical history records found.</p>
        )}
      </div>
    );
  }
  
  export default PatientMedicalHistoryView;