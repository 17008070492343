import React, { useState, useEffect } from 'react';
import { storage, auth, functions } from '../../firebase';
import { ref, list, listAll, getDownloadURL, deleteObject, getMetadata, uploadBytes } from 'firebase/storage';
import './FileBrowser.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faMinusCircle } from '@fortawesome/free-solid-svg-icons';


const FileBrowser = () => {
  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [newFolderName, setNewFolderName] = useState('');
  const [editedFolderName, setEditedFolderName] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileIndex, setSelectedFileIndex] = useState(-1);
  const [creatingFolder, setCreatingFolder] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [moveDestinationFolder, setMoveDestinationFolder] = useState(null);
  const [showMoveDropdown, setShowMoveDropdown] = useState(false);


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (
      selectedFileIndex >= 0 &&
      moveDestinationFolder &&
      files[selectedFileIndex]
    ) {
      moveFile(files[selectedFileIndex], moveDestinationFolder.fullPath);
      setSelectedFileIndex(-1);
      setMoveDestinationFolder(null);
    }
  }, [moveDestinationFolder, files, selectedFileIndex]);
  

  useEffect(() => {
    if (!currentUser) {
      return;
    }
  
    const userId = currentUser.uid;
    const outputBucketRef = ref(storage, `BatchEligibility/${userId}`);
  
    const fetchFolders = async () => {
      const folderResult = await list(outputBucketRef);
      setFolders(folderResult.prefixes);
      setSelectedFolder('Output');
    };
  
    const fetchFiles = async () => {
      const outputFolderRef = ref(storage, `BatchEligibility/${userId}/Output`);
      const outputFolderFiles = await listAll(outputFolderRef);
      fetchFilesMetadata(outputFolderFiles.items.map(item => ({ ...item, fullPath: item.fullPath })));
    };
    
  
    fetchFolders();
    fetchFiles().catch((error) => console.error(error));
  }, [currentUser]);
  
  
  

  const selectFolder = async (folder) => {
    if (folder.name !== selectedFolder) {
      setSelectedFolder(folder.name);
  
      const folderPath = folder.fullPath;
      const folderRef = ref(storage, folderPath);
      const folderFiles = await listAll(folderRef);
      fetchFilesMetadata(folderFiles.items);
  
      setSelectedFileIndex(-1); // Deselect the file when switching to a new folder
    }
  };
  
  
    //new folder
    const createFolder = () => {
      setCreatingFolder(true);
    };
    
    const saveNewFolder = async () => {
      if (!currentUser || !newFolderName) {
        return;
      }
    
      const userId = currentUser.uid;
      const folderRef = ref(storage, `BatchEligibility/${userId}/${newFolderName}`);
    
      const emptyFileRef = ref(folderRef, '.keep');
      await uploadBytes(emptyFileRef, new Uint8Array(0));
    
      const newFolder = {
        fullPath: folderRef.fullPath,
        name: newFolderName
      };
      setFolders([...folders, newFolder]);
    
      setNewFolderName('');
      setCreatingFolder(false);
    };    
    
  //Edit button delete function
  const deleteFolder = async (folder) => {
    // Check if the folder being deleted is a special folder
    if (folder.name === 'Output' || folder.name === 'Upload') {
      return;
    }
  
    // Delete all files inside the folder
    const folderRef = ref(storage, folder.fullPath);
    const folderFiles = await listAll(folderRef);
    await Promise.all(folderFiles.items.map((file) => deleteObject(file)));
  
    // Remove the folder from the folders state
    setFolders((prevFolders) => prevFolders.filter((f) => f.fullPath !== folder.fullPath));
  };
  
     

  const moveFile = async (sourcePath, destinationPath) => {
    const moveStorageFile = functions.httpsCallable('triggerStorage');
    try {
      await moveStorageFile({ sourcePath, destinationPath });
    } catch (error) {
      console.error('Error moving file:', error);
    }
  };

  const handleMoveFile = async (event) => {
    const selectedFolder = event.target.value;

    if (selectedFolder) {
      try {
        const destinationFolder = folders.find((folder) => folder.fullPath === selectedFolder);
        const sourcePath = files[selectedFileIndex].fullPath;
        await moveFile(sourcePath, `${destinationFolder.fullPath}/${files[selectedFileIndex].name}`);

        // Remove the moved file from the files state
        setFiles((prevFiles) => prevFiles.filter((file, index) => index !== selectedFileIndex));

        // Hide the move dropdown and deselect the file
        setShowMoveDropdown(false);
        setSelectedFileIndex(-1);
      } catch (error) {
        console.error("Error moving the file:", error);
      }
    }
  };
  
// Helper function to download a file from Firebase Storage as a blob
async function getStorageFileAsBlob(filePath) {
  try {
    const fileRef = firebase.storage().ref(filePath);

    const blob = await fileRef.getBlob();
    return blob;
  } catch (error) {
    console.error('Error getting storage file as a blob:', error);
    return undefined;
  }
};
  
  
  // Helper function to upload a file to a specific path
  const uploadFileToPath = async (path, fileBlob) => {
    const app = getApps()[0] || initializeApp(/* Your Firebase configuration object here */);
    const storage = getStorage(app);
    const fileRef = ref(storage, path);
    await put(fileRef, fileBlob);
  };
  
  // Helper function to delete a file at a specific path
  const deleteFileAtPath = async (path) => {
    const fileRef = storage.ref().child(path);
    await fileRef.delete();
  };
  
  
//Download button 
const downloadFile = async (file) => {
    const fileRef = ref(storage, file.fullPath);
    const downloadURL = await getDownloadURL(fileRef);
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Close the option box after downloading the file
  };

  
  //delete a file from the optionbox
  const deleteFile = async (file) => {
    const fileRef = ref(storage, file.fullPath);
    await deleteObject(fileRef);
  
    // Remove the deleted file from the files state
    setFiles((prevFiles) => prevFiles.filter((f) => f.fullPath !== file.fullPath));
  
    // Close the option box after deleting the file
  };
  
  //File View Name Code
  const extractBatchName = (fileName) => {
    if (!fileName) {
      return 'Unknown';
    }
    
    const index = fileName.indexOf('_');
    if (index === -1) return fileName;
    return fileName.slice(0, index);
  };

  //extract number of requests
  const extractRequests = (fileName) => {
    if (!fileName) {
      return 'Unknown';
    }
  
    const match = fileName.match(/_Requests(\d+)\.xlsx$/);
    if (match) {
      return match[1];
    }
    return 'Unknown';
  };
  
  
  
  // File View
  const fetchFilesMetadata = async (files) => {
    const updatedFiles = await Promise.all(
      files.map(async (file) => {
        const fileRef = ref(storage, file.fullPath);
        const metadata = await getMetadata(fileRef);
  
        // Extract file name and folder name from the fullPath property
        const fileName = file.fullPath.split('/').pop();
        const folderName = extractFolderName(file.fullPath);
  
        return { ...file, metadata, name: fileName, folderName: folderName, fullPath: file.fullPath };
      })
    );
    setFiles(updatedFiles);
  };
  

  const extractFolderName = (fullPath) => {
    if (!fullPath) {
      return 'Unknown';
    }
    
    const pathParts = fullPath.split('/');
    if (pathParts.length < 2) return 'Unknown';
    return pathParts[pathParts.length - 2];
  };

  const toggleMoveDropdown = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setShowMoveDropdown(!showMoveDropdown);
  };
  
    return (
      <>
        <div className="folder-file-container">
          <div className="folder-browser-container">
          <ul className="folder-list">
            {folders.map((folder, index) => (
              <li key={index} className={`${selectedFolder === folder.name ? 'selected' : ''} ${folder.name === 'Upload' || folder.name === 'Output' ? (isEditMode ? 'folder-transparency' : '') : ''}`} onClick={() => selectFolder(folder)}>
                {folder.name === 'Upload' || folder.name === 'Output' ? (
                  <FontAwesomeIcon icon={faFolder} className="folder-icon" />
                ) : (
                  isEditMode && (
                    <FontAwesomeIcon
                      icon={faMinusCircle}
                      className="folder-delete-icon"
                      onClick={(event) => {
                        event.stopPropagation();
                        deleteFolder(folder);
                      }}
                    />
                  )
                  || <FontAwesomeIcon icon={faFolder} className="folder-icon" />
                )}
                <span className="folder-name">{folder.name}</span>
              </li>
            ))}
            {creatingFolder && (
              <li>
                <FontAwesomeIcon icon={faFolder} className="folder-icon" />
                <input
                  className="foldernameinput"
                  type="text"
                  value={newFolderName}
                  onChange={(e) => setNewFolderName(e.target.value)}
                  onBlur={saveNewFolder}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      saveNewFolder();
                    }
                  }}
                  autoFocus
                />
              </li>
            )}
          </ul>
          <button className="edit-button" onClick={() => setIsEditMode(!isEditMode)}>
              Edit
            </button>
            <button className="icon-button" onClick={createFolder}>
                  <FontAwesomeIcon icon={faFolder} size="lg" />
                </button>
          </div>
          <div className="file-browser-container">
          <ul className="file-list">
          {files.map((file, index) => {
                  const dateUploaded =
                    file.metadata && file.metadata.timeCreated
                      ? new Date(file.metadata.timeCreated)
                      : null;
                  const formattedDate = dateUploaded
                    ? dateUploaded.toLocaleString()
                    : "Unknown date";
                  const batchName = extractBatchName(file.name);
                  const folderName = file.folderName;
                  const requests = extractRequests(file.name);

                  return (
                    <li
                      key={index}
                      className={`file-list-item ${
                        selectedFileIndex === index ? "selected-file" : ""
                      }`}
                      onClick={() => setSelectedFileIndex(index)}
                    >
                      <div className="file-info">
                        <div className="file-name">
                          {batchName}
                          {requests !== "Unknown" && ` (${requests} Requests)`}
                        </div>
                        <div className="file-date">{formattedDate}</div>
                        <div className="file-folder">
                          <FontAwesomeIcon
                            icon={faFolder}
                            className="file-folder-icon"
                            size="sm"
                          />{" "}
                          {folderName}
                        </div>
                      </div>
                      {selectedFileIndex === index && (
                        <div className="file-actions">
                        {/* <button
                            className="file-action-button"
                            onClick={toggleMoveDropdown}
                          >
                            Move
                          </button> */}
                          {showMoveDropdown && (
                            <div className="move-file-container">
                              <label htmlFor="move-file-select">Move to:</label>
                              <select
                                id="move-file-select"
                                value={moveDestinationFolder ? moveDestinationFolder.fullPath : ''}
                                onChange={handleMoveFile}
                              >
                                <option value="">Select a folder</option>
                                {folders.map((folder, index) => (
                                  <option key={index} value={folder.fullPath}>
                                    {folder.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                          <button
                            className="file-action-button"
                            onClick={(event) => {
                              event.stopPropagation();
                              event.preventDefault();
                              downloadFile(file);
                            }}
                          >
                            Download
                          </button>
                          <button
                            className="file-action-button"
                            onClick={(event) => {
                              event.stopPropagation();
                              event.preventDefault();
                              deleteFile(file);
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      )}

                    </li>
                  );
                })}
            </ul>
        </div>
        </div>
        
      </>
    );
  };

export default FileBrowser;