import React, { useState } from 'react';
import GeneralClaimVolume from "./Stats/GeneralClaimVolume";
import "./Claims.css";
import ClaimHistogram from './Stats/ClaimHistogram';
import TopPayersFrequencyTable from './Stats/TopPayersFrequencyTable';
import BillingCodesFrequencyTable from './Stats/BillingCodesFrequencyTable';
import DiagnosisCodesFrequencyTable from './Stats/DiagnosisCodesFrequencyTable';
import Summary from './Stats/Summary';
import StatusSummary from './Stats/statusSummary';
import ClaimsTable from './ClaimsTable';

function Dashboard() {

  return (
    <>
      <div id="dashboardContent">

        <div className='frequencyTables'>
          <Summary />
          <StatusSummary />
        </div>

        <div className='Charts'>
          <GeneralClaimVolume />
          <ClaimHistogram />
        </div>
        <div className='frequencyTables'>
          <TopPayersFrequencyTable />
          <BillingCodesFrequencyTable />
          <DiagnosisCodesFrequencyTable />
        </div>
      </div>
    </>
  );
}

export default Dashboard;
