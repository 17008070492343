import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import { doc, collection, addDoc, updateDoc } from "firebase/firestore";
import ProviderSelection from "../../Providers/ProviderSelection";
import { getFunctions, httpsCallable } from "firebase/functions";
import useUID from "../../General/useUID";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HexSpinner from "../../General/Animations/Hexspinner";
import FormSelection from "../FormEditor/FormSelection/FormSelection";
import ValidateSubform from "../../PatientFiles/FormEditor/FormSelection/ValidateSubform";

import {
  faClinicMedical,
  faHospital,
  faAmbulance,
  faLaptopMedical,
  faHome,
  faUserMd,
  faEye,
  faFileMedical,
  faHandshake,
  faShieldAlt,
  faHandHoldingMedical,
  faBuilding,
  faHeartbeat,
  faBed,
  faSmile,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import ICD10 from "./ICD10";

const functions = getFunctions();

const getInputFormattedDate = (onClose) => {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
  const year = today.getFullYear();
  return `${year}-${month}-${day}`;
};

function BlankEncounter({
  patientId,
  onClose,
  duration,
  encounterData = null,
  providers = { providers },
}) {
  const [dateOfService, setDateOfService] = useState(
    encounterData ? encounterData.dateOfService : getInputFormattedDate()
  );
  const [diagnosisCode, setDiagnosisCode] = useState("");
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [reasonForVisit, setReasonForVisit] = useState("");
  const [service, setService] = useState("");
  const [encounterType, setEncounterType] = useState("");
  const [notes, setNotes] = useState("");
  const [providerId, setProviderId] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [providerfirstName, setProviderfirstName] = useState("");
  const [providerlastName, setProviderlastName] = useState("");
  const [durationValue, setDurationValue] = useState({ hours: 0, minutes: 0 });
  const [formInputValues, setFormInputValues] = useState([]);
  const [selectedFormId, setSelectedFormId] = useState(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [formValid, setFormValid] = useState(true);
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [uid, subUserUID] = useUID();

  const faIcons = {
    "Outpatient Visit": faClinicMedical,
    "Inpatient Admission": faHospital,
    "Emergency Room Visit": faAmbulance,
    "Telemedicine Visit": faLaptopMedical,
    "Home Health Visit": faHome,
    "Ambulatory Care": faUserMd,
    "Day Surgery": faClinicMedical,
    Observation: faEye,
    "Ancillary Services": faFileMedical,
    "Follow-up Visit": faHandshake,
    "Preventative Visit": faShieldAlt,
    "Rehabilitation Visit": faHandHoldingMedical,
    "Referral Visit": faBuilding,
    "Urgent Care Visit": faAmbulance,
    "Post-operative Visit": faHeartbeat,
    "Nursing Home Visit": faBed,
    "Routine Check-up": faSmile,
    "Maternity & Antenatal Visits": faUserTie,
  };

  useEffect(() => {
    const isValid = validateForm();
    if (formValid !== isValid) {
      setFormValid(isValid);
    }
  }, [
    dateOfService,
    providerId,
    diagnosisCode,
    service,
    reasonForVisit,
    encounterType,
    formValid,
  ]);

  useEffect(() => {
    if (encounterData) {
      setDateOfService(encounterData.dateOfService);
      setDiagnosisCode(encounterData.diagnosisCode);
      setReasonForVisit(encounterData.reasonForVisit);
      setService(encounterData.service);
      setEncounterType(encounterData.encounterType);
      setNotes(encounterData.notes);
      setProviderId(encounterData.providerId);
      setOrganizationName(
        encounterData.selectedProvider?.organizationName || ""
      );
      setProviderfirstName(encounterData.selectedProvider?.firstName || "");
      setProviderlastName(encounterData.selectedProvider?.lastName || "");
      setSelectedFormId(encounterData.selectedFormId);
    }

    if (duration) {
      // If the duration prop is provided, assume it's in milliseconds
      const hours = Math.floor(duration / (1000 * 60 * 60));
      const minutes = Math.floor((duration / (1000 * 60)) % 60);
      const seconds = Math.floor((duration / 1000) % 60);
      const formattedDuration = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
      setDurationValue(formattedDuration);
    } else if (encounterData && encounterData.duration) {
      // If encounterData.duration is provided, assume it's in "HH:MM:SS" format
      setDurationValue(encounterData.duration);
    } else {
      setDurationValue("00:00:00");
    }
  }, [encounterData, duration]);

  useEffect(() => {
    if (encounterData) {
      const selectedProvider = providers.find(
        (provider) => provider.id === encounterData.providerId
      );
      setSelectedProviders(selectedProvider);
    }
  }, [encounterData, providers]);

  const convertDateToMMDDYYYY = (inputDate) => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    const durationRegex = /^\d+:\d+:\d+$/; // Check for duration format (HH:MM:SS)

    if (durationRegex.test(inputDate)) {
      // If the input is a duration string, return it as is
      return inputDate;
    }

    if (!dateRegex.test(inputDate)) {
      return inputDate;
    }

    const [year, month, day] = inputDate.split("-");
    return `${month}/${day}/${year}`;
  };

  const convertDateToYYYYMMDD = (inputDate) => {
    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/; // Check if date is in MM/DD/YYYY format
    if (!dateRegex.test(inputDate)) {
      return inputDate;
    }
    const [month, day, year] = inputDate.split("/");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  };

  const resetForm = () => {
    setDateOfService(getInputFormattedDate());
    setDiagnosisCode("");
    setReasonForVisit("");
    setService("");
    setEncounterType("");
    setNotes("");
    setProviderId("");
    setOrganizationName("");
    setProviderfirstName("");
    setProviderlastName("");
    setDurationValue("00:00:00");
    setFormInputValues([]);
    setHasSubmitted(false);
    setFormValid(true);
    setFormErrors({});
  };

  const getRequiredFieldsMessage = () => {
    const requiredFields = [
      { field: "Date of Service", value: dateOfService },
      { field: "Provider", value: providerId },
      { field: "Diagnosis Code", value: diagnosisCode },
      { field: "Service", value: service },
      { field: "Reason for Visit", value: reasonForVisit },
      {
        field: "Encounter Type",
        value: encounterType === "" || encounterType === "Select an option",
      },
      { field: "Duration", value: !validateDurationValue() },
    ];

    const missingFields = requiredFields
      .filter((field) => !field.value)
      .map((field) => field.field);
    return missingFields.length === 0
      ? ""
      : `Please fill in the following required fields: ${missingFields.join(
          ", "
        )}.`;
  };

  const validateForm = () => {
    const errors = {};
    const durationRegex = /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/;
    const durationRequired = !!duration;
    const isValidDuration =
      durationRegex.test(durationValue) || !durationRequired;

    const requiredFields = {
      dateOfService: "Date of Service",
      providerId: "Provider",
      diagnosisCode: "Diagnosis Code",
      service: "Service",
      reasonForVisit: "Reason for Visit",
      encounterType: "Encounter Type",
    };

    Object.entries(requiredFields).forEach(([field, label]) => {
      if (
        !eval(field) ||
        (typeof eval(field) === "string" && eval(field).trim() === "")
      ) {
        errors[field] = `${label} is required`;
      }
    });

    if (!isValidDuration) {
      errors.duration = "Duration is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const validateDurationValue = () => {
    const durationRegex = /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/;
    const durationRequired = !!duration;
    return durationRegex.test(durationValue) || !durationRequired;
  };

  const handleFormInputChange = (formInputValues) => {
    setFormInputValues(formInputValues);
  };

  const handleDiagnosisCodeSelect = (selectedCode) => {
    setDiagnosisCode(selectedCode);
    setFormValid(validateForm());
  };

  const handleProviderSelection = (providerId) => {
    const selectedProvider = providers.find(
      (provider) => provider.id === providerId
    );
    setProviderId(providerId);
    setOrganizationName(selectedProvider.organizationName);
    setProviderfirstName(selectedProvider.firstName);
    setProviderlastName(selectedProvider.lastName);
    setFormValid(validateForm());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setHasSubmitted(true);
    setIsLoading(true);
  
    const isValid = validateForm();
  
    if (!isValid) {
      setIsLoading(false);
      return;
    }
  
    if (selectedFormId) {
      let subFormValidation = ValidateSubform(
        formInputValues.formData,
        formInputValues.formInputValues
      );
  
      if (!subFormValidation.isValid) {
        setIsLoading(false);
        setErrorMessage(subFormValidation.message);
        return;
      }
    }
  
    const formattedDateOfService = convertDateToMMDDYYYY(dateOfService);
    const encounterDataToSave = {
      dateOfService: formattedDateOfService,
      diagnosisCode,
      reasonForVisit,
      service,
      encounterType,
      notes,
      providerId,
      organizationName,
      selectedProviders,
      providerfirstName,
      providerlastName,
      duration: durationValue,
      selectedFormId,
    };
  
    try {
      const encountersRef = collection(
        doc(db, "patients", uid, "patientData", patientId),
        "encounters"
      );
  
      if (encounterData && encounterData.id) {
        // Update the existing encounter
        await updateDoc(
          doc(encountersRef, encounterData.id),
          encounterDataToSave
        );
        console.log("Encounter updated successfully");
      } else {
        // Create a new encounter
        const newEncounterRef = await addDoc(
          encountersRef,
          encounterDataToSave
        );
        console.log("New encounter created with ID:", newEncounterRef.id);
  
        // Save formInputValues under user's collection
        const formInputValuesRef = collection(
          doc(db, "users", uid),
          "formInputValues"
        );
  
        await addDoc(formInputValuesRef, {
          encounterId: newEncounterRef.id,
          formInputValues: formInputValues,
        });
  
        console.log("formInputValues saved successfully");
      }
  
      setErrorMessage("");
      setIsLoading(false);
      resetForm();
      onClose(); // Close the form after successful submission
    } catch (error) {
      setIsLoading(false);
      console.error("Error saving encounter:", error);
    }
  };
  

  return (
    <>
      <div className="actionContainer">
        <div className="popupContainer">
          <div className="popupContent">
            <button
              className="filesCloseButton"
              onClick={() => {
                resetForm();
                onClose();
              }}
            >
              X
            </button>

            <div className="centerHeader">
              <h3>New Blank Encounter</h3>
            </div>

            <div className="m-5 p-5">
              <lable className="pt-5">
                Select a Provider:
                {formErrors.providerId && (
                  <span className="RequiredMark"> *</span>
                )}
              </lable>
              <ProviderSelection
                onProviderSelect={handleProviderSelection}
                selectedProviderIdexternal={encounterData?.providerId}
              />
              <div className="input-group-row pt-5">
                <div className="input-field">
                  <label>
                    Diagnosis Code:
                    {formErrors.diagnosisCode && (
                      <span className="RequiredMark"> *</span>
                    )}
                  </label>
                  <ICD10
                    onCodeSelect={handleDiagnosisCodeSelect}
                    initialSelectedCodes={
                      Array.isArray(diagnosisCode) ? diagnosisCode : ""
                    }
                  />
                </div>
              </div>

              <div className="input-group-row">
                <div className="input-field">
                  <label>
                    Date of Service:
                    {formErrors.dateOfService && (
                      <span className="RequiredMark"> *</span>
                    )}
                  </label>
                  <input
                    type="date"
                    value={convertDateToYYYYMMDD(dateOfService)}
                    onChange={(e) => setDateOfService(e.target.value)}
                  />
                </div>

                <div className="input-group-row">
                  <div className="input-field">
                    <label>
                      Duration:
                      {formErrors.durationValue && (
                        <span className="RequiredMark"> *</span>
                      )}
                    </label>
                    <input
                      type="text"
                      pattern="^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$"
                      placeholder="HH:MM:SS"
                      value={durationValue}
                      onChange={(e) => setDurationValue(e.target.value)}
                    />
                  </div>
                </div>

                <div className="input-group-row">
                  <div className="input-field">
                    <label>
                      Encounter Type:
                      {formErrors.encounterType && (
                        <span className="RequiredMark"> *</span>
                      )}
                    </label>
                    <select
                      className="w-100"
                      value={encounterType}
                      onChange={(e) => setEncounterType(e.target.value)}
                    >
                      <option value="">Select an option</option>
                      {Object.entries(faIcons).map(([encounterType, icon]) => (
                        <option key={encounterType} value={encounterType}>
                          <FontAwesomeIcon icon={icon} /> {encounterType}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="input-group-row">
                <div className="input-field w-400px">
                  <label>
                    Service Provided:
                    {formErrors.service && (
                      <span className="RequiredMark"> *</span>
                    )}
                  </label>
                  <input
                    type="text"
                    className="w-100"
                    value={service}
                    onChange={(e) => setService(e.target.value)}
                  />
                </div>
              </div>

              <div className="input-group-row">
                <div className="input-field w-400px">
                  <label>
                    Reason for Visit:
                    {formErrors.reasonForVisit && (
                      <span className="RequiredMark"> *</span>
                    )}
                  </label>
                  <textarea
                    type="text"
                    className="w-100"
                    value={reasonForVisit}
                    onChange={(e) => setReasonForVisit(e.target.value)}
                  />
                </div>
              </div>

              <div className="input-group-row">
                <div className="input-field w-400px">
                  <label>Addtional Notes (optional):</label>
                  <textarea
                    value={notes}
                    className="w-100"
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </div>
              </div>

              <lable className="pt-5">Select a Form Layout: (optional)</lable>
              <FormSelection
                onFormInputChange={handleFormInputChange}
                selectedFormId={selectedFormId}
                setSelectedFormId={setSelectedFormId}
              />

              <div className="my-5 display-grid">
                {!formValid && hasSubmitted && (
                  <p style={{ color: "red" }}>{getRequiredFieldsMessage()}</p>
                )}

                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}

                {isLoading ? (
                  <HexSpinner />
                ) : (
                  <button
                    type="submit"
                    className="mx-auto"
                    disabled={isLoading}
                    onClick={handleSubmit}
                  >
                    Save Encounter
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlankEncounter;
