import React, { useState, useEffect } from "react";
import HexagonSpinner from '../General/Animations/Hexspinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { db } from "../../firebase";
import ProviderForm from './ProviderForm';
import { useProviderContext } from "../../context/ProviderContext";
import { collection, getDocs, deleteDoc, doc, updateDoc  } from "firebase/firestore";
import POSCodeIcon from './POSCodeIcon';
import useUID from '../General/useUID'
import Modal from 'react-modal';
import { Button } from 'react-bootstrap';

function ProviderSelection(props) {
    const { onProviderSelect } = props;

    // Try to use providers from context, if available
    const context = useProviderContext();
    const isStandalone = !context || !context.providers;

    // Use local states
    const [localProviders, setLocalProviders] = useState([]);
    const providers = isStandalone ? localProviders : context.providers;
    const setProviders = isStandalone ? setLocalProviders : context.updateProviders;
    const [selectedProvider, setSelectedProvider] = useState([]);
    const [selectedProviderId, setSelectedProviderId] = useState(null);
    const [showProviderForm, setShowProviderForm] = useState(false);
    const [editingProvider, setEditingProvider] = useState(null);
    const [gettingProviders, setGettingProviders] = useState(false);
    const [uid] = useUID();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [providerToDelete, setProviderToDelete] = useState(null);
    
    const { providers: contextProviders, updateProviders: contextUpdateProviders } = useProviderContext() || {};

    useEffect(() => {
        if (!uid || !isStandalone) return; // Fetch only if standalone
        fetchProviders();
    }, [uid, isStandalone]);

    // Initialize Firestore database reference
    const providersRef = collection(db, `users/${uid}/providers`);

    useEffect(() => {
        if (!uid) return;
        fetchProviders();
    }, [uid]);

    const fetchProviders = async () => {
        setGettingProviders(true);
        const querySnapshot = await getDocs(providersRef);
        setGettingProviders(false);
        
        if (querySnapshot.empty) {
            setProviders([]);
            return;
        }
    
        let providersData = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            // Push the full data object, including the 'deleted' field
            providersData.push({
                id: doc.id,
                ...data
            });
        });
    
        // Filter out deleted providers after constructing the full array
        providersData = providersData.filter(provider => !provider.deleted);
    
        setProviders(providersData);
    };
    

    // Function to initiate editing of a provider
    const handleEdit = (e, provider) => {
        e.stopPropagation();
        setEditingProvider(provider);
        setShowProviderForm(true);
    };

    const handleAddNewProvider = () => {
        setEditingProvider(null); // No provider means it's a new provider
        setShowProviderForm(true);
    };

    /// Handle selection of existing provider
    const handleSelect = (providerId) => {

        if (!selectedProviderId || selectedProviderId != providerId) {
            setSelectedProviderId(providerId);
            // Find the selected provider from the providers array
            const provider = providers.find((provider) => provider.id === providerId);
            // Store the selected provider's information in an array
            setSelectedProvider([
                providerId,
                provider.organizationName,
                `${provider.firstName} ${provider.lastName}`,
                provider.npi,
                provider.ein,
                `${provider.address1 ? provider.address1 + ", " : ""}${provider.address2 ? provider.address2 + ", " : ""}${provider.city ? provider.city + ", " : ""}${provider.state ? provider.state + " " : ""}${provider.zip ? provider.zip : ""}`,
                provider.posCode, provider.medicareNumber, provider.medicaidNumber, provider.cliaNumber
            ]);

            // Call the parent component's function with the selected provider's name, NPI, and POS code
            onProviderSelect(providerId, provider.organizationName, provider.firstName, provider.lastName, provider.npi, provider.ein, provider.address1, provider.city, provider.state, provider.zip, provider.posCode, provider.cliaNumber, provider.medicareNumber);

            return;
        }

        // Deselect
        setSelectedProviderId(null);
        setSelectedProvider([]);

    };

    const handleDelete = async (e, provider) => {
        e.stopPropagation();
        setProviderToDelete(provider);
        setShowConfirmModal(true);
    };
    
    const confirmDelete = async () => {
        if (!providerToDelete || !providerToDelete.id) {
            console.error("No provider selected for deletion");
            setShowConfirmModal(false);
            return;
        }
    
        // Update the provider document to set deleted: true
        await updateDoc(doc(db, "users", uid, "providers", providerToDelete.id), {
            deleted: true
        });
    
        // Refresh the providers list
        fetchProviders();
    
        setShowConfirmModal(false);
        setProviderToDelete(null);
    };
    
    
    const cancelDelete = () => {
        setShowConfirmModal(false);
        setProviderToDelete(null);
    };
    
    

    const handleProviderFormClose = (isNewProviderAdded) => {
        setShowProviderForm(false);
        if (isNewProviderAdded) {
            if (isStandalone) {
                fetchProviders(); // For standalone, re-fetch providers
            } else {
                contextUpdateProviders(); // For context, call the context's update function
            }
        }
    };

    return (
        <>
            {showProviderForm ? (<ProviderForm provider={editingProvider} onClose={(isNewProviderAdded) => handleProviderFormClose(isNewProviderAdded)} />)
                : (
                    <div className="grid-container w-100">

                        {gettingProviders ? (
                            <div className='grey-card h-250px w-100 content-center py-4'>
                                <HexagonSpinner />
                                <h4>Your providers are loading.</h4>
                            </div>
                        ) : (
                            providers.map((provider) => (
                                <div className={(provider.id === selectedProviderId ? "blue-card card-selected" : "white-card") + " card w-100 h-250px content-center py-4"}
                                    onClick={() => handleSelect(provider.id)} key={provider.id} >
                                    <h5>
                                        <POSCodeIcon posCode={provider.posCode} /> {/* Use the POSCodeIcon component */}
                                    </h5>
                                    <h5>{provider.organizationName}</h5>
                                    <h5>{provider.firstName} {provider.lastName}</h5>
                                    <h5>NPI: {provider.npi}</h5>
                                    <h5>Tax ID: {provider.ein}</h5>
                                    <h5>
                                        {provider.address1}{provider.address2 ? ` ${provider.address2}` : ''}{provider.city ? `, ${provider.city}` : ''}{provider.state ? `, ${provider.state}` : ''}{provider.zip ? ` ${provider.zip}` : ''}
                                    </h5>
                                    <div className="button-group">
                                        <button onClick={(e) => handleEdit(e, provider)} className={(provider.id === selectedProviderId ? "primary" : "secondary")}>
                                            <FontAwesomeIcon icon={faEdit} />
                                        </button>
                                        <button onClick={(e) => handleDelete(e, provider)} className={(provider.id === selectedProviderId ? "primary" : "secondary")}>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </div>
                                </div>
                            ))

                        )}

                        < div className='white-card w-100 h-250px content-center py-4' onClick={handleAddNewProvider}>
                            <FontAwesomeIcon icon={faPlus} style={{ height: '32px' }} />
                            <h4>Add a Provider</h4>
                        </div>

                    </div >
                )
            }
            <Modal
    isOpen={showConfirmModal}
    onRequestClose={cancelDelete}
    className='confirmModal'
    overlayClassName='confirmModalOverlay'
>
    <h2>Delete "{providerToDelete?.firstName} {providerToDelete?.lastName}"?</h2>
    <p>Are you sure you want to delete this provider?</p>
    <div className='confirmButtons'>
        <Button variant="secondary" onClick={cancelDelete}>
            Cancel
        </Button>
        <Button variant="danger" onClick={confirmDelete}>
            Delete
        </Button>
    </div>
</Modal>

        </>
    );

}

export default React.memo(ProviderSelection);