import { useState } from "react";

function CollapsibleSection({ title, number, children, activeSection, setActiveSection }) {
  const isOpen = activeSection === number;

  return (
    <div className="collapsibleSection">
      <div className="sectionHeader" >
        <span className="sectionNumber">{number}.</span>
        <span className="sectionTitle">{title}</span>
        <span className="indicator">{isOpen ? "-" : "+"}</span>
      </div>
      {isOpen && <div className="sectionContent">{children}</div>}
    </div>
  );
}

export default CollapsibleSection;
