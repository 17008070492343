import React, { useState, useEffect } from 'react';
import { format, addMinutes, startOfDay, eachDayOfInterval, endOfWeek, startOfWeek, addWeeks, subWeeks } from 'date-fns';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { auth, db, storage } from "../../../../../firebase";

const AppointmentSlots = ({ workingHours, appointmentTypes, clinicTimeZone, onNext, clinicId }) => {
  const [selectedAppointmentType, setSelectedAppointmentType] = useState(appointmentTypes[0]?.name || '');
  const [currentWeek, setCurrentWeek] = useState(new Date());
  const [weekSlots, setWeekSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);

  useEffect(() => {
    generateWeekSlots();
  }, [selectedAppointmentType, workingHours, clinicTimeZone, currentWeek]);

  const generateWeekSlots = () => {
    const selectedType = appointmentTypes.find(type => type.name === selectedAppointmentType);
    const duration = parseInt(selectedType?.duration || 30, 10);
    const startWeek = startOfWeek(currentWeek, { weekStartsOn: 0 });
    const endWeek = endOfWeek(currentWeek, { weekStartsOn: 0 });
    const eachDay = eachDayOfInterval({ start: startWeek, end: endWeek });

    const slotsByDay = eachDay.map(day => {
      const dayOfWeek = format(day, 'EEEE');
      const slots = generateDaySlots(day, duration, dayOfWeek);
      return {
        dayOfWeek,
        date: format(day, 'MMMM do'),
        slots
      };
    });

    setWeekSlots(slotsByDay);
  };

  const handleSaveAppointment = async () => {
    if (!selectedSlot) {
        console.log('No slot selected');
        return;
    }

    const appointmentData = {
        ...selectedSlot,
        timestamp: Timestamp.now(), 
        appointmentType: selectedAppointmentType,
    };

    try {
        // Targeting the specific clinic document and its appointments subcollection
        const clinicAppointmentsRef = collection(db, `clinics/${clinicId}/appointments`);
        await addDoc(clinicAppointmentsRef, appointmentData);

        onNext();
    } catch (error) {
        console.error("Error saving the appointment: ", error);
    }
};

  const generateDaySlots = (day, duration, dayOfWeek) => {
    let slots = [];
    if (workingHours[dayOfWeek]?.open) {
      let currentTime = new Date(day.setHours(...workingHours[dayOfWeek].start.split(':')));

      while (format(currentTime, 'HH:mm') < workingHours[dayOfWeek].end) {
        const slotTime = format(currentTime, 'HH:mm');
        slots.push(slotTime);

        currentTime = addMinutes(currentTime, duration);
      }
    }
    return slots;
  };

  const selectSlot = (slot, dayOfWeek, date) => {
    setSelectedSlot({ time: slot, dayOfWeek, date, timeZone: clinicTimeZone });
  };

  const handleNextWeek = () => {
    setCurrentWeek(addWeeks(currentWeek, 1));
    setSelectedSlot(null); // Clear selected slot when changing weeks
  };

  const handlePreviousWeek = () => {
    setCurrentWeek(subWeeks(currentWeek, 1));
    setSelectedSlot(null); // Clear selected slot when changing weeks
  };

  return (
    <div>
      <h2>Select an Appointment Type</h2>
      <select value={selectedAppointmentType} onChange={e => setSelectedAppointmentType(e.target.value)}>
        {appointmentTypes.map((type, index) => (
          <option key={index} value={type.name}>{type.name} - {type.duration} minutes</option>
        ))}
      </select>

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '20px 0' }}>
        <button onClick={handlePreviousWeek}>← Prev Week</button>
        <h3>Week of {format(startOfWeek(currentWeek, { weekStartsOn: 0 }), 'MMMM do')}</h3>
        <button onClick={handleNextWeek}>Next Week →</button>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
      {weekSlots.map(({ dayOfWeek, date, slots }, index) => (
        <div key={index} style={{ margin: '10px', textAlign: 'center' }}>
            <h4>{dayOfWeek}</h4>
            <p>{date}</p>
                {slots.map((slot, slotIndex) => {
                // Determine if this slot is selected
                const isSelectedSlot = selectedSlot?.time === slot && selectedSlot?.dayOfWeek === dayOfWeek;
                return (
                    <div key={slotIndex} style={{ margin: '5px' }}>
                    {/* Wrap both the slot button and continue button in a div when selected */}
                    {isSelectedSlot ? (
                        <div className='selectedSlot'>
                        <button style={{ display: 'block', margin: '5px auto' }} onClick={() => selectSlot(slot, dayOfWeek, date)}>
                            {slot} {clinicTimeZone}
                        </button>
                        <div style={{ textAlign: 'center', marginTop: '10px' }}>
                            <span className='continueButton' onClick={handleSaveAppointment} >Continue {">>"}</span>
                        </div>
                        </div>
                    ) : (
                        <button style={{ padding: '10px', margin: '5px', border: '1px solid #ccc' }} onClick={() => selectSlot(slot, dayOfWeek, date)}>
                        {slot} {clinicTimeZone}
                        </button>
                    )}
                    </div>
                  );
                })}
            </div>
            ))}
      </div>
    </div>
  );
};

export default AppointmentSlots;
