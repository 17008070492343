import React, { useState, useEffect } from 'react';
import { db } from "../../firebase";
import { collection, onSnapshot, doc } from 'firebase/firestore';
import useUID from '../General/useUID';
import styles from './PatientView.module.css';

function EligibilityCards({ patientId }) {
  const [eligibilityData, setEligibilityData] = useState([]);
  const [expandedId, setExpandedId] = useState(null);
  const [uid, subUserUID, isLoading, error] = useUID();

  useEffect(() => {
    if (!uid) return;
    if (!patientId) return;
  
    const eligibilityRef = collection(
      doc(db, 'patients', uid, 'patientData', patientId),
      'eligibility'
    );
  
    const unsubscribe = onSnapshot(eligibilityRef, (querySnapshot) => {
      let eligibilityDocs = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        formattedUpdatedAt: doc.data().updatedAt ? new Date(doc.data().updatedAt.seconds * 1000).toLocaleString() : 'Unknown',
      }));
  
      // Sort the documents by updatedAt in descending order
      eligibilityDocs = eligibilityDocs.sort((a, b) => {
        const dateA = a.updatedAt ? a.updatedAt.seconds : 0;
        const dateB = b.updatedAt ? b.updatedAt.seconds : 0;
        return dateB - dateA; // For descending order
      });
  
      setEligibilityData(eligibilityDocs);
    }, (error) => {
      console.error('Error fetching eligibility data:', error);
    });
  
    return () => unsubscribe();
  }, [uid, patientId]);
  

  const toggleExpand = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  const renderDataField = (label, data) => {
    return data ? <p>{label}: {data}</p> : null;
  };

  return (
    <div className='eligibilityContainer'>
      {eligibilityData.length > 0 ? (
        eligibilityData.map(data => (
          <div key={data.id} className={`patientViewCard ${styles.patientViewCard}`} onClick={() => toggleExpand(data.id)}>
            {renderDataField('Insurance', data.insuranceName)}
            {renderDataField('Status', data.insuranceStatus)}
            {renderDataField('Updated At', data.formattedUpdatedAt)}

            {expandedId === data.id && (
              <div className={styles.expandedSection}>
                {renderDataField('Member ID', data.submemberId)}
                {renderDataField('Group Number', data.groupNumber)}
                {renderDataField('Payer ID', data.payerID)}
                {renderDataField('Plan Number', data.planNumber)}
                {renderDataField('Provider Name', data.providerName)}
                {renderDataField('Coverage Level', data.coverageLevel)}
                {renderDataField('Insurance Type', data.insuranceType)}
                {renderDataField('Updated At', data.formattedUpdatedAt)}
                {renderDataField('Subscriber First Name', data.subfirstName)}
                {renderDataField('Subscriber Last Name', data.sublastName)}
              </div>
            )}
          </div>
        ))
      ) : (
        <p>No eligibility information available for this patient.</p>
      )}
    </div>
  );
}

export default EligibilityCards;