import React, { useState, useEffect } from 'react';
import { auth, db } from "../../../firebase";
import { collection, getDocs, doc, query, where, getDoc, onSnapshot, deleteDoc, updateDoc, setDoc } from 'firebase/firestore';
import './Encounters.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrash,
  faStethoscope,
  faHospital,
  faAmbulance,
  faUserMd,
  faHome,
  faClinicMedical,
  faEye,
  faFileMedical,
  faHandshake,
  faBed,
  faHeartbeat,
  faHandHoldingMedical,
  faHandHoldingHeart,
  faLaptopMedical,
  faBuilding,
  faUserNurse,
  faUserTie,
  faUserInjured,
  faMedkit,
  faShieldAlt,
  faSmile,
} from '@fortawesome/free-solid-svg-icons'; // Import the necessary FontAwesome icons
import useUID from '../../General/useUID'

// Mapping of encounter types to FontAwesome icons
const faIcons = {
  'Outpatient Visit': faClinicMedical,
  'Inpatient Admission': faHospital,
  'Emergency Room Visit': faAmbulance,
  'Telemedicine Visit': faLaptopMedical,
  'Home Health Visit': faHome,
  'Ambulatory Care': faUserMd,
  'Day Surgery': faClinicMedical,
  'Observation': faEye,
  'Ancillary Services': faFileMedical,
  'Follow-up Visit': faHandshake,
  'Preventative Visit': faShieldAlt,
  'Rehabilitation Visit': faHandHoldingMedical,
  'Referral Visit': faBuilding,
  'Urgent Care Visit': faAmbulance,
  'Post-operative Visit': faHeartbeat,
  'Nursing Home Visit': faBed,
  'Routine Check-up': faSmile,
  'Maternity & Antenatal Visits': faUserTie,
};

function EncountersView({ patientId }) {
  const [encounters, setEncounters] = useState([]);
  const [openEncounterId, setOpenEncounterId] = useState(null);
  const [uid, subUserUID, isLoading, error] = useUID();

  useEffect(() => {
    if (!uid) return;
    if (!patientId) {
      return; // Exit the useEffect if patientId isn't available
    }
    const encountersRef = collection(
      doc(
        db,
        'patients', uid,
        'patientData', patientId
      ),
      'encounters'
    );

    // Create a query for the encounters
    const encountersQuery = query(encountersRef);

    // Set up the real-time listener
    const unsubscribe = onSnapshot(encountersQuery, async (snapshot) => {
      const loadedEncounters = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      const providerDataPromises = loadedEncounters.map(async (encounter) => {
        if (!encounter.providerId) {  // Check if providerId exists
          return {
            organizationName: null,
            firstName: null,
            lastName: null
          };
        }
        const providerDocRef = doc(db, 'users', uid, 'providers', encounter.providerId);
        const providerSnapshot = await getDoc(providerDocRef);
        if (providerSnapshot.exists()) {
          const data = providerSnapshot.data();
          return {
            organizationName: data.organizationName,
            firstName: data.firstName,
            lastName: data.lastName
          };
        }
        return {
          organizationName: null,
          firstName: null,
          lastName: null
        };
      });


      const providersData = await Promise.all(providerDataPromises);

      const encountersWithProviders = loadedEncounters.map((encounter, index) => ({
        ...encounter,
        ...providersData[index]
      }));

      setEncounters(encountersWithProviders);
    });

    // Clean up the listener on component unmount
    return () => unsubscribe();
  }, [uid, patientId, db]);

  const handleToggle = (id) => {
    if (openEncounterId === id) {
      setOpenEncounterId(null);
    } else {
      setOpenEncounterId(id);
    }
  }

  const handleDelete = async (encounterId) => {
    try {
      const encounterDocRef = doc(
        db,
        'patients',
        uid,
        'patientData',
        patientId,
        'encounters',
        encounterId
      );

      await updateDoc(encounterDocRef, {
        isDeleted: true,
        BillingStatus: 'Deleted',
      });
      // Optionally: you could add a success message here or handle further UI updates
    } catch (error) {
      console.error("Error deleting encounter:", error);
      // Optionally: you could add an error message for the user
    }
  }

  const handleToggleBillingStatus = async (encounterId, currentStatus) => {
    try {
      const encounterDocRef = doc(
        db,
        'patients', uid,
        'patientData', patientId,
        'encounters', encounterId
      );

      const newStatus = currentStatus === 'NotBilled' ? 'Billed' : 'NotBilled';
      await updateDoc(encounterDocRef, {
        BillingStatus: newStatus
      });
      // Optionally: you could add a success message here or handle further UI updates
    } catch (error) {
      console.error("Error updating billing status:", error);
      // Optionally: you could add an error message for the user
    }
  }

  // Utility function to format duration in minutes and seconds
  const formatDuration = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}m ${seconds}s`;
  };

  return (
    <div className='encountersContainer'>
       {encounters.filter(encounter => !encounter.isDeleted).length > 0 && (
      <>
        <h3>Encounters</h3>
        <div style={{ height: '1px', backgroundColor: 'black', marginTop: '10px', marginBottom: '10px' }}></div>
      </>
    )}
      {encounters
        .filter(encounter => !encounter.isDeleted) // Filter out deleted encounters
        .map(encounter => (<div
          key={encounter.id}
          className={`encounterCard ${encounter.BillingStatus && encounter.BillingStatus === 'NotBilled' ? 'notBilled' : ''}`}
          onClick={() => handleToggle(encounter.id)}
          title={'Click to expand'} // Tooltip text
        >
          <div className="serviceAndDateContainer">
            {/* Display the FontAwesome icon based on encounter type */}
            {faIcons[encounter.encounterType] && (
              <FontAwesomeIcon
                icon={faIcons[encounter.encounterType]}
                className="encounterIcon"
                title={encounter.encounterType} // Tooltip text
              />
            )}
            <p>{encounter.service}</p>
            <p>{encounter.dateOfService}</p>
            <span
              className="toggleBillingStatus"
              onClick={(e) => {
                e.stopPropagation();
                handleToggleBillingStatus(encounter.id, encounter.BillingStatus);
              }}
              title={'Click to mark as billed.'}
            >
              $
            </span>
            <button
              className="delete"
              style={{ color: 'white' }}
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(encounter.id);
              }}
            >
              <FontAwesomeIcon className='mr-3' icon={faTrash} />
            </button>
          </div>
          {openEncounterId === encounter.id && (
            <>
              {/* <p><strong>Diagnosis Code:</strong> {encounter.diagnosisCode}</p> */}
              <p><strong>Reason for Visit:</strong> {encounter.reasonForVisit}</p>
              {encounter.duration && (
                <p><strong>Duration:</strong> {formatDuration(encounter.duration)}</p>
              )}
              <p><strong>Encounter Type:</strong> {encounter.encounterType}</p>
              <p><strong>Provider Organization:</strong> {encounter.organizationName}</p>
              <p><strong>Provider:</strong> {encounter.organizationName}{' |'} {encounter.providerfirstName}{' '}{encounter.providerlastName}</p>

              <p><strong>Notes:</strong> {encounter.notes}</p>
            </>
          )}
        </div>
        ))}
    </div>
  );
}

export default EncountersView;