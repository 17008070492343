import React, { useState, useEffect } from "react";
import { auth, db } from "../../../firebase";
import HexSpinner from "../../General/Animations/Hexspinner";
import { collection, getDocs, query, where, limit } from "firebase/firestore";

const SearchBox = ({ onCodeSelect, initialSelectedCodes = [] }) => {
  const [selectedCodes, setSelectedCodes] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [manualOverride, setManualOverride] = useState(false);
  const [manualCode, setManualCode] = useState("");

    useEffect(() => {
    const hasInitialCodesChanged =
      JSON.stringify(initialSelectedCodes) !== JSON.stringify(selectedCodes);

    if (Array.isArray(initialSelectedCodes) && hasInitialCodesChanged) {
      setSelectedCodes(initialSelectedCodes);
    }
  }, [initialSelectedCodes]);

  const handleCodeSelect = (result) => {

    if (!selectedCodes.some((code) => code.code === result.code)) {
      const newSelectedCodes = [...selectedCodes, result];
      setSelectedCodes(newSelectedCodes);
      setSearchTerm("");

      if (onCodeSelect) {
        onCodeSelect(newSelectedCodes);
      }
      setShowResults(false);
    }
  };

  const handleManualCodeSelect = () => {
    if (manualCode.trim() !== "" && (!selectedCodes.some((code) => code.code === manualCode))) {
      const newSelectedCodes = [...selectedCodes, {'code' : manualCode}]; // Ensure it's an array
      setSelectedCodes(newSelectedCodes);
      if (onCodeSelect) onCodeSelect(newSelectedCodes);
      setManualOverride(false);
      setManualCode("");
    } else {
      // case for if code already exists in list
      console.error("code is already in list");

    }
  };

  const handleChangeManualCode = (event) => {
    setManualCode(event.target.value);
  };

  const removeSelectedCode = (codeToRemove) => {
    const updatedSelectedCodes = selectedCodes.filter(
      (code) => code.code !== codeToRemove
    );
    setSelectedCodes(updatedSelectedCodes);
  };

  const searchICD10 = async (term) => {
    try {
      const icd10Ref = collection(db, "ICD10");
      const cleanedTerm = term.trim().replace(".", ""); // Remove periods
      const capitalizedTerm =
        cleanedTerm.charAt(0).toUpperCase() + cleanedTerm.slice(1);
      const upperCaseTerm = cleanedTerm.toUpperCase();

      const executeQueries = async (searchTerm) => {
        const codeQuery = query(
          icd10Ref,
          where("code", ">=", searchTerm.toUpperCase()), // Assuming codes are uppercase
          where("code", "<=", `${searchTerm.toUpperCase()}\uf8ff`),
          limit(25)
        );

        const descriptionQuery = query(
          icd10Ref,
          where("short_description", ">=", searchTerm),
          where("short_description", "<=", `${searchTerm}\uf8ff`),
          limit(25)
        );

        const longDescriptionQuery = query(
          icd10Ref,
          where("long_description", ">=", searchTerm),
          where("long_description", "<=", `${searchTerm}\uf8ff`),
          limit(25)
        );

        const snapshots = await Promise.all([
          getDocs(codeQuery),
          getDocs(descriptionQuery),
          getDocs(longDescriptionQuery),
        ]);

        const combinedResults = [];
        snapshots.forEach((snapshot) => {
          snapshot.docs.forEach((doc) => {
            const data = doc.data();
            if (data.valid_code === 1) {
              // Filtering only valid codes
              combinedResults.push(data);
            }
          });
        });
        return combinedResults;
      };

      // Get results for cleaned, capitalized, and upper case terms and then combine them
      const resultsCleaned = await executeQueries(cleanedTerm);
      const resultsCapitalized = await executeQueries(capitalizedTerm);
      const resultsUpperCase = await executeQueries(upperCaseTerm);

      // Combine and deduplicate results (assuming each entry has a unique 'code')
      const resultMap = {};
      [...resultsCleaned, ...resultsCapitalized, ...resultsUpperCase].forEach(
        (result) => {
          resultMap[result.code] = result;
        }
      );
      const combinedResults = Object.values(resultMap);
      return combinedResults;
    } catch (error) {
      console.error("Error while searching ICD10:", error);
      return [];
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === ".") {
      e.preventDefault();
    }
  };

  const throttle = (func, delay) => {
    let isThrottled = false;
    return (...args) => {
      if (isThrottled) return;
      func.apply(null, args);
      isThrottled = true;
      setTimeout(() => {
        isThrottled = false;
      }, delay);
    };
  };

  const handleChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
  };

  const handleSearch = async () => {
    if (searchTerm.trim() != "") {
      setIsLoading(true);
      const results = await searchICD10(searchTerm);
      setSearchResults(results);
      setShowResults(true);
      setIsLoading(false);
    } else {
      setSearchResults([]);
      setShowResults(false);
    }
  };

  useEffect(() => {
    const throttledSearch = throttle(handleSearch, 100);
    throttledSearch(searchTerm);
  }, [searchTerm]);

  return (
    <>
      {manualOverride ? (
        <div className="">
          <input
            type="text"
            placeholder="Enter Diagnosis Code"
            value={manualCode}
            onChange={(e) => handleChangeManualCode(e)}
          />
          <button type="button" onClick={() => handleManualCodeSelect()}>
            + Add Code
          </button>

          <button
            type="button"
            className=""
            onClick={() => setManualOverride(false)}
          >
            Cancel
          </button>
        </div>
      ) : (
        <>
          <div className="">
            <div
              className={`search-bar-ICD10 ${showResults && "searching-input"}`}
            >
              <div className="search-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
              </div>
              <input
                className="search-input-ICD10"
                type="text"
                placeholder="Search ICD10 codes"
                value={searchTerm}
                onChange={handleChange}
                onC={handleKeyPress}
              />
              <button
                onClick={() => setManualOverride(true)}
                className={`search-button-ICD10 ${
                  showResults && "searching-button"
                }`}
              >
                Manual
              </button>
            </div>

            {isLoading ? (
              <HexSpinner />
            ) : (
              <>
                {showResults && (
                  <div className="results-container">
                    {searchResults.map((result, index) => (
                      <div key={`${result.code}-${index}`}>
                        <span>
                          <button
                            type="button"
                            onClick={() => handleCodeSelect(result)}
                          >
                            Select
                          </button>
                          {result.code} - {result.long_description}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
      {selectedCodes.length > 0 && (
        <div>
          {selectedCodes.map((code, index) => (
            <div
              className="primary-card w-100 content-center py-2 mt-3 m-0 p-0"
              key={index}
              onClick={() => removeSelectedCode(code.code)}
            >
              <h3>{code.code}</h3>
              {code.short_description && (<p>{code.short_description}</p>)}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default SearchBox;