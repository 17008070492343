import React, { useState, useEffect } from 'react';
import { auth, db } from "../../firebase";
import { collection, onSnapshot, doc, getDoc, getDocs } from "firebase/firestore";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import ClaimsPopup from './ClaimsPopup';
import useUID from '../General/useUID';
import Modal from 'react-modal';
import { useNavigate, useParams } from 'react-router-dom';

const ClaimsList = () => {
  const [batches, setBatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedBatchId, setSelectedBatchId] = useState(null);
  const [filter, setFilter] = useState('');
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [dateFilter, setDateFilter] = useState('');
  const [batchSizeFilter, setBatchSizeFilter] = useState('');
  const [uid, subUserUID] = useUID();
  const [startDateFilter, setStartDateFilter] = useState('');
  const [endDateFilter, setEndDateFilter] = useState('');
  const { claimIdfromURL } = useParams(); 
  const navigate = useNavigate();

  useEffect(() => {
    if (!uid) return;
  
    const claimsRef = collection(db, `patients/${uid}/claims`);
  
    const unsubscribe = onSnapshot(claimsRef, async (snapshot) => {
      let batchMap = new Map();
  
      for (const claimDoc of snapshot.docs) {
        const claimData = claimDoc.data();
        if (!claimData.isDeleted) {
          const isSingle = !claimData.batchId;
          const batchId = isSingle ? `single-${claimDoc.id}` : claimData.batchId;
  
          if (!batchMap.has(batchId)) {
            batchMap.set(batchId, {
              id: batchId,
              isSingle: isSingle,
              claimIds: [],
              timestamp: claimData.timestamp || new Date()
            });
          }
          batchMap.get(batchId).claimIds.push(claimDoc.id);
        }
      }
  
      const allBatches = Array.from(batchMap.values());
      // Sort batches by date, newest first
       allBatches.sort((a, b) => b.timestamp.toDate() - a.timestamp.toDate());

      setBatches(allBatches);
      setLoading(false);
  
      if (claimIdfromURL) {
        const selectedId = await handleClaimSelectionByUrl(claimIdfromURL, allBatches);
        setSelectedBatchId(selectedId);
      }
    });
  
    return () => unsubscribe();
  }, [uid, claimIdfromURL]);
  
  
  // Updated function to handle both single claims and batch claims
  const handleClaimSelectionByUrl = async (claimId, batches) => {
    // First, find the claim document by the claimId
    const claimDocId = await getClaimDocIdByClaimId(claimId);
    if (!claimDocId) return null; // Return null if the claim document is not found

    // Fetch the claim document to check for batchId
    const claimDoc = await getDoc(doc(db, `patients/${uid}/claims/${claimDocId}`));
    if (!claimDoc.exists()) return null; // Return null if the claim document does not exist

    const claimData = claimDoc.data();
    if (claimData.batchId) {
        // If the claim is part of a batch (has batchId), return the batchId
        return claimData.batchId;
    } else {
        // If the claim is not part of a batch, return it as a single claim
        return `single-${claimDocId}`;
    }
};

  
  
  const getClaimDocIdByClaimId = async (claimId) => {
    if (!uid || !claimId) return null;
    try {
      const claimsRef = collection(db, `patients/${uid}/claims`);
      const querySnapshot = await getDocs(claimsRef);
  
      for (const docSnapshot of querySnapshot.docs) {
        const claimData = docSnapshot.data();
        if (claimData.claimId === claimId) {
          return docSnapshot.id; // This returns the document ID
        }
      }
      console.log(`No matching claim found for claimId: ${claimId}`);
      return null; 
    } catch (error) {
      console.error("Error fetching claim data: ", error);
      return null;
    }
  };
  



  const handleBatchClick = (batchId) => {
    setSelectedBatchId(batchId);
    navigate('/claims'); 
  };

  const toggleFilter = (filterType) => {
    setFilter(prevFilter => prevFilter === filterType ? '' : filterType);
  };

  const openFiltersModal = () => {
    setShowFiltersModal(true);
  };

  const closeFiltersModal = () => {
    setShowFiltersModal(false);
  };

  const applyFilters = () => {
    closeFiltersModal();
  };

  const clearFilters = () => {
    setDateFilter('');
    setBatchSizeFilter('');
    setFilter('');
    setStartDateFilter('');
    setEndDateFilter('');
  };
  
  const filteredBatches = batches
    .filter(batch => {
      // Filter by Date
      const filterDate = dateFilter ? new Date(dateFilter + 'T00:00:00') : null;
  
      // Compare only year, month, and day parts of the dates
      const isDateMatch = filterDate
        ? (batchDate.getFullYear() === filterDate.getFullYear() &&
           batchDate.getMonth() === filterDate.getMonth() &&
           batchDate.getDate() === filterDate.getDate())
        : true;
  
      // Filter by Batch Size
      const isBatchSizeMatch = batchSizeFilter ? batch.claimIds.length === parseInt(batchSizeFilter, 10) : true;
  
      // Apply 'single' and 'batch' filter logic
      const isSingleBatch = batch.id.startsWith('single-');
      const isBatchFilterMatch = (filter === 'single' && isSingleBatch) || (filter === 'batch' && !isSingleBatch) || filter === '';
   
      // Filter by Date Range
   const batchDate = new Date(batch.timestamp.toDate());
   const startDate = startDateFilter ? new Date(startDateFilter + 'T00:00:00') : null;
   const endDate = endDateFilter ? new Date(endDateFilter + 'T00:00:00') : null;

   const isDateInRange = (!startDate || batchDate >= startDate) && (!endDate || batchDate <= endDate);

   return isDateMatch && isBatchSizeMatch && isBatchFilterMatch && isDateInRange;
 });
  

    

  return (
    <div className='claimsListContainer'>
      <div className='searchAndFilter' style={{ display: 'flex' }}>
        <button onClick={openFiltersModal}>Filters</button>
      </div>

      <Modal
        isOpen={showFiltersModal}
        onRequestClose={closeFiltersModal}
        className="confirmModal"
      >
        <button className="filesCloseButton" onClick={closeFiltersModal}>
            X
          </button>
        <h2>Filter Claims</h2>
        <button onClick={() => toggleFilter('single')}><FontAwesomeIcon icon={faUser} /> Singles</button>
        <button onClick={() => toggleFilter('batch')}><FontAwesomeIcon icon={faUsers} /> Batches</button>
        <div>
          <label>Start Date: </label>
          <input type="date" value={startDateFilter} onChange={e => setStartDateFilter(e.target.value)} />
        </div>
        <div>
          <label>End Date: </label>
          <input type="date" value={endDateFilter} onChange={e => setEndDateFilter(e.target.value)} />
        </div>
        <div className='confirmButtons'>
          <button className="primaryButton" onClick={applyFilters}>
            Apply Filters
          </button>
          <button onClick={clearFilters}>Clear Filters</button>

        </div>
      </Modal>

      <div className='batchListContent'>
      {loading ? <div>Loading...</div> : filteredBatches.map(batch => {
      const claimCount = batch.claimIds.length || 0;
      const batchDate = new Date(batch.timestamp.toDate()).toLocaleDateString();
      const batchTitle = batch.isSingle ? `1 claim` : `${claimCount} claims`;
  
      return (
        <div key={batch.id} onClick={() => handleBatchClick(batch.id)}
        className={`batch-wrapper ${batch.id === selectedBatchId ? 'batch-selected' : ''}`}>
        <div className="batch-header-claimsList">
                <div className="batch-info-claimsList">
                  <div className='iconClaimsList'>
                    <FontAwesomeIcon icon={batch.isSingle ? faUser : faUsers} className='batch-icon' />
                    </div>
                    <h3>{batchTitle}</h3>
                </div>
                <div className="batch-date">
                    {batchDate}
                </div>
                </div>
        </div>
      );
    })}
      </div>    
      {selectedBatchId && (
        <ClaimsPopup 
          claimIds={selectedBatchId.startsWith('single-') ? [selectedBatchId.replace('single-', '')] : batches.find(batch => batch.id === selectedBatchId)?.claimIds}
          onClose={() => setSelectedBatchId(null)}
        />
      )}
      </div>


  );
};

export default ClaimsList;
