import React, { useState, useEffect } from 'react';
import styles from './Website.css';
import { Link } from 'react-router-dom';
import logo from './assetts/Specto_logo_specto.png';
import whitelogo from './assetts/Specto_logo_specto.png'
import Hextials from './Hextials'
import PricingTable from './PricingTable';
import { faCheckSquare, faChartLine, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';
import ICD10 from '../Claims/ICD10'
import Chat from '../AI/chat'
import Footer from './Footer';
import Header from './Header';
import FAQSection from './FAQ';



const TechSpecs = () => {

    const [selectedPayer, setSelectedPayer] = useState(null);
    const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);

    const handlePayerSelect = (payer) => {
      setSelectedPayer(payer);
      // Do something with the selected payer
    };

    // Handling window resize
    useEffect(() => {
        if (typeof window !== 'undefined') {
          const handleResize = () => setWidth(window.innerWidth);
          window.addEventListener('resize', handleResize);
          return () => window.removeEventListener('resize', handleResize);
        }
      }, []);

    return (
        <div className="landing-page">
            <Helmet>
                <title>Specto Health</title>
                <meta name="description" content="Software tools for the modern healthcare professional." />
                <meta name="keywords" content="Medical Software, HIPAA Compliant, Patient Eligibility, Batch Claims, AI in Healthcare" />
                <meta property="og:title" content="Specto Health" />
                <meta property="og:description" content="Software tools for the modern healthcare professional. Eligibility, Claims, Patients, and AI. Sign up today to streamline your healthcare practice." />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/spectohealth.appspot.com/o/specto_logo_wide.png?alt=media&token=72c8f353-f0f9-463c-966e-4f7b644bea5d" />  
            
            </Helmet>
            <Header />
            <div className='section-content'>
                <main>
                <section>
                        <div className="block">
                            <h1>RPM and CGM built for Professionals</h1>
                            <h3 className="centered-text reduced-margin">Latest Release:  2.2</h3>
                            <div className='patientsImage'>
                            </div>
                        </div>
                    </section>

                <section className='section-tech-specs'>
                        <div className='section-header-techspecs'>
                             <h2 className='section-headline'>Clinics</h2>
                        </div>
                            <div className='tech-specs-column-row'>
                                <ul className='tech-specs-list'>
                                    <li>
                                        <span>Public intake forms hosted at links set by the user</span>
                                    </li>
                                    <li>
                                        <span>Custom required fields</span>
                                    </li>
                                    <li>
                                        <span>Insurance ID camera</span>
                                    </li>
                                </ul>
                            </div>
                    </section>

                    <section className='section-tech-specs'>
                        <div className='section-header-techspecs'>
                             <h2 className='section-headline'>Codes</h2>
                        </div>
                            <div className='tech-specs-column-row'>
                                <ul className='tech-specs-list'>
                                    <li>
                                        <span>Diagnosis codes: ICD10</span>
                                    </li>
                                    <li>
                                        <span>Claim Status Codes</span>
                                    </li>
                                    <li>
                                        <span>Place of Service Codes</span>
                                    </li>
                                </ul>
                            </div>
                    </section>

                    <section className='section-tech-specs'>
                        <div className='section-header-techspecs'>
                             <h2 className='section-headline'>Capacity</h2>
                        </div>
                            <div className='tech-specs-column-row'>
                            <ul className='tech-specs-list'>
                                    <li>
                                        <span>Tested with up to 50,000 patients</span>
                                    </li>
                                    <li>
                                        <span>Tested with up to 20,000 claims</span>
                                    </li>
                                    <li>
                                        <span>Maximum of 1,500 claims per batch claim processing</span>
                                    </li>
                                    <li>
                                        <span>Maximum of 500 claims per batch eligibility check</span>
                                    </li>
                                    <li>
                                        <span>Processes up to 33 claims per minute with batch claims</span>
                                    </li>
                                    <li>
                                        <span>Batch patient uploads: 1,000 patients in 4.8 seconds</span>
                                    </li>
                                </ul>
                            </div>
                    </section>

           

                    <section className='section-tech-specs'>
                        <div className='section-header-techspecs'>
                             <h2 className='section-headline'>Interoperability</h2>
                        </div>
                            <div className='tech-specs-grid'>
                              <div className='tech-specs-grid-item'>
                                <ul className='tech-specs-list'>
                                <li>
                                    <span><strong>Patient Data:</strong></span>
                                </li>
                                <li>
                                    <span>Encounters API: Callback for transmitting new encounters </span>
                                </li>
                                <li>
                                    <span>Lab Results API: API for updating lab results </span>
                                </li>
                                <li>
                                    <span>Individual patient data download (XLSX or PDF)</span>
                                </li>
                                <li>
                                    <span>Batch patient data download (XLSX)</span>
                                </li>
                                <li>
                                    <span>Batch patient upload (XLSX)</span>
                                </li>
                                </ul>
                              </div>
                              <div className='tech-specs-grid-item'>
                              <ul className='tech-specs-list'>
                                    <li>
                                    <span><strong>Claims Data:</strong></span>
                                    </li>
                                    <li>
                                        <span>Download CMS1500 forms</span>
                                    </li>
                                    <li>
                                        <span>Download a batch of sent claims (XSLX)</span>
                                    </li>
                                    <li>
                                        <span>Download from claims table with filters and search applied (XSLX)</span>
                                    </li>
                                    <li>
                                        <span>Create reports using demographics, codes, or payers (XSLX)</span>
                                    </li>
                                    <li>
                                        <span>Accounts Receivable Reports (XSLX or PDF)</span>
                                    </li>
                                </ul>
                              </div>
                            </div>
                    </section>


                    <section className='section-tech-specs'>
                        <div className='section-header-techspecs'>
                             <h2 className='section-headline'>Security</h2>
                        </div>
                            <div className='tech-specs-column-row'>
                                <ul className='tech-specs-list'>
                                    <li>
                                        <span>HIPPA Compliant Cloud-Based Servers</span>
                                    </li>
                                    <li>
                                        <span>Unique user identification, strong authentication, and role-based access controls</span>
                                    </li>
                                    <li>
                                        <span>Detailed Activity Logs</span>
                                    </li>
                                    <li>
                                        <span>Safeguards established to protect ePHI from unauthorized alteration or destruction</span>
                                    </li>
                                    <li>
                                        <span>Regular ePHI backups and a disaster recovery in place</span>
                                    </li>
                                </ul>
                            </div>
                    </section>

                    <section className='section-tech-specs'>
                        <div className='section-header-techspecs'>
                             <h2 className='section-headline'>APIs</h2>
                        </div>
                            <div className='tech-specs-column-row'>
                                <ul className='tech-specs-list'>
                                    <li>
                                        <span>Patient Encounters</span>
                                    </li>
                                    <li>
                                        <span>Lab test results</span>
                                    </li>
                                </ul>
                            </div>
                    </section>

                    <section className='section-tech-specs'>
                        <div className='section-header-techspecs'>
                             <h2 className='section-headline'>Patient Data</h2>
                        </div>
                            <div className='tech-specs-column-row'>
                                <div className='grid tech-specs-grid-3wide'>
                                    <div className='tech-specs-grid-item'>
                                        <ul className='tech-specs-list'>
                                            <li>
                                                 <span><strong>Basic Information:</strong></span>
                                            </li>
                                            <li>
                                                <span>Name</span>
                                            </li>
                                            <li>
                                                <span>Date of Birth</span>
                                            </li>
                                            <li>
                                                <span>Gender</span>
                                            </li>
                                            <li>
                                                <span>Address</span>
                                            </li>
                                            <li>
                                                <span>Phone</span>
                                            </li>
                                            <li>
                                                <span>Email</span>
                                            </li>
                                            </ul>
                                        </div>

                                    <div className='tech-specs-grid-item'>
                                        <ul className='tech-specs-list'>
                                            <li>
                                                 <span><strong>Medical History:</strong></span>
                                            </li>
                                            <li>
                                                <span>Family History</span>
                                            </li>
                                            <li>
                                                <span>Immunizations and Allergies</span>
                                            </li>
                                            <li>
                                                <span>Lab and Test Results</span>
                                            </li>
                                            <li>
                                                <span>Medication</span>
                                            </li>
                                            <li>
                                                <span>Vitals</span>
                                            </li>
                                            </ul>
                                        </div>

                            <div className='tech-specs-grid-item'>
                                    <ul className='tech-specs-list'>
                                        <li>
                                            <span><strong>Documents:</strong></span>
                                        </li>
                                        <li>
                                            <span>Notes with Signatures</span>
                                        </li>
                                        <li>
                                            <span>Files (any format)</span>
                                        </li>
                                        <li>
                                            <span>Encounters</span>
                                        </li>
                                        <li>
                                            <span>Claims</span>
                                        </li>
                                        <li>
                                            <span>Claims Status</span>
                                        </li>
                                        <li>
                                            <span>Eligibility Reports</span>
                                        </li>
                                    </ul>
                            </div>
                          </div>
                        </div>
                    </section>

            
                



                </main>
                </div>
                <Footer />
        </div>
    );
};

export default TechSpecs;
