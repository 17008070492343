import React, { useEffect, useState, useRef } from 'react';
import SignaturePad from './SignaturePad';
import { auth, db } from "../../../firebase";
import './Signatures.css';
import { collection, query, doc, getDocs, where, setDoc, deleteDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import useUID from '../../General/useUID'

const functions = getFunctions();

export default function Signature({ onBack, onNext, onApplySignature }) {
  const [signatures, setSignatures] = useState([]);
  const [selectedSignature, setSelectedSignature] = useState(null);
  const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);
  const [saveSignature, setSaveSignature] = useState(false);
  const signatureRef = useRef(null);
  const [uid, subUserUID] = useUID();
  const [userName, setUserName] = useState(""); 

  useEffect(() => {
    if (!uid) return;
    getSignatures();
  }, [uid]);

  useEffect(() => {
    if (signatures.length > 0) {
      // const latestSignature = signatures.reduce((latest, signature) =>
      //   signature.createdDate > latest.createdDate ? signature : latest
      // );

      const sortedSignatures = signatures.sort((a, b) => b.createdDate - a.createdDate);
      const latestSignature = sortedSignatures[0];
      setSelectedSignature(latestSignature);

      setSelectedSignature(latestSignature);
    }
  }, [signatures]);

  const getSignatures = async () => {
    try {
      const signaturesSnapshot = await getDocs(
        query(collection(db, 'signatures'), where('userId', '==', uid))
      );

      const signaturesList = signaturesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        createdDate: formatTimestamp(doc.data().createdDate),
        userName: doc.data().userName || 'Unnamed' // Fallback for signatures without a user name
      }));

      setSignatures(signaturesList);
    } catch (error) {
      console.error('Error fetching signatures:', error);
    }
  };


  const formatTimestamp = (timestamp) => {
    const date = timestamp.toDate();
    return date.toLocaleString();
  };

  const handleBackClick = () => {
    onBack();
  };

  const handleSignatureSelect = (signatureId) => {
    const selected = signatures.find((signature) => signature.id === signatureId);
    setSelectedSignature(selected);
  };

    const handleSignatureCreate = async (signatureData) => {
    try {
      // Add the user's name to the signature data
      const signatureWithUserName = {
        ...signatureData,
        userName: userName // Save user name along with signature data
      };

      const signaturesRef = collection(db, 'signatures');
      const newSignatureDocRef = doc(signaturesRef);

      await setDoc(newSignatureDocRef, signatureWithUserName).then(() => { getSignatures() });

      //Logs
      const addLogFunction = httpsCallable(functions, 'addLog');
      await addLogFunction({ uid, message: 'Signature created with user name.' });

      return newSignatureDocRef.id;
    } catch (error) {
      console.error('Error adding signature to Firestore:', error);
      throw error;
    }
  };

  // Handle change in user name input
  const handleUserNameChange = (name) => {
    setUserName(name);
};

  const handleApplySignature = async () => {
    let signatureData = null;

    if (selectedSignature) {
      signatureData = selectedSignature;
    } else {
      if (!signatureRef.current) return;

      signatureData = {
        userId: uid,
        imageData: signatureRef.current.toDataURL(),
        createdDate: new Date(),
        userName: userName, // Include user name
      };

      if (saveSignature) {
        await handleSignatureCreate(signatureData);
      }
    }

    if (signatureData) {
      onApplySignature(signatureData);
      onNext();
    }
  };

  const handleDeleteSignature = async (signatureId) => {
    try {
      const signatureDocRef = doc(collection(db, 'signatures'), signatureId);
      await deleteDoc(signatureDocRef);

      const updatedSignatures = signatures.filter((signature) => signature.id !== signatureId);
      setSignatures(updatedSignatures);
      setSelectedSignature(null);

    } catch (error) {
      console.error('Error deleting signature:', error);
    }
  };

  const handleSignatureRefChange = (ref) => {
    signatureRef.current = ref;
  };

  return (
    <div className="signature-container">
      <div className="signature-list">
        <h2>Your Signatures</h2>
        <select onChange={(e) => handleSignatureSelect(e.target.value)} value={selectedSignature ? selectedSignature.id : 'create'}>
        {signatures.map((signature) => (
            <option key={signature.id} value={signature.id}>
              {signature.userName ? `${signature.userName}` : `Signature - ${signature.createdDate}`} 
            </option>
          ))}
          <option value="create">+ Create Signature</option>
        </select>
        <div className="button-group-signature">
          <button
            onClick={handleApplySignature}
            disabled={isSignatureEmpty && !selectedSignature}
            className='next-button'
          >
          Save note with signature
          </button>
          <button onClick={handleBackClick} className='back-button'>
            Back
          </button>
        </div>
      </div>
      <div className="signature-pad">
        {selectedSignature ? (
          <>
            <h2>Existing Signature</h2>
            <div className="signature-preview">
              <img src={selectedSignature.imageData} alt="Selected Signature" />
              <button onClick={() => handleDeleteSignature(selectedSignature.id)} className='back-button'>
                Delete Signature
              </button>
            </div>
          </>
        ) : (
          <>
            <h2>Create a New Signature</h2>
            <SignaturePad
              onSignatureRefChange={handleSignatureRefChange}
              isSignatureEmpty={(isEmpty) => setIsSignatureEmpty(isEmpty)}
              onSaveSignatureChange={(saveSignature) => setSaveSignature(saveSignature)}
              onUserNameChange={handleUserNameChange}            />
          </>
          
        )}
      </div>
    </div>
  );
}
