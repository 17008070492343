import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { storage, auth, db } from '../../../firebase';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import teachinguploadFile from './TeachingUpload';
import { getFirestore, collection, getDocs, doc, updateDoc } from 'firebase/firestore';

function TeachingDocuments({ onDocumentSelect }) {
  const [sources, setSources] = useState([]);
  const [error, setError] = useState(null);

  const onDrop = (acceptedFiles) => {
    handleUpload(acceptedFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const getDocuments = async () => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        setError('No user signed in');
        return;
      }
  
      const db = getFirestore();
      const docRef = collection(db, 'teachingDocuments');
      const snapshot = await getDocs(docRef);
      const documents = snapshot.docs.filter(doc => 
        doc.data().uid === currentUser.uid && doc.data().deleteFlag === 1
      ).map(doc => ({
        originalFilename: doc.data().originalFilename,
        deleteFlag: doc.data().deleteFlag,
        id: doc.id,
        uid: doc.data().uid,
        status: doc.data().status,
      }));
      setSources(documents);
    } catch (error) {
      console.error('Error fetching documents:', error);
      setError('Failed to fetch documents');
    }
  };
  

  useEffect(() => {
    getDocuments();
  }, []);

  const handleDelete = async (document) => {
    try {
      const db = getFirestore();
      const docRef = doc(db, 'teachingDocuments', document.id);
      await updateDoc(docRef, { deleteFlag: 0 });
      getDocuments();
    } catch (error) {
      console.error('Error deleting document:', error);
      setError('Failed to delete document');
    }
  };

  const handleUpload = async (files) => {
    if (!files.length) {
      setError('No file selected');
      return;
    }

    const currentUser = auth.currentUser;
    if (!currentUser) {
      setError('No user signed in');
      return;
    }

    try {
      const results = await Promise.all(files.map((file) => teachinguploadFile(file, currentUser)));
      
      // If any of the uploads failed, show an error message.
      const failedUpload = results.find(result => !result.success);
      if (failedUpload) {
        setError(failedUpload.message);
      } else {
        setError(null);
        getDocuments();
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <div className="sidebar">
      <div className='uploads-list'>
      {sources.map((document, index) => (
        <div className="documentContainer" key={index}>
          <FontAwesomeIcon icon={faFileAlt} className="svg-inline--fa" />
            <div className='documentLabel'>
              <p className="textLabel">{document.originalFilename}</p>
              {document.status === 'processing' ? (
                  <div><p className="processingLabel">Processing</p>
                  <button className='buttonai' onClick={() => handleDelete(document)}>
                  Delete
                </button>
                </div>
                ) : document.status.startsWith('error') ? (
                  <div><p className="errorLabel">{document.status}</p>
                  <button className='buttonai' onClick={() => handleDelete(document)}>
                    Delete
                  </button></div>
                ) : (
                  document.deleteFlag !== 0 && 
                  <button className='buttonai' onClick={() => handleDelete(document)}>
                    Delete
                  </button>
                )}
            </div>
          </div>
      ))}


    </div>

      <hr className="mb-4" />

      <h3 className="text-center">Document Upload:</h3>

      {error && (
        <div className="mb-5 text-center">
          <p className="text-red-600">{error}</p>
        </div>
      )}

<div {...getRootProps()} className="dropbox">
  <input {...getInputProps()} />
  {isDragActive ? (
    <p style={{ textAlign: 'center' }}>Drop the files here...</p>
  ) : (
    <p style={{ textAlign: 'center' }}>Click or drag and drop a file here to upload</p>
  )}
</div>
    </div>
  );
}
export default TeachingDocuments;
