/**
 * FormEditor
 * Created by: Brandon Stewart/Popularis Health
 * Date: 03/01/2024
 * Description:
 */

import React, { useState, useEffect } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import Palette from "./Palette";
import FormCanvas from "./FormCanvas";
import "../FormEditor.css";
import fieldDefinitions from "../fieldDefinitions";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import useUID from "../../../General/useUID";

const FormEditor = ({ formId = null, handleSave = async () => {}}) => {
  const [formElementsData, setFormElementsData] = useState([]);
  const [fieldDefinitionsData, setFieldDefinitionsData] =
    useState(fieldDefinitions);
  const [uid] = useUID();
  const field = Object.entries(fieldDefinitionsData);
  const db = getFirestore();
  const [isLoading, setIsLoading] = useState(true);
  const [reload, setReload] = useState(3);
  const [formName, setFormName] = useState("Custom Form");

  const loadFormData = async () => {
    if (!formId) {
      console.log("Form ID is missing");
      setIsLoading(false);
      return;
    }

    if (!reload) {
      setIsLoading(false);
      return;
    }

    try {
      const formDocRef = doc(db, `users/${uid}/forms`, formId);
      const formDocSnap = await getDoc(formDocRef);
      if (formDocSnap.exists()) {
        setFormElementsData(formDocSnap.data().formElements);
      } else {
        console.log("Form not found!");
        setFormElementsData([]);
      }
    } catch (error) {
      console.error("Error fetching form data:", error);
      setFormElementsData([]);
    }

    setIsLoading(false);
    setReload(reload - 1);
  };

  useEffect(() => {
    (async function () {
      await Promise.all([loadFormData()]);
    })();
  }, [reload]);

  const saveFormData = async () => {
    console.log("saving");
    try {
      const formData = {
        formElements: formElementsData.map((element, index) => ({
          ...element,
          order: index,
          expandableAreaOpen: false,
        })),
        uid,
        title: (formName) ? formName : "Custom Form",
        updatedAt: new Date(),
        deleted: false,
        
      };

      if (formId) {
        formData.createdAt = new Date();
      }

      formId = formId ? formId : `formId-${uid}-${Date.now()}`;

      // Save the form data
      const formDocRef = doc(db, `users/${uid}/forms`, formId);
      await setDoc(formDocRef, formData);

      await handleSave();

      console.log("Form data saved successfully");
    } catch (error) {
      console.error("Error saving form data:", error);
    }
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) return;
    if (destination.droppableId === "palette-droppable") return;

    const droppedElementType = field.find(
      ([key, typeData]) => typeData.type === draggableId
    );

    let defaultField = {
      label: null,
      placeholder: null,
      required: false,
      isVisible: true,
      default: null,
    };

    if (droppedElementType) {
      const newElement = {
        id: `${draggableId}-${formElementsData.length}`,
        type: draggableId,
        fields: droppedElementType[1].formElementSubTypes.map(() => ({
          ...defaultField,
        })),
      };

      const updatedElements = [...formElementsData];
      updatedElements.splice(destination.index, 0, newElement);
      setFormElementsData(updatedElements);
    } else if (destination.droppableId === "form-canvas-droppable") {
      const reorderedElements = [...formElementsData];
      const [removed] = reorderedElements.splice(source.index, 1);
      reorderedElements.splice(destination.index, 0, removed);
      setFormElementsData(reorderedElements);
    }
  };

  return (
    <div className="grid-container-lg w-100 my-3">
      <div className="my-3 text-center">
        <input
          type="text"
          value={formName}
          onChange={(e) => setFormName(e.target.value)}
          placeholder="Enter Form Name"
          className="TitleInput"
        />
      </div>
      {/* (isLoading) */}
      <div className="FormEditorContainer">
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="FormCanvasContainer">
            <FormCanvas
              formElementsData={formElementsData}
              setFormElementsData={setFormElementsData}
              fieldDefinitions={fieldDefinitionsData}
            />
          </div>
          <Palette
            onElementAdd={(newElement) =>
              setFormElementsData([...formElementsData, newElement])
            }
            saveFormData={saveFormData}
            fieldDefinitions={fieldDefinitionsData}
          />
        </DragDropContext>
      </div>
    </div>
  );
};

export default FormEditor;
