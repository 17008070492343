import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import styles from './Clinics.css';
import { auth, db } from "../../../firebase";
import {
    getDoc,
    doc, 
    updateDoc
} from "firebase/firestore";

const ClinicEditModal = ({ selectedClinic, onSave, setSelectedClinic, onClinicsUpdated }) => {
    const [clinicData, setClinicData] = useState(null);

    useEffect(() => {
        const fetchClinicData = async () => {
            if (selectedClinic && selectedClinic.id) {
                const clinicRef = doc(db, 'clinics', selectedClinic.id);
                const clinicSnapshot = await getDoc(clinicRef);
        
                if (clinicSnapshot.exists()) {
                    setClinicData(clinicSnapshot.data());
                }
            }
        };
  
        fetchClinicData();
    }, [selectedClinic]);

    const handleSaveEdit = async () => {
    
        if (clinicData) {
            try {
                // Use selectedClinic.id instead of clinicData.id
                const clinicRef = doc(db, 'clinics', selectedClinic.id);
    
                await updateDoc(clinicRef, {
                    clinicName: clinicData.clinicName,
                    addInsuranceCardCamera: clinicData.addInsuranceCardCamera,
                    requireAddress: clinicData.requireAddress,
                    requireInsuranceInfo: clinicData.requireInsuranceInfo,
                    requirePhone: clinicData.requirePhone
                });
        
                setSelectedClinic(null); // Close the modal after updating
                onClinicsUpdated();     // Notify the parent component to refetch or update its state
    
            } catch (error) {
                console.error('Error updating clinic:', error);
            }
        }
    };
    
    

    return (
        <ReactModal
            isOpen={!!selectedClinic}
            onRequestClose={() => setSelectedClinic(null)}
            className={styles.confirmModal}
            overlayClassName={styles.confirmModalOverlay}
        >
            <h2>Edit Clinic</h2>

            <label>Clinic Name: </label>
            <input 
                type="text" 
                value={clinicData ? clinicData.clinicName : ''} 
                onChange={(e) => setClinicData(prev => ({ ...prev, clinicName: e.target.value }))}
            />

            <br/>

<label>Add Insurance Card Camera: </label>
<input 
    type="checkbox"
    checked={clinicData ? clinicData.addInsuranceCardCamera : false}
    onChange={(e) => setClinicData(prev => ({ ...prev, addInsuranceCardCamera: e.target.checked }))}
    className="custom-checkbox"
    required
/><br/>

<label>Require Address: </label>
<input 
    type="checkbox"
    checked={clinicData ? clinicData.requireAddress : false}
    onChange={(e) => setClinicData(prev => ({ ...prev, requireAddress: e.target.checked }))}
    className="custom-checkbox"
    required
/><br/>

<label>Require Insurance Info: </label>
<input 
    type="checkbox"
    checked={clinicData ? clinicData.requireInsuranceInfo : false}
    onChange={(e) => setClinicData(prev => ({ ...prev, requireInsuranceInfo: e.target.checked }))}
    className="custom-checkbox"
    required
/><br/>

<label>Require Phone: </label>
<input 
    type="checkbox"
    checked={clinicData ? clinicData.requirePhone : false}
    onChange={(e) => setClinicData(prev => ({ ...prev, requirePhone: e.target.checked }))}
    className="custom-checkbox"
    required
/><br/>


            <button onClick={handleSaveEdit}>Save</button>
            <button onClick={() => setSelectedClinic(null)}>Cancel</button>
        </ReactModal>
    );
};

export default ClinicEditModal;
