import React, { useState, useEffect } from 'react';
import { auth, db } from "../../../firebase";
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import HexagonSpinner from '../../General/Animations/Hexspinner';
import useUID from '../../General/useUID'
import { getFunctions, httpsCallableFromURL, httpsCallable } from 'firebase/functions';

const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
};

const ClaimHistogram = () => {
  const [loading, setLoading] = useState(true);
  const [claimHistogramData, setClaimHistogramData] = useState([]);
  const [timeFrame, setTimeFrame] = useState('1m');
  const [uid, subUserUID] = useUID();

  useEffect(() => {
    if (!uid) return;

    const fetchClaims = async () => {
      setLoading(true);
      const uid = auth.currentUser?.uid;
      
      const functions = getFunctions();
      const aggregateClaimsHistogram = httpsCallable(functions, 'aggregateClaimsHistogram');
      
      try {
        const response = await aggregateClaimsHistogram({ uid, timeFrame });
        const histogramData = response.data;
        setClaimHistogramData(histogramData);
      } catch (error) {
        console.error("Error fetching histogram data:", error);
      }
    
      setLoading(false);
    };
    

    fetchClaims();
  }, [timeFrame, uid]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '5px', border: '1px solid #ccc' }}>
          <p>{label}</p>
          <p>Number of Claims Submitted: {payload[0].payload.numberOfClaims}</p>
          <p>Total Amount Submitted: ${payload[0].payload.totalAmount.toFixed(2)}</p>
        </div>
      );
    }
  
    return null;
  };
  
  

  if (loading) {
    return (
      <div>
        <HexagonSpinner />
        <h4>Loading each claim sent daily.</h4>
      </div>
    );
  }

  return (
    <div className='SingleChart'>
      <h3 style={{ textAlign: 'center' }}>Claims Sent Per Day</h3>
      {/* Time Frame Selector */}
      <div style={{ display: 'flex', justifyContent: 'center', margin: '10px 0' }}>
      <select value={timeFrame} onChange={(e) => setTimeFrame(e.target.value)}>
          <option value="1w">1 Week</option>
          <option value="1m">1 Month</option>
          <option value="1y">1 Year</option>
        </select>

      </div>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={claimHistogramData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="numberOfClaims" fill="#206274" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ClaimHistogram;
