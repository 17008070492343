import { useMemo } from 'react';

function capitalizeName(name) {
  if (!name) {
    return "";
  }
  return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
}


function usePatientSearch(patients, searchTerm) {
  const filteredPatients = useMemo(() => {
    if (!searchTerm) {
      return patients.map(patient => ({ ...patient, matchType: null, matchedText: '' }));
    }

    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    return patients
      .map(patient => {

        //Basic Patient Info
        const fullName = `${capitalizeName(patient.data.patient.firstName)} ${capitalizeName(patient.data.patient.middleName)} ${capitalizeName(patient.data.patient.lastName)}`;
        const memberId = patient.data.patient.memberId || patient.payers?.memberId || '';
        const insurance = patient.data.payers?.name || '';
        const dob = patient.data.patient.dob || '';
        const phone = patient.data.patient.phone || '';
        const email = patient.data.patient.email || '';
        const addressData = patient.data.patient.address || {};
        const address1 = addressData.address1 || '';
        const city = addressData.city || '';
        const state = addressData.state || '';
        const zipString = String(addressData.zip || '');
        const flag = patient.data.flag || '';

     
        if (fullName.toLowerCase().includes(lowerCaseSearchTerm)) return { ...patient, matchType: 'name', matchedText: '' };
        if (typeof phone === 'string' && phone.includes(searchTerm)) {return { ...patient, matchType: 'phone', matchedText: phone };}
       if (email.toLowerCase().includes(lowerCaseSearchTerm)) return { ...patient, matchType: 'email', matchedText: email };
        // Add a condition to check if the flag matches the search term
        if (flag.toLowerCase().includes(lowerCaseSearchTerm)) {
          return { ...patient, matchType: 'flag', matchedText: flag };
        }       
        if (address1.toLowerCase().includes(lowerCaseSearchTerm)) {
          return { ...patient, matchType: 'address', matchedText: address1 };
        } else if (city.toLowerCase().includes(lowerCaseSearchTerm)) {
          return { ...patient, matchType: 'address', matchedText: city };
        } else if (state.toLowerCase().includes(lowerCaseSearchTerm)) {
          return { ...patient, matchType: 'address', matchedText: state };
        } else if (zipString.includes(searchTerm)) {
          return { ...patient, matchType: 'address', matchedText: zipString };
        }
        if (String(dob).includes(searchTerm)) return { ...patient, matchType: 'dob', matchedText: dob };
        if (insurance.toLowerCase().includes(lowerCaseSearchTerm)) return { ...patient, matchType: 'insurance', matchedText: insurance };
        if (String(memberId).includes(searchTerm)) return { ...patient, matchType: 'memberId', matchedText: memberId };

        return null;      
      })
      .filter(patient => patient !== null);
  }, [patients, searchTerm]);

  return filteredPatients;
}

export default usePatientSearch;
