import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Topbar from "../General/Topbar";
import "./Claims.css";
import ClaimsList from "./ClaimsList";
import ClaimsPopup from './ClaimsPopup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCircleCheck, faMagnifyingGlassDollar, faUserMd, faCalculator } from "@fortawesome/free-solid-svg-icons";
import Dashboard from './Dashboard';
import ToolsBar from './ToolsBar';

function Claims() {
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [showProvidersMenu, setShowProvidersMenu] = useState(false);
  const [showCodesMenu, setShowCodesMenu] = useState(false);
  const navigate = useNavigate();
  const { claimIdfromURL } = useParams();

  useEffect(() => {
    if (claimIdfromURL) {
      setSelectedBatch({ claimIds: [claimIdfromURL] });
    } else {
      setSelectedBatch(null);
    }
  }, [claimIdfromURL]);

  const handleClosePopup = () => {
    setSelectedBatch(null);
    navigate('/claims');
  };

  return (
    <div>
      <Topbar />
      <ClaimsList setSelectedBatch={setSelectedBatch} />
      {!claimIdfromURL && (
        <div className='DashboardContainer'>
          <div >
            <ToolsBar />
          </div>
          <Dashboard />
        </div>
      )}
    </div>
  );
}

export default Claims;
