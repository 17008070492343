import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNotesMedical, faSyringe, faFlask, faPills, faHeartbeat } from '@fortawesome/free-solid-svg-icons';
import FamilyHistory from './MedicalHistory/FamilyHistory';
import ImmunizationsAllergies from './MedicalHistory/ImmunizationsAllergies';
import LabandTest from './MedicalHistory/LabandTest';
import Medications from './MedicalHistory/Medications';
import Vitals from './MedicalHistory/Vitals';
import styles from "./PatientView.module.css";
import PatientMedicalHistoryView from './MedicalHistory/MedicalHistoryView';

const sections = [
  { name: 'Family History', component: FamilyHistory, icon: faNotesMedical },
  { name: 'Immunizations and Allergies', component: ImmunizationsAllergies, icon: faSyringe },
  { name: 'Lab and Test Results', component: LabandTest, icon: faFlask },
  { name: 'Medication', component: Medications, icon: faPills },
  { name: 'Vitals', component: Vitals, icon: faHeartbeat }
];

function MedicalHistory({ patientId }) {
    const [modalOpen, setModalOpen] = useState(false);
    const [activeSection, setActiveSection] = useState(null);
    const [showOptions, setShowOptions] = useState(false);
  
    const openModal = (section) => {
      setActiveSection(section.name);
      setModalOpen(true);
    };
  
    const closeModal = () => {
      setModalOpen(false);
    };
   
    const handleAddMedicalHistoryClick = () => {
      setShowOptions(true);
    };
  
    const renderSectionForm = () => {
      const section = sections.find(s => s.name === activeSection);
      if (!section) return null;
  
      const SectionComponent = section.component;
      return (
        <SectionComponent
          patientId={patientId}
          onClose={closeModal} // Passing the onClose function here
        />
      );
    };
  
    return (
      <div>
        {!showOptions && (
          <button onClick={handleAddMedicalHistoryClick}>
            <FontAwesomeIcon icon={faNotesMedical} /> Add Medical History
          </button>
        )}
  
        {showOptions && (
          <div>
            {sections.map((section) => (
              <button key={section.name} onClick={() => openModal(section)}>
                <FontAwesomeIcon icon={section.icon} /> {section.name}
              </button>
            ))}
          </div>
        )}
  
        {modalOpen && (
          <div className={styles.overlay}>
            <div className={styles.modalBox}>
            <div className={styles.medicalHistoryContainer}>
              <button onClick={closeModal} className='filesCloseButton'>
                X
              </button>
              <h3>{activeSection}</h3>
              {renderSectionForm()}
            </div>
          </div>
          </div>
        )}
  
  <PatientMedicalHistoryView patientId={patientId} />
      </div>
    );
  }
  
  export default MedicalHistory;