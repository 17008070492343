import React, { useEffect, useState, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';

function SignaturePad({ onSignatureRefChange, isSignatureEmpty, onSaveSignatureChange }) {
  const signatureRef = useRef(null);
  const [isSaveSignatureChecked, setIsSaveSignatureChecked] = useState(false);

  useEffect(() => {
    handleSignatureChange();
  }, []);

  useEffect(() => {
    const isEmpty = signatureRef.current.isEmpty();
    isSignatureEmpty(isEmpty);
  }, [signatureRef, isSignatureEmpty]);

  useEffect(() => {
    onSaveSignatureChange(isSaveSignatureChecked);
  }, [isSaveSignatureChecked, onSaveSignatureChange]);

  const handleClear = () => {
    signatureRef.current.clear();
    isSignatureEmpty(true);
    onSignatureRefChange('');
    setUserName(''); // Clear the name as well
  };

  const handleToggleSaveSignature = () => {
    setIsSaveSignatureChecked(!isSaveSignatureChecked);
  };

  const handleCanvasMouseDown = () => {
    const isEmpty = signatureRef.current.isEmpty();
    isSignatureEmpty(isEmpty);
  };

  const handleSignatureChange = () => {
    onSignatureRefChange(signatureRef.current);
  };

  const handleNameChange = (e) => {
    setUserName(e.target.value);
    if (onUserNameChange) {
      onUserNameChange(e.target.value);
    }
};

  return (
    <div>
      <SignatureCanvas
        ref={signatureRef}
        penColor="black"
        className="signature-canvas"
        canvasProps={{
          width: 200 * 1.618,
          height: 200,
          className: 'signature-canvas',
          onClick: handleCanvasMouseDown,
        }}
      />
      <button
        className={`checkbox-button ${isSaveSignatureChecked ? 'selected-button' : ''}`}
        onClick={handleToggleSaveSignature}
      >
        {isSaveSignatureChecked ? '✓' : ' '}
      </button>
      <span>Save Signature</span>
    </div>
  );
}

export default SignaturePad;
