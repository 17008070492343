import React, { useState, useEffect } from 'react';
import { auth, db } from "../../../firebase";
import { collection, query, onSnapshot, orderBy, getDoc } from 'firebase/firestore';
import { doc, deleteDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faFileArrowDown, faBrain } from '@fortawesome/free-solid-svg-icons';
import DoctorsNote from "./DoctorsNote";
import PdfGenerator from './PdfGenerator';
import './Notes.css';
import useUID from '../../General/useUID'

function Notes({ patientId, onClose }) {  // onNotesCountChanged
  const [notes, setNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);
  const [body, setBody] = useState('');
  const [selectedTab, setSelectedTab] = useState('all');
  const [noteData, setNoteData] = useState('');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState(null);
  const [patientData, setPatientData] = useState(null);
  const [uid, subUserUID] = useUID();


  useEffect(() => {
    if (!uid) return;
    const notesRef = collection(db, "patients", uid, "patientData", patientId, "notes");
    const q = query(notesRef, orderBy('timestamp', 'desc'));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const notesData = [];
      querySnapshot.forEach((doc) => {
        notesData.push({ id: doc.id, ...doc.data() });
      });
      setNotes(notesData);
    });

    const loadPatientData = async () => {
      const patientDocRef = doc(db, 'patients', uid, 'patientData', patientId);
      const patientDoc = await getDoc(patientDocRef);
      if (patientDoc.exists()) {
        const data = patientDoc.data();
        setPatientData(data);
      } else {
      }
    };

    loadPatientData();
    // onNotesCountChanged(notes.length);

    return () => {
      unsubscribe();
    };
  }, [patientId, uid]);

  const formatDate = (timestamp) => {
    const date = timestamp.toDate();
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  const handleNoteClick = (noteId) => {
    setSelectedNote(prevSelectedNote =>
      prevSelectedNote === noteId ? null : noteId
    );
  };

  const toggleTab = (tab) => {
    if (selectedTab === tab) {
      setSelectedTab('all');
    } else {
      setBody('');
      setSelectedTab(tab);
    }
  };

  const handleDownload = async (note) => {
    if (!note) return;

    console.log("note", note);

    try {
      const signatureDocRef = doc(db, 'signatures', note.signatureId);
      const signatureDoc = await getDoc(signatureDocRef);

      console.log("signatureDoc",signatureDoc);
      if (signatureDoc.exists()) {
        const signatureData = signatureDoc.data();
        console.log(signatureData);
        const pdfGenerator = new PdfGenerator();
        await pdfGenerator.downloadPdf(note.text, patientData, signatureData, uid, subUserUID);
      }
    } catch (error) {
      console.error('Error fetching and processing signature data:', error);
    }
  };

  const handleBrainIconClick = (note) => {
    if (!note) return;
    setBody(note.text);
    setSelectedTab('ai');
    setNoteData(note)
  };

  const handleDeleteNote = async (noteId) => {
    setNoteToDelete(noteId);
    setShowDeleteConfirmation((noteId != noteToDelete) ? true : !showDeleteConfirmation);
  };

  const handleCancelDelete = () => {
    setNoteToDelete(null);
    setShowDeleteConfirmation(false);
  };

  const handleConfirmDelete = async () => {
    if (noteToDelete) {
      const noteRef = doc(db, "patients", uid, "patientData", patientId, "notes", noteToDelete);
      await deleteDoc(noteRef);
      setNoteToDelete(null);
      setShowDeleteConfirmation(false);
    }
  };

  const getNoteSnippet = (text, maxLength = 20) => {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };

  return (
    <div className='notesContainer'>
      <button onClick={onClose} className='filesCloseButton'>
        X
      </button>

      <div className='buttonsContainer'>
        <button
          onClick={() => toggleTab('all')}
          className={`greyButton ${selectedTab === 'all' ? 'selectedButton' : ''}`}
        >
          All Notes
        </button>

        <button
          onClick={() => toggleTab('write')}
          className={`greyButton ${selectedTab === 'write' ? 'selectedButton' : ''}`}
        >
          Write a Note
        </button>

        <button
          onClick={() => toggleTab('ai')}
          className={`greyButton ${selectedTab === 'ai' ? 'selectedButton' : ''}`}
        >
          AI Note Writer
        </button>
      </div>

      {selectedTab === 'all' && (
        <div className='notes'>
          {notes.length === 0 ? (
            <label className="content-center mt-3">
              <h5>No notes available.</h5>
            </label>
          ) : (
            <ul>
              {notes.map((note) => (
                <div key={note.id} className='noteItem'>
                  <div onClick={() => handleNoteClick(note.id)}>
                    <div className='noteContent'>
                      <br />
                      <span>{note.title}</span>
                      <br />
                      <span>Author: {note.authorName || 'Unknown Author'}</span>
                      <br />
                      <small>{formatDate(note.timestamp)}</small>
                    </div>
                    {selectedNote === note.id
                      ? <div className='expandedNoteContent'>{note.text}</div>
                      : <div className='notePreview'>{getNoteSnippet(note.text)}</div>}
                  </div>
                  <div className='noteActions'>
                    <button onClick={() => handleDownload(note)}>
                      <FontAwesomeIcon title={'Download Note'} icon={faFileArrowDown} />
                    </button>
                    <button onClick={() => handleBrainIconClick(note)} disabled={note.isAIGenerated} className={note.isAIGenerated ? 'disabled' : ''}>
                      <FontAwesomeIcon title={'Run through the AI'} icon={faBrain} />
                    </button>
                    {(!showDeleteConfirmation || note.id !== noteToDelete) && (
                      <button onClick={() => handleDeleteNote(note.id)} className='deleteNoteButton'>
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    )}
                  </div>
                  {showDeleteConfirmation && note.id === noteToDelete && (
                    <div className='confirmationDialog slide-in'>
                      <p>Are you sure you want to delete this note?</p>
                      <button onClick={handleConfirmDelete}>Yes</button>
                      <button onClick={handleCancelDelete}>No</button>
                    </div>
                  )}
                </div>
              ))}
            </ul>
          )}
        </div>
      )}

      {selectedTab === 'write' && <DoctorsNote patientId={patientId} body={body} manualInputMode={true} />}

      {selectedTab === 'ai' && <DoctorsNote patientId={patientId} body={body} note={noteData} />}

    </div>
  );
}

export default Notes;