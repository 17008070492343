import React, { useState, useEffect, useRef } from 'react';
import { auth, db, storage } from "../../firebase";
import { collection, query, onSnapshot, addDoc, orderBy } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import styles from './PatientView.module.css';
import { doc, deleteDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import useUID from '../General/useUID'
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';

function Files({ patientId, onClose, onFilesCountChanged }) {
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uid, subUserUID] = useUID();

  useEffect(() => {
    // Guard clause: Only run this effect if uid is present
    if (!uid) return;
  
    // If we reach here, it means uid is present
    const filesRef = collection(db, "patients", uid, "patientData", patientId, "files");
    const q = query(filesRef, orderBy('timestamp', 'desc'));
  
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const filesData = [];
      querySnapshot.forEach((doc) => {
        filesData.push({ id: doc.id, ...doc.data() });
      });
      setFiles(filesData);
    });
    // onFilesCountChanged(files.length);

    return () => {
      unsubscribe();
    };        
  }, [uid, patientId]); // Watching both uid and patientId

  const getFileType = (fileName) => {
    if (fileName.toLowerCase().endsWith('.jpg') || fileName.toLowerCase().endsWith('.jpeg')) {
      return 'image';
    } else if (fileName.toLowerCase().endsWith('.pdf')) {
      return 'pdf';
    }
    return 'other';
  };
  
  

  const handleFileUpload = async (file) => {
    if (!file) return;
  
    setUploading(true);
  
    const filePath = `PatientDocuments/${uid}/${file.name}`; // Update the path here
    const fileRef = ref(storage, filePath);
  
    await uploadBytes(fileRef, file);
  
    const downloadUrl = await getDownloadURL(fileRef);
    const filesRef = collection(db, "patients", uid, "patientData", patientId, "files");
    await addDoc(filesRef, { name: file.name, url: downloadUrl, timestamp: new Date() });
  
    setUploading(false);
  };

    const fileInputRef = useRef(null);

    const handleFileInputClick = () => {
    fileInputRef.current.click();
    };

    const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    handleFileUpload(file);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.currentTarget.classList.add(styles.dropboxDragover);
      };
      
      const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.currentTarget.classList.remove(styles.dropboxDragover);
      };
      

    const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];
    handleFileUpload(file);
    };

    const handleDeleteFile = async (fileId) => {
        const fileRef = doc(db, "patients", uid, "patientData", patientId, "files", fileId);
        await deleteDoc(fileRef);
      };
      


      return (
        <div className='notesContainer'>
            <button onClick={onClose} className='filesCloseButton'>
                X
            </button>
            <div className={styles.filesLayout}>
                <div className={styles.filesList}>
                    <h3>My Files</h3>
                    {files.length > 0 ? (
                        <ul>
                            {files.map((file) => (
                                <li key={file.id} className={styles.fileItem}>
                                    <div className={styles.fileContent}>
                                        {getFileType(file.name) === 'image' && (
                                            <img src={file.url} alt={file.name} className={styles.fileImagePreview} />
                                        )}
                                        {getFileType(file.name) === 'pdf' && (
                                            <FontAwesomeIcon icon={faFilePdf} className={styles.filePdfPreview} />
                                        )}
                                        <a href={file.url} download={file.name} target="_blank" rel="noopener noreferrer">
                                            {file.name}
                                        </a>
                                    </div>
                                    <button
                                        onClick={() => handleDeleteFile(file.id)}
                                        className={styles.deleteFileButton}
                                    >
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                    </button>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No files available.</p>
                    )}
                </div>
                <div className={styles.uploadBox}>
                    <form className={styles.uploadForm}>
                        <input
                            type="file"
                            accept=""
                            ref={fileInputRef}
                            onChange={handleFileInputChange}
                            style={{ display: "none" }}
                        />
                        <div
                            className="dropbox"
                            onClick={handleFileInputClick}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                            title="Upload files"

                        >
                         <div className='dropBoxContent'>
                                <FontAwesomeIcon icon={faCloudArrowUp}
                                    style={{ height: "3rem", marginBottom: ".5rem" }}/>                
                            </div>
                            <p>Click to browse or drop files in here.</p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Files;
