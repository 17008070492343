import React, { useState, useEffect } from 'react';
import { db } from "../../firebase";
import { collection, onSnapshot, doc } from 'firebase/firestore';
import useUID from '../General/useUID';
import styles from '../PatientFiles/PatientView.module.css';

function ClaimsReportsView({ patientId }) {
  const [claimReports, setClaimReports] = useState([]);
  const [expandedId, setExpandedId] = useState(null);
  const [uid] = useUID();

  useEffect(() => {
    if (!uid || !patientId) {
      return;
    }
    
    const claimReportsRef = collection(doc(db, 'patients', uid, 'patientData', patientId), 'reports');

    const unsubscribe = onSnapshot(claimReportsRef, (querySnapshot) => {
      if (querySnapshot.empty) {
        setClaimReports([]); // Clear any existing reports
        return;
      }

      let reports = querySnapshot.docs.map(doc => {
        const reportData = doc.data();
        return { id: doc.id, ...reportData }; // Assuming reportData structure matches the mapped data
      });

      setClaimReports(reports);
    }, (error) => {
      console.error('Error fetching claim reports:', error);
    });

    return () => unsubscribe();
  }, [uid, patientId]);

  const toggleExpand = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  return (
    <div className='claimsReportsContainer'>
      {claimReports.length > 0 ? (
        claimReports.map(report => (
          <div key={report.id} className={`patientViewCard ${styles.patientViewCard}`} onClick={() => toggleExpand(report.id)}>
            {/* Displaying basic report information */}
            <p>Status: {report.claimStatusCode}</p>
            <p>Total Payment Amount: {'$'}{report.totalActualProviderPaymentAmount}</p>

            {expandedId === report.id && (
              <div className={styles.expandedSection}>
                {/* Expanded section to show more detailed information */}
                <p>NPI: {report.npi}</p>
                <p>Control Number: {report.controlNumber}</p>
                <p>Claim Status Code: {report.claimStatusCode}</p>
                <p>Service Date: {report.serviceDate}</p>

                <p>Procedure Code: {report.adjudicatedProcedureCode}</p>
                <p>Charge Amount: {'$'}{report.lineItemChargeAmount}</p>
                <p>Provider Payment Amount: {'$'}{report.lineItemProviderPaymentAmount}</p>
                {report.adjustments && report.adjustments.map((adjustment, index) => (
                  <div key={index}>
                    <p>Adjustment Amount: {'$'}{adjustment.adjustmentAmount}</p>
                    <p>Adjustment Reason Code: {adjustment.adjustmentReasonCode}</p>
                    <p>Group Code: {adjustment.claimAdjustmentGroupCodeValue}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))
      ) : (
        <p>No claim reports available for this patient.</p>
      )}
    </div>
  );
}

export default ClaimsReportsView;
