import React, { useState, useEffect } from 'react';
import { auth, db } from "../../firebase";
import HexSpinner from '../General/Animations/Hexspinner';
import {
  collection,
  onSnapshot,
  getDocs,
  setDoc,
  addDoc,
  deleteDoc,
  query,
  where
} from "firebase/firestore";

const SearchBox = ({ onCodeSelect, initialSelectedCodes = [] }) => {
  const [selectedCodes, setSelectedCodes] = useState(initialSelectedCodes);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCode, setSelectedCode] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [manualOverride, setManualOverride] = useState(false);
  const [manualCode, setManualCode] = useState('');

  const fetchData = async () => {
    setIsLoading(true);
    const results = await searchICD10(searchTerm);
    setSearchResults(results);
    setIsLoading(false);
  };


  const handleSearch = async () => {
    if (searchTerm.trim() !== '') {
      setIsLoading(true);
      const results = await searchICD10(searchTerm);
      setSearchResults(results);
      setIsLoading(false);
      setShowResults(true);
    }
  };

  const handleCodeSelect = (code) => {
    if (!selectedCodes.includes(code)) {
      const newSelectedCodes = [...selectedCodes, code];
      setSelectedCodes(newSelectedCodes);
      if (onCodeSelect) {
        onCodeSelect(newSelectedCodes);
      }
      setShowResults(false);
    }
  };

  const removeSelectedCode = (code) => {
    setSelectedCodes(selectedCodes.filter(c => c !== code));
  };

  const searchICD10 = async (term) => {
    try {
      const icd10Ref = collection(db, "ICD10");
      const cleanedTerm = term.trim().replace('.', ''); // Remove periods
      const capitalizedTerm = cleanedTerm.charAt(0).toUpperCase() + cleanedTerm.slice(1);
      const upperCaseTerm = cleanedTerm.toUpperCase();

      console.log("Cleaned Term:", cleanedTerm, "Capitalized Term:", capitalizedTerm, "Uppercase Term:", upperCaseTerm);

      const executeQueries = async (searchTerm) => {
        const codeQuery = query(
          icd10Ref,
          where("code", ">=", searchTerm.toUpperCase()), // Assuming codes are uppercase
          where("code", "<=", `${searchTerm.toUpperCase()}\uf8ff`)
        );

        const descriptionQuery = query(
          icd10Ref,
          where("short_description", ">=", searchTerm),
          where("short_description", "<=", `${searchTerm}\uf8ff`)
        );

        const longDescriptionQuery = query(
          icd10Ref,
          where("long_description", ">=", searchTerm),
          where("long_description", "<=", `${searchTerm}\uf8ff`)
        );

        const snapshots = await Promise.all([
          getDocs(codeQuery),
          getDocs(descriptionQuery),
          getDocs(longDescriptionQuery)
        ]);

        const combinedResults = [];
        snapshots.forEach(snapshot => {
          snapshot.docs.forEach(doc => {
            const data = doc.data();
            if (data.valid_code === 1) { // Filtering only valid codes
              combinedResults.push(data);
            }
          });
        });
        return combinedResults;
      };

      // Get results for cleaned, capitalized, and upper case terms and then combine them
      const resultsCleaned = await executeQueries(cleanedTerm);
      const resultsCapitalized = await executeQueries(capitalizedTerm);
      const resultsUpperCase = await executeQueries(upperCaseTerm);

      // Combine and deduplicate results (assuming each entry has a unique 'code')
      const resultMap = {};
      [...resultsCleaned, ...resultsCapitalized, ...resultsUpperCase].forEach(result => {
        resultMap[result.code] = result;
      });

      const combinedResults = Object.values(resultMap);

      console.log("Search Results:", combinedResults);
      return combinedResults;
    } catch (error) {
      console.error("Error while searching ICD10:", error);
      return [];
    }
  };

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleManualCodeSelect = () => {
    if (manualCode.trim() !== '') {
      const newSelectedCodes = [...selectedCodes, manualCode]; // Ensure it's an array
      setSelectedCodes(newSelectedCodes);
      if (onCodeSelect) {
        onCodeSelect(newSelectedCodes);
      }
      setManualOverride(false);
    }
  };

  const handleChangeManualCode = (event) => {
    setManualCode(event.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === '.') {
      e.preventDefault();
    }
  };

  // When the component mounts or initialSelectedCodes changes, update the selectedCodes state
  useEffect(() => {
    if (Array.isArray(initialSelectedCodes)) {
      setSelectedCodes(initialSelectedCodes);
    } else {
      // Handle the case where initialSelectedCodes is not an array
      console.error('initialSelectedCodes is not an array');
    }
  }, [initialSelectedCodes]);

  return (
    <div>
      <input
        type="text"
        placeholder="Search ICD10 codes"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onC={handleKeyPress}

      />
      <button type="button" onClick={handleSearch}>
        Search
      </button>
      {isLoading ? (
        <HexSpinner />
      ) : (
        <>
          {selectedCodes.length > 0 && (
            <div>
              <p>Selected Diagnosis Codes:</p>
              <ul>
                {selectedCodes.map(code => (
                  <li key={code}>
                    {code}
                    <button type="button" onClick={() => removeSelectedCode(code)}>
                      Remove
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {manualOverride ? (
            <>
              <input
                type="text"
                placeholder="Enter Diagnosis Code"
                value={manualCode}
                onChange={handleChangeManualCode}
              />
              <button type="button" onClick={handleManualCodeSelect}>
                Submit
              </button>
            </>
          ) : (
            <button type="button" onClick={() => setManualOverride(true)}>
              Manual Override
            </button>
          )}
          {showResults && (
            <ul className="results-container">
              {searchResults.map((result, index) => (
                <li key={`${result.code}-${index}`}>
                  <span>
                    <button
                      type="button"
                      onClick={() => handleCodeSelect(result.code)}
                    >
                      Select
                    </button>
                    {result.code} - {result.long_description}
                  </span>
                </li>
              ))}
            </ul>
          )}
        </>
      )}
    </div>
  );
};

export default SearchBox;