import React, { useState, useEffect } from 'react';
import { db } from "../../firebase";
import { collection, onSnapshot, doc } from 'firebase/firestore';
import useUID from '../General/useUID';
import styles from '../PatientFiles/PatientView.module.css';
import ClaimStatusCategoryFlag from '../ClaimStatus/ClaimStatusCategoryFlag';

function ClaimsStatusCards({ patientId, claimId }) {
  const [claimStatuses, setClaimStatuses] = useState([]);
  const [expandedId, setExpandedId] = useState(null);
  const [uid] = useUID();


  useEffect(() => {
    if (!uid || !patientId) {
      return;
    }
    
    const claimStatusRef = collection(doc(db, 'patients', uid, 'patientData', patientId),'claimStatus');
  
    const unsubscribe = onSnapshot(claimStatusRef, (querySnapshot) => {
      if (querySnapshot.empty) {
        setClaimStatuses([]); // Clear any existing statuses
        return;
      }
  
      let statuses = querySnapshot.docs.map(doc => {
        const statusData = doc.data();
        const mainStatus = statusData.mainStatus || {};
        return {
          id: doc.id,
          mainStatus,
          formattedUpdatedAt: mainStatus.updatedAt ? new Date(mainStatus.updatedAt.seconds * 1000).toLocaleString() : 'Unknown',
          formattedEffectiveDate: formatDate(mainStatus.effectiveDate),
          formattedPaidDate: formatDate(mainStatus.paidDate),
          formattedClaimServiceDate: formatDate(mainStatus.claimServiceDate),
          associatedClaimID: statusData.associatedClaimID
        };
      })
      .filter(status => status.associatedClaimID === claimId);

      // Sort the statuses by formattedUpdatedAt in descending order
      statuses = statuses.sort((a, b) => {
        return new Date(b.formattedUpdatedAt) - new Date(a.formattedUpdatedAt);
      });
  
      setClaimStatuses(statuses);
    }, (error) => {
      console.error('Error fetching claim statuses:', error);
    });
  
    return () => unsubscribe();
  }, [uid, patientId, claimId]);
  
  

  const toggleExpand = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  const formatDate = (dateStr) => {
    if (dateStr && dateStr.includes('-')) {
      // Split the string into two dates
      const dates = dateStr.split('-');
      // Format each date individually
      return dates.map(d => d.length === 8 
        ? `${d.substring(4, 6)}/${d.substring(6, 8)}/${d.substring(0, 4)}`
        : 'N/A').join(' - ');
    }
    // Original formatting for single date
    return dateStr && dateStr.length === 8 
      ? `${dateStr.substring(4, 6)}/${dateStr.substring(6, 8)}/${dateStr.substring(0, 4)}`
      : 'N/A';
  };
  

  return (
    <div className='claimsStatusContainer'>
      {claimStatuses.length > 0 ? (
        claimStatuses.map(status => (
          <div key={status.id} className={`patientViewCard ${styles.patientViewCard}`} onClick={() => toggleExpand(status.id)}>
            <p><ClaimStatusCategoryFlag statusCategoryCode={status.mainStatus.statusCategoryCode} /></p>
            <p>Amount Paid: {'$'}{status.mainStatus.amountPaid || ' -'} {'  |  '}Checked on: {status.formattedUpdatedAt}</p>
            
            {expandedId === status.id && (
              <div className={styles.expandedSection}>
                {status.mainStatus.statusCategoryCodeValue && <p>Status Category Code Value: {status.mainStatus.statusCategoryCodeValue}</p>}
                {status.mainStatus.statusCode && <p>Status Code: {status.mainStatus.statusCode}</p>}
                {status.mainStatus.statusCodeValue && <p>Status Code Value: {status.mainStatus.statusCodeValue}</p>}
                {status.mainStatus.entityCode && <p>Entity Code: {status.mainStatus.entityCode}</p>}
                {status.mainStatus.entity && <p>Entity: {status.mainStatus.entity}</p>}
                {status.mainStatus.submittedAmount && <p>Submitted Amount: {'$'}{status.mainStatus.submittedAmount}</p>}
                {status.formattedEffectiveDate && <p>Effective Date: {status.formattedEffectiveDate}</p>}
                {status.formattedPaidDate && <p>Paid Date: {status.formattedPaidDate}</p>}
                {status.formattedClaimServiceDate && <p>Claim Service Date: {status.formattedClaimServiceDate}</p>}
                {status.mainStatus.checkNumber && <p>Check Number: {status.mainStatus.checkNumber}</p>}
                {status.mainStatus.trackingNumber && <p>Tracking Number: {status.mainStatus.trackingNumber}</p>}
                {status.mainStatus.tradingPartnerClaimNumber && <p>Trading Partner Claim Number: {status.mainStatus.tradingPartnerClaimNumber}</p>}
                {status.mainStatus.controlNumber && <p>Control Number: {status.mainStatus.controlNumber}</p>}
              </div>
            )}
          </div>
        ))
      ) : (
        <p>No claim statuses available for this patient.</p>
      )}
    </div>
  );
}

export default ClaimsStatusCards;
