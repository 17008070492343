import React, { useState } from 'react';
import Modal from 'react-modal';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { auth } from '../../firebase';
import PayerSelection from '../Payers/PayerSelection';
import { ProviderProvider } from '../../context/ProviderContext';
import ProviderSelection from '../Providers/ProviderSelection';
import HexSpinner from '../General/Animations/Hexspinner';
import useUID from '../General/useUID'

// This line is important to bind the modal to your appElement
Modal.setAppElement('#root');


const SingleStatus = ({
  initialFirstName = '',
  initialLastName = '',
  initialGender = '',
  initialDob = '',
  initialmemberid = '',
  patientId,
  initialTradingPartnerName
}) => {
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [memberId, setMemberId] = useState(initialmemberid);
  const [firstName, setFirstName] = useState(initialFirstName);
  const [lastName, setLastName] = useState(initialLastName);
  const [gender, setGender] = useState(initialGender);
  const [dob, setDob] = useState(initialDob);
  const [dos, setDos] = useState('');
  const [providerOrganizationName, setProviderOrganizationName] = useState('');
  const [providerNPI, setProviderNPI] = useState('');
  const [billingOrganizationName, setBillingOrganizationName] = useState('');
  const [billingEIN, setBillingEIN] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedPayer, setSelectedPayer] = useState(null);
  const [results, setResults] = useState(null);
  const [chargeAmount, setChargeAmount] = useState();

  const [uid, subUserUID] = useUID();

  const handleButtonClick = async () => {

    // Validate form fields
    if (
      memberId.trim() === '' ||
      firstName.trim() === '' ||
      lastName.trim() === '' ||
      gender.trim() === '' ||
      dob.trim() === '' ||
      dos.trim() === '' ||
      selectedPayer === null ||
      providerOrganizationName.trim() === '' ||
      providerNPI.trim() === '' ||
      billingOrganizationName.trim() === '' ||
      billingEIN.trim() === ''
    ) {
      setError('requiredFields');
      return;
    }


    setLoading(true);
    setError(null);

    const currentUser = auth.currentUser;
    const uid = currentUser ? currentUser.uid : null;
    const tradingPartnerServiceId = selectedPayer?.tradingPartnerId; // use selectedPayer instead of payer
    const claimChargeAmount = chargeAmount

    const formattedDob = dob ? new Date(dob).toLocaleString('en-US', { timeZone: 'UTC', month: '2-digit', day: '2-digit', year: 'numeric' }).split(',')[0] : '';
    const formattedDos = dos ? new Date(dos).toLocaleString('en-US', { timeZone: 'UTC', month: '2-digit', day: '2-digit', year: 'numeric' }).split(',')[0] : '';


    // Set up input data for the Firebase function
    const inputData = {
      memberId,
      firstName,
      lastName,
      gender,
      dob: formattedDob,
      dos: formattedDos,
      providerOrganizationName,
      providerNPI,
      billingOrganizationName,
      billingEIN,
      tradingPartnerServiceId,
      uid,
      patientId,
      claimChargeAmount
    };

    try {
      // Call the Firebase function and get the result
      const functions = getFunctions();
      const getClaimStatus = httpsCallable(functions, 'getClaimStatus');
      const response = await getClaimStatus(inputData);
      console.log(response)

      // Handle claim not found error
      if (response.data && response.data.allClaims.length === 0) {
        setError('claimNotFound');
        return;
      }

      setResult(response.data);
    } catch (err) {
      setError('Claim not found.');
    } finally {
      setLoading(false);
    }
  };

  const openModal = () => {
    setModalIsOpen(true);
  };


  const closeModal = () => {
    setResult(null);
    setModalIsOpen(false);
  };

  const handlePayerSelect = (selectedPayer) => {
    setSelectedPayer(selectedPayer);
  };


  return (
      <div className='form-container'>
        {!results && (
          <div className='claimSearchContainer'>
            <div className="centerHeader">
              <h3>Claims Status Search</h3>
            </div>

            <div className='form-grid'>

              <div className="input-group-row">
                <div className="input-field">
                  <label htmlFor="firstName">First Name</label>
                  <input type="text" className='input' placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                </div>
                <div className="input-field">
                  <label htmlFor="firstName">Last Name</label>
                  <input type="text" className='input' placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
                </div>
              </div>
              <div className="input-group-row">
                <select id="gender" className="genderSelect" value={gender} onChange={(e) => setGender(e.target.value)} required>
                  <option value="">Select gender</option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                </select>
              </div>
              <div className="input-group-row">
                <div className="input-field">
                  <label htmlFor="dob">Date of Birth:</label>
                  <input className='input' type="date" id="dob" placeholder="Date of Birth" value={dob} onChange={(e) => setDob(e.target.value)} required />
                </div>
                <div className="input-field">
                  <label htmlFor="dos">Date of Service:</label>
                  <input className='input' type="date" id="dos" placeholder="Date of Service" value={dos} onChange={(e) => setDos(e.target.value)} required />
                </div>
              </div>

              <div className="input-group-row">
                <div className="input-field">
                  <label htmlFor="memberId">Member ID</label>
                  <input
                    type="text"
                    className="input"
                    placeholder="member ID"
                    value={memberId}
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9]/g, ''); // Remove anything that's not a letter or a number
                      setMemberId(sanitizedValue);
                    }}
                  />
                </div>
                <div className="input-field">
                  <label htmlFor="chargeAmount">Claim Charge Amount</label>
                  <input
                    type="text"
                    className="input"
                    placeholder="chargeAmount"
                    value={chargeAmount}
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9]/g, ''); // Remove anything that's not a letter or a number
                      setChargeAmount(sanitizedValue);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className='content-center mb-3 mt-3'>
              <PayerSelection onSelect={handlePayerSelect} TradingPartnerName={initialTradingPartnerName} mode={'claimStatus'} />
            </div>

            <ProviderProvider uid={uid}>

              <div className="input-group">
                <h3>Service Provider</h3>
                <ProviderSelection
                  type="service"
                  onProviderSelect={(providerId, organizationName, firstName, lastName, npi, ein, address1, city, state, zip, posCode, cliaNumber, medicareNumber) => {
                    setSelectedServiceProviders({ organizationName, firstName, lastName, npi, ein, address1, city, state, zip, posCode });
                  }}
                />
              </div>
              <div className="input-group">
                <h3>Billing Provider</h3>
                <ProviderSelection
                  type="billing"
                  onProviderSelect={(providerId, organizationName, firstName, lastName, npi, ein, address1, city, state, zip, posCode, cliaNumber, medicareNumber) => {
                    setSelectedBillingProviders({ organizationName, firstName, lastName, npi, ein, address1, city, state, zip, posCode });
                  }}
                />
              </div>

            </ProviderProvider>

            <div className='content-center'>
              {loading ? (
                <HexSpinner />
              ) : (
                <button type="submit" onClick={handleButtonClick}>
                  Search
                </button>
              )}
            </div>
          </div>
        )}
        {error && error !== 'requiredFields' && (
          <div className="errorContainer">
            <h2>Error:</h2>
            <p>{error}</p>
          </div>
        )}

        {error === 'requiredFields' && (
          <div className="errorContainer">
            <h2>Please fill in all the required fields.</h2>
          </div>
        )}

        {error === 'claimNotFound' && (
          <div className="errorContainer">
            <h2>Claim not found.</h2>
          </div>
        )}



        {result && (
          <div className='results'>
            <div className='resultsContainer'>
              <button
                type="button"
                className='filesCloseButton'
                onClick={() => setResult(null)}
              >
                X
              </button>
              {result.patientData && result.allClaims.length > 0 && (
                <div>
                  <h3>Claims:</h3>
                  <table>
                    <thead>
                      <tr>
                        <th>Status Category Code Value</th>
                        <th>Status Code Value</th>
                        <th>Submitted Amount</th>
                        <th>Amount Paid</th>
                        <th>Paid Date</th>
                        <th>Check Number</th>
                        <th>Tracking Number</th>
                        <th>Claim Service Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {result.allClaims.map((claim, index) => (
                        <tr key={index}>
                          <td>{claim.mainStatus.statusCategoryCodeValue}</td>
                          <td>{claim.mainStatus.statusCodeValue}</td>
                          <td>{'$'}{claim.mainStatus.submittedAmount}</td>
                          <td>{'$'}{claim.mainStatus.amountPaid}</td>
                          {/* Format the paidDate using formatDate */}
                          <td>{formatDate(claim.mainStatus.paidDate)}</td>
                          <td>{claim.mainStatus.checkNumber}</td>
                          <td>{claim.mainStatus.trackingNumber}</td>
                          {/* Format each date in the claimServiceDate range */}
                          <td>
                            {
                              claim.mainStatus.claimServiceDate.includes('-') ?
                                claim.mainStatus.claimServiceDate.split('-')
                                  .map(dateStr => formatDate(dateStr))
                                  .join(' - ')
                                : formatDate(claim.mainStatus.claimServiceDate)
                            }
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
  );
};

export default SingleStatus;