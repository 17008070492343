import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faGripVertical, faCog } from "@fortawesome/free-solid-svg-icons";

const FormElementSideBar = ({ 
  toggleExpandableArea,
  setFormElementsData,
  index
}) => {

  const handleDelete = () => {
    setFormElementsData((prevElements) =>
      prevElements.filter((_, idx) => idx !== index)
    );
  };

  return (
    <div className="SideContainer">
      <div className="item">
        <button className="TrashButton" 
        onClick={handleDelete}
        > 
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
      <div className="item m-3">
        <FontAwesomeIcon icon={faGripVertical} />
      </div>
      <div className="item">
        <button className="ExpandableButton" onClick={toggleExpandableArea}>
          <FontAwesomeIcon icon={faCog} />
        </button>
      </div>
    </div>
  );
};

export default FormElementSideBar;
