import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { getFunctions, httpsCallable } from 'firebase/functions';
import Topbar from "../General/Topbar";
import GlucoseGraph from './GlucoseGraphs';
import useUID from '../General/useUID';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // To avoid screen readers from reading the main content when the modal is open

function PatientMonitoring() {
  const { patientId } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [uid] = useUID();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const handleGenerateReport = async () => {
    const functions = getFunctions();
    const generateAGPReport = httpsCallable(functions, 'agpReport');

    setLoading(true);
    setError(null);
    try {
      console.log({ patientId, uid, startDate, endDate });
      const result = await generateAGPReport({ patientId, uid, startDate, endDate });
      console.log('Received data:', result.data);

      if (result.data && result.data.pdfBytes) {
        const pdfBlob = new Blob([new Uint8Array(Object.values(result.data.pdfBytes))], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfBlob);

        const downloadLink = document.createElement('a');
        downloadLink.href = pdfUrl;
        downloadLink.setAttribute('download', `AGP_Report_${patientId}.pdf`);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(pdfUrl);
      } else {
        setError('No data returned');
      }
    } catch (err) {
      console.error('Error generating AGP report:', err);
      setError(err.message);
    }
    setLoading(false);
    closeModal(); // Close the modal after the report is generated
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if ((startDate && !endDate) || (!startDate && endDate)) {
      setError("Both start and end dates must be set.");
      return;
    }

    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const diffTime = Math.abs(end - start);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays > 14) {
        setError("The selected date range cannot exceed 14 days.");
        return;
      }
    }

    setError(null);
    handleGenerateReport();
  };

  return (
    <div className="aibackground">
      <Topbar />
      <button style={{ marginTop: "5rem" }} onClick={openModal} disabled={loading}>
        {loading ? 'Generating...' : 'Generate AGP Report'}
      </button>
      <GlucoseGraph />
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} className="confirmModal" contentLabel="Select Date Range">
        <h2>AGP Report:</h2>
        <p>By default, if you click generate it will get the last 2 weeks of data. If you want a custom range, please select start and end dates.</p>
        <form onSubmit={handleSubmit}>
          <label>
            Start Date:
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </label>
          <label>
            End Date:
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </label>
          <div>
            <button type="button" onClick={closeModal}>Cancel</button>
            <button type="submit" disabled={loading}>{loading ? 'Generating...' : 'Generate'}</button>
          </div>
        </form>
        {error && <p>Error: {error}</p>}
      </Modal>
    </div>
  );
}

export default PatientMonitoring;
