import React, { useState } from 'react';
import styles from "../PatientView.module.css";
import { doc, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../../firebase';
import useUID from '../../General/useUID';

function Vitals({ patientId, onClose }) {
  const [uid, subUserUID, isLoading, error] = useUID();
  const [vitals, setVitals] = useState({
    heartRate: '',
    bloodPressure: '',
    bmi: '',
    weight: '',
    temperature: '',
    respiratoryRate: '',
    oxygenSaturation: ''
  });

  const handleChange = (e) => {
    setVitals({
      ...vitals,
      [e.target.name]: e.target.value
    });
  };

  const isFormEmpty = () => {
    return Object.values(vitals).every(x => x === '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Check if all fields are empty
    if (isFormEmpty()) {
      alert("Please fill in at least one field.");
      return;
    }

    // Reference to the medicalHistory subcollection
    const medicalHistoryRef = collection(
      doc(db, 'patients', uid, 'patientData', patientId),
      'medicalHistory'
    );

    try {
      // Add the new document
      await addDoc(medicalHistoryRef, { 
        type: 'vitals', 
        timestamp: serverTimestamp(),
        ...vitals 
      });      
      
      // Reset the form
      setVitals({
        heartRate: '',
        bloodPressure: '',
        bmi: '',
        weight: '',
        // Reset additional vitals here
        temperature: '',
        respiratoryRate: '',
        oxygenSaturation: ''
      });
      onClose(); 
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };

  return (
    <div className={styles.vitalsForm}>
      <form onSubmit={handleSubmit}>
        <div className='input-group-row'>
            <div className='input-field'>
          <label htmlFor="heartRate">Heart Rate (HR):</label>
          <input
            type="number"
            id="heartRate"
            name="heartRate"
            value={vitals.heartRate}
            onChange={handleChange}
          />
        </div>

    <div className='input-field'>
          <label htmlFor="bloodPressure">Blood Pressure (BP):</label>
          <input
            type="text"
            id="bloodPressure"
            name="bloodPressure"
            value={vitals.bloodPressure}
            onChange={handleChange}
            placeholder="e.g., 120/80"
          />
        </div>
    </div>
        <div className='input-group-row'>
            <div className='input-field'>
          <label htmlFor="bmi">Body Mass Index (BMI):</label>
          <input
            type="number"
            step="0.01"
            id="bmi"
            name="bmi"
            value={vitals.bmi}
            onChange={handleChange}
          />
        </div>
        <div className='input-field'>
          <label htmlFor="weight">Weight:</label>
          <input
            type="number"
            step="0.1"
            id="weight"
            name="weight"
            value={vitals.weight}
            onChange={handleChange}
          />
        </div>
    </div>
        <div className='input-group-row'>
            <div className='input-field'>
          <label htmlFor="temperature">Temperature:</label>
          <input
            type="number"
            step="0.1"
            id="temperature"
            name="temperature"
            value={vitals.temperature}
            onChange={handleChange}
          />
        </div>
    </div>
        <div className='input-group-row'>
            <div className='input-field'>
          <label htmlFor="respiratoryRate">Respiratory Rate:</label>
          <input
            type="number"
            id="respiratoryRate"
            name="respiratoryRate"
            value={vitals.respiratoryRate}
            onChange={handleChange}
          />
        </div>
            <div className='input-field'>
          <label htmlFor="oxygenSaturation">Oxygen Saturation (%):</label>
          <input
            type="number"
            step="0.1"
            id="oxygenSaturation"
            name="oxygenSaturation"
            value={vitals.oxygenSaturation}
            onChange={handleChange}
          />
        </div>
    </div>        
    <button type="submit" className={styles.submitButton} disabled={isFormEmpty()}>Submit Vitals</button>
      </form>
    </div>
  );
}

export default Vitals;
