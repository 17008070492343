import React, { useState, useEffect  } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { faHeadset, faThumbsUp,faBrain, faHeart, faCog, faHome, faChartLine, faCircleUser, faGrip, faFileInvoiceDollar, faFileCircleCheck} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../specto_logos/Specto_logo_specto.png';
import useUID from './useUID'
import { faMessage } from '@fortawesome/free-solid-svg-icons';

import './Topbar.css';
import AuthDetails from './../Auth/AuthDetails';
import { doc, onSnapshot } from "firebase/firestore";
import { auth, db } from "../../firebase";

const Topbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showAccountDropdown, setShowAccountDropdown] = useState(false);
  const [Name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [accountTier, setAccountTier] = useState('');
  const [userRole, setUserRole] = useState(null);
  const [uid, subUserUID, isLoading, error] = useUID();


  useEffect(() => {
    if (uid) { // Check if uid exists
        let unsubscribe;
        if (subUserUID) {
            // If subUserUID exists, it's a subuser
            const subUserDetailsRef = doc(db, "users", uid, "subusers", subUserUID);
            unsubscribe = onSnapshot(subUserDetailsRef, (docSnapshot) => {
                if (docSnapshot.exists()) {
                    setName(docSnapshot.data().Name);
                    setEmail(docSnapshot.data().Email);
                    setAccountTier(docSnapshot.data().AccountTier);
                    setUserRole(docSnapshot.data().role);
                }
            });
        } else {
            // It's not a subuser
            const userRef = doc(db, "users", uid);
            unsubscribe = onSnapshot(userRef, (docSnapshot) => {
                if (docSnapshot.exists()) {
                    setName(docSnapshot.data().Name);
                    setEmail(docSnapshot.data().Email);
                    setAccountTier(docSnapshot.data().AccountTier);
                    setUserRole(docSnapshot.data().role);
                }
            });
        }

        return () => {
            if (unsubscribe) unsubscribe();  // Cleanup listener on component unmount
        };
    }
}, [uid, subUserUID]);  
  

  const getLogo = () => {
    switch (location.pathname) {
      default:
        return logo;
    }
  };
  
  
  


  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
    setShowAccountDropdown(false); // Close account dropdown when opening nav dropdown
  };

  const toggleAccountDropdown = () => {
    setShowAccountDropdown(!showAccountDropdown);
    setShowDropdown(false); // Close nav dropdown when opening account dropdown
  };

  return (
    <div className="topbar">
        <div className="leftNav">
            <Link to="/home" onClick={() => navigate('/home')} style={{ textDecoration: 'none' }}>
                <img src={getLogo()} alt="Logo" className="logo" />
            </Link>
        </div>
        <div className="rightNav">
            {(accountTier === "Freebie") && 
                <button style={{marginTop: '1rem'}} className="upgradeButton" onClick={() => navigate("/payment")}>
                    Upgrade Account
                </button>
            }
            <button title='View messages in your organization' className="dropdownButton" onClick={() => navigate('/messages')}>
                <FontAwesomeIcon icon={faMessage} size="lg" />
            </button>
            <button className="dropdownButton" onClick={toggleDropdown}>
                <FontAwesomeIcon icon={faGrip} size="lg" />
            </button>
            {showDropdown && (
                <div className="dropdown">
                    <Link to="/home" className="dropdownLink">
                        <FontAwesomeIcon icon={faHome} size="lg" />
                        <span className="dropdownLinkText">Home</span>
                    </Link>
                    <Link to="/patients" className="dropdownLink">
                        <FontAwesomeIcon icon={faHeart} size="lg" />
                        <span className="dropdownLinkText">Patients</span>
                    </Link>
         {userRole !== "nurse" && (
                        <>
                            <Link to="/claims" className="dropdownLink">
                                <FontAwesomeIcon icon={faFileInvoiceDollar} size="lg" />
                                <span className="dropdownLinkText">Claims</span>
                            </Link>
                            <Link to="/eligibility" className="dropdownLink">
                                <FontAwesomeIcon icon={faThumbsUp} size="lg" />
                                <span className="dropdownLinkText">Eligibility</span>
                            </Link>
                            <Link to="/claimstatus" className="dropdownLink">
                                <FontAwesomeIcon icon={faFileCircleCheck} size="lg" />
                                <span className="dropdownLinkText">Claim Status</span>
                            </Link>
                        </>
                    )}
                    <Link to="/ai" className="dropdownLink">
                        <FontAwesomeIcon icon={faBrain} size="lg" />
                        <span className="dropdownLinkText">AI</span>
                    </Link>
                </div>
            )}
            <button className="dropdownButton" onClick={toggleAccountDropdown}>
                <FontAwesomeIcon icon={faCircleUser} size="3x" />
            </button>
            {showAccountDropdown && (
                <div className="dropdown">
                    <div className='authText'>
                        <div> 
                            <span className="nameBold">{Name}</span>
                        </div>
                        <div>
                            <span>{email}</span>
                        </div>
                    </div>
            {userRole !== "nurse" && (
                      <>
                    <Link to="/settings" className="dropdownLink">
                        <FontAwesomeIcon icon={faCog} size="lg" />
                        <span className="dropdownLinkText">Settings</span>
                    </Link>
                    <Link to="/logs" className="dropdownLink">
                        <FontAwesomeIcon icon={faChartLine} size="lg" />
                        <span className="dropdownLinkText">Logs</span>
                    </Link>
                    </>
                  )}
                    <Link to="/support" className="dropdownLink">
                        <FontAwesomeIcon icon={faHeadset} size="lg" />
                        <span className="dropdownLinkText">Support</span>
                    </Link>
                    <div className="dropdownLink"> 
                        <AuthDetails/>
                    </div>


                </div>
            )}
        </div>
    </div>
);

};

export default Topbar;