import React, { useState } from 'react';
import AddPatient from './AddPatient';
import './AddPatient.modules.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faUpload } from '@fortawesome/free-solid-svg-icons';
import BatchUploadPopup from './BatchUploadPopup';

function AddPatientPopup({selectedFolder, onDataUpdated }) {
  const [isAddPatientOpen, setIsAddPatientOpen] = useState(false);
  const [isBatchUploadOpen, setIsBatchUploadOpen] = useState(false);

  const toggleAddPatientPopup = () => {
    setIsAddPatientOpen(!isAddPatientOpen);
  };

  const toggleBatchUploadPopup = () => {
    setIsBatchUploadOpen(!isBatchUploadOpen);
  };

  return (
    <>
      <div className="addpatientbuttonContainer"> {/* Added a div to wrap the buttons */}
        <button id="AddPatientButton" onClick={toggleAddPatientPopup} className="addpatientactionButton">
          <FontAwesomeIcon icon={faUserPlus} className="addpatientactionButtonIcon" />
          <div className='patientsActionButtonText'>Add Patient</div>
        </button>

        <button 
          id="BatchUploadButton" 
          onClick={toggleBatchUploadPopup} 
          className="addpatientbatchButton"
          title="Batch Patient Upload"  // Tooltip text
        >
          <FontAwesomeIcon icon={faUpload} />
        </button>

      </div>

      {isAddPatientOpen && (
        <div className="popupContainer">
          <div className="popupContentAddPatient">
            <div className="cancelButton">
              <button onClick={toggleAddPatientPopup} className="filesCloseButton">X</button>
            </div>
            <AddPatient selectedFolder={selectedFolder} onDataUpdated={onDataUpdated} onClose={toggleAddPatientPopup} />
          </div>
        </div>
      )}

      {isBatchUploadOpen && (
        <div className="popupContainer">
          <div className="popupContentAddPatient">
            <div className="cancelButton">
              <button onClick={toggleBatchUploadPopup} className="filesCloseButton">X</button>
            </div>
            <BatchUploadPopup selectedFolder={selectedFolder} onClose={toggleBatchUploadPopup} />
          </div>
        </div>
      )}
    </>
  );
};

export default AddPatientPopup;

