import React, { useState } from 'react';
import styles from "../PatientView.module.css";
import { doc, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../../firebase';
import useUID from '../../General/useUID';

function LabAndTest({ patientId, onClose }) {
  const [uid, subUserUID, isLoading, error] = useUID();
  const [labTests, setLabTests] = useState({
    testName: '',
    testDate: '',
    testResults: '',
    notes: ''
  });

  const handleChange = (e) => {
    setLabTests({
      ...labTests,
      [e.target.name]: e.target.value
    });
  };

  const isFormEmpty = () => {
    return !labTests.testName || !labTests.testDate;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isFormEmpty()) {
      alert("Please fill in at least the test name and date.");
      return;
    }

    const medicalHistoryRef = collection(
      doc(db, 'patients', uid, 'patientData', patientId),
      'medicalHistory'
    );

    try {
      await addDoc(medicalHistoryRef, { type: 'LabAndTest', timestamp: serverTimestamp(), ...labTests });
      console.log('Lab and Test entry added:', labTests);

      setLabTests({
        testName: '',
        testDate: '',
        testResults: '',
        notes: ''
      });
      onClose();
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };

  return (
    <div className={styles.labAndTestForm}>
      <form onSubmit={handleSubmit}>
      <div className='input-group-row'>
        <div className='input-field'>
          <label htmlFor="testName">Test Name:</label>
          <input
            type="text"
            id="testName"
            name="testName"
            value={labTests.testName}
            onChange={handleChange}
            placeholder="Name of the lab or test"
          />
        </div>
        </div>

        <div className='input-group-row'>
        <div className='input-field'>
          <label htmlFor="testDate">Test Date:</label>
          <input
            type="date"
            id="testDate"
            name="testDate"
            value={labTests.testDate}
            onChange={handleChange}
          />
        </div>
        <div className='input-field'>
          <label htmlFor="testResults">Test Results:</label>
          <input
            type="text"
            id="testResults"
            name="testResults"
            value={labTests.testResults}
            onChange={handleChange}
            placeholder="Results or findings"
          />
        </div>
        </div>

        <div className='input-group-row'>
        <div className='input-field'>
          <label htmlFor="notes">Notes:</label>
          <textarea
            id="notes"
            name="notes"
            value={labTests.notes}
            onChange={handleChange}
            placeholder="Any additional notes or comments"
          />
        </div>
        </div>

        <button type="submit" className={styles.submitButton} disabled={isFormEmpty()}>
          Submit Lab & Test
        </button>
      </form>
    </div>
  );
}

export default LabAndTest;
