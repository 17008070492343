import { sendPasswordResetEmail } from "firebase/auth";
import React, { useState } from "react";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";
import styles from "./AUTH.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setSuccessMessage(
          "A password reset link has been sent to your email address"
        );
        setErrorMessage("");
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage("Error sending password reset link");
        setSuccessMessage("");
      });
  };

  return (
    <div className={styles["AUTH-container"]}>
      <form onSubmit={handleSubmit}>
        <h1 className={styles["heading"]}>Forgot Password</h1>
        <p>Please enter your email address to reset your password</p>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        {successMessage && (
          <div className={styles["success-message"]}>{successMessage}</div>
        )}
        {errorMessage && (
          <div className={styles["error-message"]}>{errorMessage}</div>
        )}
        <button type="submit" className={styles["reset-password-btn"]}>
          Reset Password
        </button>
        <button
          onClick={() => navigate("/")}
          className={styles["back-to-login-btn"]}
        >
          Back to Login
        </button>
      </form>
    </div>
  );
};

export default ForgotPassword;
