import React from "react";

const TextArea = ({ fieldItem, isVisible = true, preview = true }) => {
  return (
    <>
      {isVisible && (
        <>
          {fieldItem?.label && (
            <label>
              {fieldItem?.label}
              <span className="RequiredMark">{fieldItem?.required && " *"}</span>
            </label>
          )}
          <textarea
            type={fieldItem?.type}
            name={fieldItem?.name}
            placeholder={fieldItem?.placeholder}
            readOnly={preview}
          ></textarea>
        </>
      )}
    </>
  );
};

export default TextArea;
