import React, { useState, useEffect } from 'react';
import { auth, db, storage } from "../../../../../firebase";
import { doc, getDoc } from 'firebase/firestore';
import AppointmentSlots from './AppointmentSlots';

const Appointments = ({ clinicId, onNext }) => {
  const [clinicData, setClinicData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchClinicData = async () => {
      setLoading(true);
      try {
        const docRef = doc(db, 'clinics', clinicId); // Adjust path as needed
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setClinicData(docSnap.data());
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching clinic data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchClinicData();
  }, [clinicId]);

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : clinicData ? (
        <AppointmentSlots
          workingHours={clinicData.workingHours}
          appointmentTypes={clinicData.appointmentTypes}
          clinicTimeZone={clinicData.timeZone}
          clinicId={clinicId}
          onNext={onNext}
        />
      ) : (
        <p>Clinic data not found.</p>
      )}
    </div>
  );
};

export default Appointments;
