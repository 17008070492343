import { useState, useEffect } from "react";
import { auth, db } from "../../firebase";
import { getDoc, doc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

const useUID = () => {
  const [uid, setUID] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchParentUID = async (subuserUid) => {
      try {
        const subuserDoc = await getDoc(doc(db, "users", subuserUid, "subusers", subuserUid));

        if (subuserDoc.exists()) {
          const subuserData = subuserDoc.data();
          const parentUID = subuserData.parentUID;

          sessionStorage.setItem('effectiveUID', parentUID);
          setUID(parentUID);
        } else {
          const uidFromSession = sessionStorage.getItem('effectiveUID');
          if (uidFromSession) {
            setUID(uidFromSession);
          } else {
            throw new Error("Subuser document not found and no UID in session storage.");
          }
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    const uidFromSession = sessionStorage.getItem('effectiveUID');
    if (uidFromSession) {
      setUID(uidFromSession);
      setIsLoading(false);  // Set loading to false if UID is fetched from session
      return;
    }

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchParentUID(user.uid);
      } else {
        setIsLoading(false); // Set loading to false if no user is authenticated
      }
    });

    return () => unsubscribe();
  }, []);

  let subUserUID = auth.currentUser?.uid;

  if (uid === subUserUID) {
    subUserUID = null;
  }

  return [uid, subUserUID, isLoading, error];
};

export default useUID;
