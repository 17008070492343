import React, { useState, useEffect } from 'react';
import { auth } from "../../../firebase";
import { getFunctions, httpsCallable } from 'firebase/functions';
import HexagonSpinner from '../../General/Animations/Hexspinner';
import useUID from '../../General/useUID'

const BillingCodesFrequencyTable = () => {
  const [loading, setLoading] = useState(true);
  const [billingCodeData, setBillingCodeData] = useState([]);
  const [uid, subUserUID] = useUID();

  useEffect(() => {
    if (!uid) return;

    setLoading(true);
    const functions = getFunctions();
    const aggregateFrequencyData = httpsCallable(functions, 'aggregateFrequencyData');

    aggregateFrequencyData({ uid })
      .then((result) => {
        setBillingCodeData(result.data.billingCodeData);
      })
      .catch((error) => {
        console.error("Error fetching billing code data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [uid]);

  if (loading) {
    return <div><HexagonSpinner /></div>;
  }

  return (
    <div className='FrequencyTableContainer'>
      <h3>Top Billing Codes</h3>
      <table>
        <thead>
          <tr>
            <th>Billing Code</th>
            <th>Frequency</th>
          </tr>
        </thead>
        <tbody>
          {billingCodeData.map((item, index) => (
            <tr key={index}>
              <td>{item.code}</td>
              <td>{item.count}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BillingCodesFrequencyTable;
