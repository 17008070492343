import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './Website.css';  // Make sure to modify CSS to handle the new layouts
import Footer from './Footer';
import Header from './Header';
import FAQSection from './FAQ';
import armhero from './assetts/armhero.jpg';  // Background image for the hero section
import libresolo from './assetts/libreSolo.jpg';  // Libre Solo device
import phoneCMG from './assetts/phoneCMG.png';  // Phone with CGM integration
import rpmBp from './assetts/rpm_bp.jpg';  // Blood pressure RPM
import bloodpressure2 from './assetts/bloodpressure2.jpg';  // Blood pressure for edge bleed
import emr from './assetts/emr.png';  // EMR screenshot
import spectoCgm from './assetts/specto_cgm.png';  // Specto CGM screenshot
import arm from './assetts/arm.jpg';  // Arm with CGM device

const LandingPage = () => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="landing-page">
            <Helmet>
                <title>Specto Health - CGM and RPM Services</title>
                <meta name="description" content="CGM and RPM services for modern healthcare practices." />
                <meta name="keywords" content="CGM, RPM, Healthcare, Remote Monitoring, Continuous Glucose Monitoring" />
            </Helmet>

            <Header />

            {/* Hero Section */}
            <section className="hero-section" style={{ backgroundImage: `url(${armhero})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="hero-content">
                    <h1 style={{ color: 'white' }}>RPM and CGM Built for Professionals</h1>
                    <p>Monitor your patients remotely with confidence using advanced medical technology.</p>
                </div>
            </section>

            {/* Devices Section */}
            <section className="devices-section">
                <div className="device-content">
                    <h2>Devices We Work With</h2>
                    <div className="device-images">
                        <div className="device">
                            <img src={libresolo} alt="Libre 3 CGM Device" className="device-image" />
                            <h3>Libre 3 Continuous Glucose Monitor (CGM)</h3>
                            <p>
                                The Libre 3 CGM system provides real-time glucose readings every minute, helping healthcare professionals monitor 
                                glucose trends and improve diabetes management. With a compact design, it can be worn comfortably and transmits data to 
                                compatible devices for easy access.
                            </p>
                        </div>
                        <div className="device">
                            <img src={phoneCMG} alt="Phone with CGM App" className="device-image" />
                            <h3>Phone CGM Integration</h3>
                            <p>
                                Our platform seamlessly integrates with mobile apps for CGM monitoring, enabling users to track glucose data on their phones.
                                The real-time transmission of glucose levels from devices such as the Libre 3 ensures constant vigilance and better patient outcomes.
                            </p>
                        </div>
                        <div className="device">
                            <img src={rpmBp} alt="RPM Blood Pressure Device" className="device-image" />
                            <h3>RPM Blood Pressure Monitor</h3>
                            <p>
                                The RPM Blood Pressure Monitor allows for remote monitoring of blood pressure, enabling healthcare providers to keep track 
                                of critical cardiovascular metrics from afar. With real-time data transmission, this FDA-approved device is ideal for RPM programs 
                                that require close tracking of hypertension and other conditions.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Blood Pressure Edge Bleed Section */}
            <section className="edge-bleed-section" style={{ backgroundImage: `url(${bloodpressure2})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="edge-bleed-content">
                    <h2>Remote Blood Pressure Monitoring</h2>
                    <p>
                        With our remote patient monitoring (RPM) platform, you can seamlessly integrate blood pressure data from FDA-approved devices. 
                        Track your patients’ cardiovascular health with real-time measurements and receive alerts for any significant changes.
                    </p>
                </div>
            </section>

            {/* EMR and CGM Screenshots Section */}
            <section className="screenshots-section" style={{ backgroundColor: '#fff' }}>
                <div className="screenshots-content">
                    <h2>Powerful Software Integration</h2>
                    <div className="screenshot-images">
                        <div className="screenshot">
                            <img src={emr} alt="EMR Screenshot" className="screenshot-image" style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} />
                            <h3>Electronic Medical Records (EMR) Integration</h3>
                            <p>
                                Our platform integrates seamlessly with EMR systems, allowing you to manage patient data efficiently and securely. 
                                Easily access clinical data, records, and monitoring information within one streamlined interface.
                            </p>
                        </div>
                        <div className="screenshot">
                            <img src={spectoCgm} alt="Specto CGM Dashboard Screenshot" className="screenshot-image" style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} />
                            <h3>Specto CGM Dashboard</h3>
                            <p>
                                The Specto CGM Dashboard provides real-time insights into glucose trends, helping you make informed decisions about 
                                patient care. View historical data, analyze trends, and adjust treatment plans based on the latest information.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Final Edge Bleed Section with Arm Image */}
            <section className="edge-bleed-section" style={{ backgroundImage: `url(${arm})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="edge-bleed-content">
                    <h2>Comfortable, Continuous Monitoring</h2>
                    <p>
                        Patients can wear continuous glucose monitoring (CGM) devices with ease, allowing for uninterrupted data transmission and 
                        real-time analysis. Our devices are designed to be comfortable and discreet, ensuring high patient compliance and better outcomes.
                    </p>
                </div>
            </section>

            <FAQSection />
            <Footer />
        </div>
    );
};

export default LandingPage;
